import { GET_ALL_ROLES_URL, STORE_IMPERSONATE_URL } from "../../config";
import axiosClient from "../../helpers/axios-client";
import { OPEN_FORM_LOADING, CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS, GET_ALL_ROLES, STORE_IMPERSONATE } from "./actionTypes";



export const openFormLoading = () => (dispatch) => {
    return dispatch({ type: OPEN_FORM_LOADING });
};

export const closeFormLoading = () => (dispatch) => {
    return dispatch({ type: CLOSE_FORM_LOADING });
};

export const activeFormSubmissionStatus = () => (dispatch) => {
    return dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
};

export const nonActiveFormSubmissionStatus = () => (dispatch) => {
    return dispatch({ type: FORM_SUBMISSION_STATUS, payload: false });
};


export const getAllRoles = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_ROLES_URL)
        .then((response) => {
            if (response && response.data.success) {
                const roles = response.data.roles;
                return dispatch({ type: GET_ALL_ROLES, payload: roles });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const StoreImpersonate = (store_owner_id) => (dispatch) => {
    return axiosClient
        .post(STORE_IMPERSONATE_URL, { store_owner_id })
        .then((response) => {
            if (response && response.data) {
                const store_owner = response.data;
                return dispatch({ type: STORE_IMPERSONATE, payload: store_owner });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};
