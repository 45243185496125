
const menu = [
    {
        icon: "dashboard-fill",
        text: "Dashboard",
        link: "/dashboard",
        active: localStorage.getItem("permissions")?.includes("dashboard"),
    },
    {
        icon: "list-thumb-fill",
        text: "Create Orders",
        link: "/create-orders",
        active: localStorage.getItem("permissions")?.includes("dashboard"),
    },
    {
        icon: "bag-fill",
        text: "Live Orders",
        link: "/live-orders",
        active: localStorage.getItem("permissions")?.includes("live_orders"),
    },
    {
        icon: "bag-fill",
        text: "Returned Orders",
        link: "/returned-orders",
        active: localStorage.getItem("permissions")?.includes("dashboard"),
    },
    {
        heading: "Inventory Management",
        active: localStorage.getItem("permissions")?.includes("main_categories")
            || localStorage.getItem("permissions")?.includes("categories")
            || localStorage.getItem("permissions")?.includes("sub_categories")
            || localStorage.getItem("permissions")?.includes("brands")
            || localStorage.getItem("permissions")?.includes("brands")
            || localStorage.getItem("permissions")?.includes("items")
            || localStorage.getItem("permissions")?.includes("item_variants")
            || localStorage.getItem("permissions")?.includes("addon_categories")
            || localStorage.getItem("permissions")?.includes("addons"),
    },
    {
        icon: "list-thumb-fill",
        text: "Inventory",
        active: localStorage.getItem("permissions")?.includes("main_categories")
            || localStorage.getItem("permissions")?.includes("categories")
            || localStorage.getItem("permissions")?.includes("sub_categories")
            || localStorage.getItem("permissions")?.includes("brands"),
        subMenu: [
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("brands"),
                text: "Brands",
                link: "/brands",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("main_categories"),
                text: "Main Categories",
                link: "/main-categories",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("categories"),
                text: "Categories",
                link: "/categories",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("sub_categories"),
                text: "Sub Categories",
                link: "/sub-categories",
            },
        ],
    },
    {
        icon: "list-thumb-fill",
        text: "Product",
        active: localStorage.getItem("permissions")?.includes("items")
            || localStorage.getItem("permissions")?.includes("item_variants")
            || localStorage.getItem("permissions")?.includes("addon_categories")
            || localStorage.getItem("permissions")?.includes("addons")
            ||localStorage.getItem("permissions")?.includes("stock_products"),
        subMenu: [
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("items"),
                text: "Items",
                link: "/items",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("item_variants"),
                text: "Item Variants",
                link: "/item-variants",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("addon_categories"),
                text: "Addon Categories",
                link: "/addon-categories",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("addons"),
                text: "Addons",
                link: "/addons",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("stock_products"),
                text: "Limited Stocks",
                link: "/stock-products",
            },
        ],
    },

    // {
    //     heading: "Stock Management",
    //     active: localStorage.getItem("permissions")?.includes("stock_products")
    // },
    // {
    //     icon: "building-fill",
    //     active: localStorage.getItem("permissions")?.includes("stock_products"),
    //     text: "Products",
    //     link: "/stock-products",
    // },

    {
        heading: " ",
        active: localStorage.getItem("permissions")?.includes("customers")
            || localStorage.getItem("permissions")?.includes("delivery_partners")
            || localStorage.getItem("permissions")?.includes("all_users")
            || localStorage.getItem("permissions")?.includes("orders")
            || localStorage.getItem("permissions")?.includes("datewise_orders")
            || localStorage.getItem("permissions")?.includes("delivery_collections")
            || localStorage.getItem("permissions")?.includes("delivery_collection_logs")
            || localStorage.getItem("permissions")?.includes("banners")
            || localStorage.getItem("permissions")?.includes("sliders")
            || localStorage.getItem("permissions")?.includes("coupons"),
    },
    {
        icon: "users-fill",
        text: "User",
        active: localStorage.getItem("permissions")?.includes("all_users")
            || localStorage.getItem("permissions")?.includes("customers")
            || localStorage.getItem("permissions")?.includes("delivery_partners"),
        subMenu: [
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("all_users"),
                text: "All Users",
                link: "/all-users",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("customers"),
                text: "Customers",
                link: "/customers",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("delivery_partners"),
                text: "Delivery Partners",
                link: "/delivery-partners",
            },
        ],
    },

    // {
    //     heading: "Report Management",
    //     active: localStorage.getItem("permissions")?.includes("orders")
    //         || localStorage.getItem("permissions")?.includes("datewise_orders")
    //         || localStorage.getItem("permissions")?.includes("delivery_collections")
    //         || localStorage.getItem("permissions")?.includes("delivery_collection_logs"),
    // },
    {
        icon: "cc-alt2-fill",
        text: "Reports",
        active: localStorage.getItem("permissions")?.includes("orders")
            || localStorage.getItem("permissions")?.includes("datewise_orders")
            || localStorage.getItem("permissions")?.includes("delivery_collections")
            || localStorage.getItem("permissions")?.includes("delivery_collection_logs"),
        subMenu: [
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("orders"),
                text: "Orders",
                link: "/all-orders",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("datewise_orders"),
                text: "Datewise Orders",
                link: "/datewise-order",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("delivery_collections"),
                text: "Delivery Collection Report",
                link: "/delivery-collection",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("delivery_collection_logs"),
                text: "Delivery Collection Log Report",
                link: "/delivery-collection-logs",
            },
        ],
    },
    // {
    //     heading: "Promotions Management",
    //     active: localStorage.getItem("permissions")?.includes("banners")
    //         || localStorage.getItem("permissions")?.includes("sliders")
    //         || localStorage.getItem("permissions")?.includes("coupons"),
    // },

    {
        icon: "layers-fill",
        text: "Promotions",
        active: localStorage.getItem("permissions")?.includes("banners")
            || localStorage.getItem("permissions")?.includes("sliders")
            || localStorage.getItem("permissions")?.includes("coupons"),
        subMenu: [
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("banners"),
                text: "Banners",
                link: "/banners",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("sliders"),
                text: "Sliders",
                link: "/sliders",
            },
            {
                icon: "dot",
                active: localStorage.getItem("permissions")?.includes("coupons"),
                text: "Coupons",
                link: "/coupons",
            },
        ],
    },
    {
        heading: "City & Permission Management",
        active: localStorage.getItem("permissions")?.includes("cities")
            || localStorage.getItem("permissions")?.includes("permission"),
    },
    {
        icon: "building-fill",
        active: localStorage.getItem("permissions")?.includes("cities"),
        text: "Cities",
        link: "/cities",
    },
    {
        icon: "grid-alt-fill",
        active: localStorage.getItem("permissions")?.includes("permission"),
        text: "Permissions",
        link: "/permissions",
    },
    {
        icon: "home-fill",
        active: localStorage.getItem("permissions")?.includes("warehouse"),
        text: "Warehouse",
        link: "/warehouses",
    },

    //for loading template components

    // {
    //     icon: "bag",
    //     text: "Template preview",
    //     link: "/preview",
    //     panel: true,
    //     newTab: true,
    //     subPanel: [
    //         {
    //             icon: "dashboard-fill",
    //             text: "Dashboard",
    //             link: "/ecommerce/index",
    //         },
    //         {
    //             icon: "bag-fill",
    //             text: "Orders",
    //             link: "/ecommerce/orders",
    //         },
    //         {
    //             icon: "package-fill",
    //             text: "Products",
    //             link: "/ecommerce/products",
    //         },
    //         {
    //             icon: "users-fill",
    //             text: "Customers",
    //             link: "/ecommerce/customer",
    //         },
    //         {
    //             icon: "chat-fill",
    //             text: "Support",
    //             link: "/ecommerce/support",
    //         },
    //         {
    //             icon: "opt-alt-fill",
    //             text: "Settings",
    //             link: "/ecommerce/settings",
    //         },
    //         {
    //             icon: "server-fill",
    //             text: "Integration",
    //             link: "/ecommerce/integration",
    //         },
    //     ],
    // },
];
export default menu;
