import React from "react";
import classNames from "classnames";
import Icon from "../icon/Icon";
import { WEBSITE_BASE_URL } from "../../config";
import { useSelector } from "react-redux";

// Aviable sizes {xs,sm,md,lg,xl}
/* Aviable theme {blue-dim, blue, azure-dim, azure, indigo-dim, indigo, purple-dim, purple, pink-dim, pink, orange-dim, orange, teal-dim, teal,
    primary-dim, primary, secondary-dim, secondary, success-dim, success, info-dim, info, warning-dim, warning, danger-dim, danger, dark-dim, dark, gray-dim, gray, lighter, light} */

const UserAvatar = ({ className, size, theme, icon, text, imageAlt, ...props }) => {
    const image = localStorage.getItem('image');
    const classes = classNames({
        "user-avatar": true,
        [`${className}`]: className,
        [`user-avatar-${size}`]: size,
        [`bg-${theme}`]: theme,
    });
    return (
        <div className={classes}>
            {image != 'null' ? <img src={WEBSITE_BASE_URL + image} alt={imageAlt} style={{ borderRadius: '100%', width: '100%', height: '100%', objectFit: 'cover' }} /> :
                icon ? <Icon name={icon} /> : null}
        </div>
    );
};

export default UserAvatar;
