import React, { useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import Customization from "../Customization";
import {
  addProductQuantityToCart,
  addProductToCart,
  addToCart,
  clearCart,
  removeFromCart,
  removeProductFromCart,
  updateCart,
} from "../../../../redux/admin/cart/action";
import VariantCustomization from "../VariantCustomization";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Block, Icon } from "../../../../components/Component";
import { Button, Card, Col, Row } from "reactstrap";
import { Content } from "antd/es/layout/layout";
import { clearCartInDashboardCreateOrder } from "../../../../redux/admin/warehouse/action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Toaster, toast } from "sonner";


// import { addAddonsCart, addToCart, addVariantCart, clearCart, removeAddonsCart, removeFromCart, removeVariantCart, updateCart } from '../../../../../redux/admin/cart/action'

function DisplayItems(items) {
  const dispatch = useDispatch();
  const cartProducts = useSelector((state) => state.cart);
  const [updateState, setUpdatestate] = useState(false);

  const forceStateUpdate = () => {
    setTimeout(() => {
      // this.forceUpdate();
      if (updateState) {
        setUpdatestate(false);
      } else {
        setUpdatestate(true);
      }
    }, 100);
  };
  const history = useHistory();

  const handleButtonClick = () => {
    const customer_id = localStorage.getItem('customer_id');
    const customer_name = localStorage.getItem('customer_name');
    const customer_phone = localStorage.getItem('customer_phone');

    const landmark = localStorage.getItem('landmark');

    if (customer_id && customer_name && landmark && customer_phone) {
      // Navigate to cart orders page
      history.push('/cart-orders');
    } else {
      // Optionally, you can handle the case when any of the required data is missing
      toast.error('Please Fill all details');
    }
  };
  const addProductQuantity = (product) => {
    // Dispatch the action with the product data
    dispatch(addProductToCart(product));
  };

  const removeProductQuantity = (product) => {
    // Dispatch the action with the product id
    dispatch(removeProductFromCart(product));
  };

  return (
    <div>
      {/* style={{ backgroundColor: "#fafafa" }} */}
      {/* <div className='mt-2.5 pt-4 px-4 pb-2 flex justify-between items-center bg-white'> */}
      {/* <div className='font-bold'>
                    Order Detail
                </div> */}
      {/* <div className='flex items-center text-xs border rounded-lg px-2 py-1 text-red-500' onClick={()=>{ dispatch(clearCart()) }}>
                    <RiDeleteBinLine className='me-1' color='red' />  Clear Cart
                </div>
            </div> */}

      {/* <div className="  px-2 py-4">
        <Button
          className="btn-icon py-1 px-2 btn-dim"
          // color="primary"
          size="rg"
          onClick={() => {
            dispatch(clearCartInDashboardCreateOrder());
          }}
        >
          <RiDeleteBinLine className="me-1" color="red" />
          Clear Items
        </Button>
      </div> */}
      <div style={{marginTop:"15px", marginBottom:"15px"}}>
       
          <div
            class="btn btn-danger btn btn-md btn-danger"
            onClick={() => {
              dispatch(clearCartInDashboardCreateOrder());
            }}
          >
            <RiDeleteBinLine className="mr-2" color="WHITE" />
            Clear Items
          </div>
      </div>

      <div>
        {items?.items?.length > 0 && (
          <React.Fragment>
            <Content>
              <div>
                {items?.items?.map((item) => (
                  <div className="" key={item.id}>
                    {/* <div className="mt-1 text-black fw-600 text-md">
                                        <div>{item.name}</div>
                                    </div>
                                    <div className="text-slate-500 text-sm">
                                        <div>{item.description}</div>
                                    </div> */}
                    <div className="">
                      <Block>
                        <Row className="g-gs">
                          <Col xxl="4">
                            <Row className="g-gs">
                              <Col xxl="6" md="5">
                                <Card>
                                  <div className="nk-ecwg nk-ecwg3 pb-3">
                                    <div className="card-inner pb-0">
                                      <div className="card-title-group">
                                        <div className="form-label " style={{ fontSize: "17px" }}>
                                          {item.name}
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="">
                                          {/* data-group */}
                                          <div className="pb-4">{item.description}</div>

                                          <div className="mt-1 flex justify-between items-center ps-2 mb-1.5">
                                            <div
                                              className=" "
                                              style={{ fontSize: "17px", color: "#344357", fontWeight: "500" }}
                                            >
                                              {/* className="font-17 text-black fw-600"  */}
                                              {/* font-medium text-[#344357] */}₹{Math.min(item.sell_price)}
                                            </div>
                                            {item?.item_variants?.length > 0 ? (
                                              <VariantCustomization
                                                product={item}
                                                addProducts={() => {
                                                  addProductQuantity();
                                                }}
                                                topItems={true}
                                              />
                                            ) : item?.addoncategories?.length > 0 ? (
                                              <Customization
                                                product={item}
                                                addProducts={() => {
                                                  addProductQuantity();
                                                }}
                                              />
                                            ) : cartProducts?.orderitems?.find((cp) => cp.id === item?.id) !==
                                              undefined ? (
                                              <div>
                                                {cartProducts?.orderitems
                                                  ?.filter((cp) => cp.id === item?.id)
                                                  .map((product) => (
                                                    <div className="flex justify-between items-center" key={product.id}>

                                                      <div
                                                        className="rounded-lg text-white p-1"
                                                        style={{ backgroundColor: "#118A45" }}
                                                        onClick={() => {
                                                          // let newData = Object.assign({}, product, { quantity: 1 });
                                                          // let newData = {...selectedItem, quantity: 0};
                                                          // dispatch(removeFromCart(newData));
                                                          removeProductQuantity(product);
                                                        }}
                                                      >
                                                        {/* {product.quantity > 1 ?
                                                          <BiMinus size={22} />
                                                        :
                                                         <RiDeleteBinLine size={22} />} */}
                                                        <BiMinus size={22} />
                                                      </div>

                                                      <span className="px-1">{product.quantity}</span>
                                                      <div
                                                        className="rounded-l-lg text-white p-1"
                                                        style={{ backgroundColor: "#118A45" }}
                                                        onClick={() => {
                                                          // let newData = Object.assign({}, product, { quantity: 1 });
                                                          // dispatch(addToCart(newData));

                                                          addProductQuantity(product);
                                                        }}
                                                      >
                                                        <BiPlus size={22} />
                                                      </div>
                                                    </div>
                                                  ))}
                                              </div>
                                            ) : (
                                              <div
                                                className="rounded-l-lg text-white p-1"
                                                style={{ backgroundColor: "#118A45" }}
                                                onClick={() => {

                                                  addProductQuantity(item);
                                                }}
                                              >
                                                <BiPlus size={22} />
                                              </div>
                                            )}
                                          </div>

                                          <div
                                            className="mt-2 text-white px-2 "
                                            style={{
                                              border: "1px solid",
                                              display: "inline-block",
                                              padding: "1px",
                                              backgroundColor: "red",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <div className="   text-sm">
                                              {Math.round(
                                                ((item?.market_price - item?.sell_price) / item?.market_price) * 100
                                              )}
                                              %
                                            </div>
                                          </div>

                                          <div className="info text-right">
                                            {/* <span className="change up text-danger" style={{ fontSize: "20px" }}>
                                            <Icon name="user-list"></Icon>
                                          </span> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Block>
                    </div>

                    {/* <div className="text-white bg-red-600 px-1  absolute left-0 top-4 rounded-r-md text-sm">
                      {Math.round(((item?.market_price - item?.sell_price) / item?.market_price) * 100)}%
                    </div> */}
                  </div>
                ))}
              </div>
            </Content>

            <Row className="g-gs">
              <Col md="5">
                {/* <Link 
                to="/cart-orders"
                > */}
                  <Button 
                  color="primary"
                  className="btn-block mt-3"
                  size="rg"
                  onClick={handleButtonClick}
                   >
                    Go to Cart
                  </Button>
                {/* </Link> */}
              </Col>
            </Row>

          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default DisplayItems;
