import { GET_ALL_CITIES, GET_CITY_WAREHOUSE, EDIT_CITY, GET_CITY_USERS, GET_ALL_MESSAGES } from "./actionTypes";

const initialState = {
    cities: [],
    city_warehouse: [],
    edit_city: [],
    city_users: [],
    messages: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_CITIES:
            return { ...state, cities: action.payload };
        case GET_CITY_WAREHOUSE:
            return { ...state, city_warehouse: action.payload };
        case EDIT_CITY:
            return { ...state, edit_city: action.payload };
        case GET_CITY_USERS:
            return { ...state, city_users: action.payload };
        case GET_ALL_MESSAGES:
            return { ...state, messages: action.payload };
        default:
            return state;
    }
}
