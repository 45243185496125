import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useParams } from 'react-router-dom';

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Progress,
  Row,
  Spinner,
  Form,
} from "reactstrap";
import { Icon, RSelect } from "../../../../../components/Component";
import ToastExample from "../../../../../helpers/toast";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import {
  acceptOrder,
  assignDeliveryGuyToOrder,
  cancelOrder,
  createOrderItem,
  deleteOrderItem,
  getOrderDeliveryPartners,
  getSingleOrder,
  updateOrderStatus,
} from "../../../../../redux/admin/order/action";
import { nonActiveFormSubmissionStatus, openFormLoading } from "../../../../../redux/common/actions";
import moment from "moment";
// import { FaPrint } from "react-icons/fa";
import { WEBSITE_API_URL } from "../../../../../config";
import { Controller, useForm } from "react-hook-form";

function SingleOrder() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleOrder(id));
    dispatch(getOrderDeliveryPartners());
  }, []);

  const getSelectors = (state) => ({
    single_order: state.order.single_order,
    order_log: state.order.order_log,
    order_delivery_partners: state.order.order_delivery_partners,
    form_loading: state.common.form_loading,
    form_submission_status: state.common.form_submission_status,
    main_items: state.item.items,
  });
  const { single_order, order_log, order_delivery_partners, form_loading, form_submission_status, main_items } =
    useSelector(getSelectors);
  console.log(main_items,"main_items")


  const listItems = main_items?.map((category) => (
    
    { value: category.id, label: category.name }));

  const acceptTheOrder = () => {
    dispatch(nonActiveFormSubmissionStatus());
    dispatch(openFormLoading());
    const data = new FormData();
    data.append("id", single_order?.id);
    dispatch(acceptOrder(data));
  };

  const changeStatustoPickUp = () => {
    dispatch(nonActiveFormSubmissionStatus());
    dispatch(openFormLoading());
    const data = new FormData();
    data.append("id", single_order?.id);
    data.append("order_status_id", 5);
    data.append("type", "pickup");
    dispatch(updateOrderStatus(data));
  };
  const completeTheOrder = () => {
    dispatch(nonActiveFormSubmissionStatus());
    dispatch(openFormLoading());
    const data = new FormData();
    data.append("id", single_order?.id);
    data.append("order_status_id", 7);
    data.append("type", "complete");
    dispatch(updateOrderStatus(data));
  };
  //modal
  const [modal, setModal] = useState({
    cancel: false,
    delete: false,
    add: false,
  });
  const [addonCategories, setAddonCategories] = useState([]);
  
  const [varient, setVarient] = useState([]);

  const [showAddonDropdown, setshowAddonDropdown] = useState(false);
  const [showVarientDropdown, setshowVarientDropdown] = useState(false);

  const [selectedAddonCategory, setSelectedAddonCategory] = useState('');
  const [selectedVarient, setSelectedvarient] = useState('');

  console.log(addonCategories,"addonCategories")

  const [dltitem, setDltItem] = useState();
  const openCancelModal = () => {
    dispatch(nonActiveFormSubmissionStatus());
    setModal({ cancel: true });
  };
  const onFormCancel = () => {
    setModal({ cancel: false, delete: false });
    setCancelData("");
  };
  const handleSelectChange = (val) => {
    const selectedItem = main_items.find((item) => item.id === val.value);
    console.log(selectedItem, "selectedItem");

    // Check if addon categories are available
    if (selectedItem?.addoncategories.length > 0) {
        setshowAddonDropdown(true);
        setAddonCategories(selectedItem?.addoncategories);
    } else {
        setshowAddonDropdown(false);
    }

    // Check if item variants are available
    if (selectedItem?.item_variants.length > 0) {
        setshowVarientDropdown(true);
        setVarient(selectedItem?.item_variants);
    } else {
        setshowVarientDropdown(false);
    }

};


  const handleAddonCategoryChange = (value) => {
    setSelectedAddonCategory(value);
    // Handle the selection of addon category
  };
  const handleVarientChange = (value) => {
    setSelectedvarient(value);
    // Handle the selection of addon category
  };
  const cancelTheOrder = () => {
    dispatch(openFormLoading());
    const data = new FormData();
    data.append("id", single_order?.id);
    if (cancelData?.reason === "OTHER") {
      data.append("cancellation_reason", cancelData?.other_reason);
    } else {
      data.append("cancellation_reason", cancelData?.reason);
    }
    if (modal.cancel) {
      dispatch(cancelOrder(data));
    }
  };
  //delete order
  const deleteItemOrder = (item) => {
    dispatch(openFormLoading());
    const data = new FormData();
    data.append("order_id", single_order?.order_items[0]?.order_id);
    data.append("item_id", item);

    if (modal.delete) {
      dispatch(deleteOrderItem(data));
      setModal({
        delete: false,
      });
    }
  };
  const printOrderBill = (order_id) => {
    window.location = `${WEBSITE_API_URL}/admin-print-order/${order_id}`;
  };

  const handleDeleteButtonClick = (item_id) => {
    setModal({ delete: true });
    setDltItem(item_id);
  };
  //form validation with yup
  const itemSchema = yup.object({
    name: yup.string().required(),
    quantity: yup.string().required(),
  });

  const methods = useForm({
    resolver: yupResolver(itemSchema),
    // defaultValues: defaultValues,
  });

  //useform methods
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = methods;

  const canceloptions = [
    { value: "Items Not Available", label: "Items Not Available" },
    { value: "Payment Issue", label: "Payment Issue" },
    { value: "Wrong Delivery Address", label: "Wrong Delivery Address" },
    { value: "Restaurant Changed", label: "Restaurant Changed" },
    { value: "Restaurant Closed", label: "Restaurant Closed" },
    { value: "Delay from Restaurant", label: "Delay from Restaurant" },
    { value: "Delay by Delivery", label: "Delay by Delivery" },
    { value: "OTHER", label: "Other Reasons" },
  ];
  const [cancelData, setCancelData] = useState({ reason: "", other_reason: "" });
  const [cancelInput, setCancelInput] = useState(false);

  const handleInput = (val) => {
    setCancelData({ ...cancelData, reason: val });
    if (val === "OTHER") {
      setCancelInput(true);
    } else {
      setCancelInput(false);
    }
  };

  //delivery guy
  const [deliveryGuy, setDeliveryGuy] = useState(single_order?.delivery_guy_detail_id);
  const [deliveryUpdate, setDeliveryUpdate] = useState(false);
  const deliveryguys = order_delivery_partners?.map((partner) => ({ value: partner.id, label: partner?.user?.name }));

  const assignDeliveryGuy = () => {
    if (deliveryGuy) {
      setDeliveryUpdate(true);
      dispatch(openFormLoading());
      const data = new FormData();
      data.append("order_id", single_order?.id);
      data.append("delivery_guy_detail_id", deliveryGuy);
      dispatch(assignDeliveryGuyToOrder(data));
    } else {
      document.getElementById("nodelivery").innerHTML = "* Select a Delivery Guy.....";
    }
  };

  const [showToast, setShowToast] = useState(false); //toast state
  useEffect(() => {
    if (modal.cancel == true && form_loading == false && form_submission_status == true) {
      setTimeout(() => {
        dispatch(getSingleOrder(id));
        setModal({ cancel: false });
        setCancelInput(false);
        // setShowToast(true);
      }, 300);
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    } else if (form_loading == false && form_submission_status == true) {
      setTimeout(() => {
        setDeliveryUpdate(false);
        dispatch(getSingleOrder(id));
        // setShowToast(true);
      }, 300);
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    }
  }, [form_loading, modal, form_submission_status]);

  const openAddModal = () => {
    // setTimeout(() => {
    setModal({ add: true });
    // }, 100);
  };
  // create orderitem
  const [formData, setFormData] = useState({ quantity: "", list_items_id: "",addon:"",variant:"" });
  const handleFormSubmit = (e) => {
    e.preventDefault();
    onFormSubmit(formData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onFormSubmit = (submitData) => {
    // Create a new instance of FormData
    const formData = new FormData();

    // Append form fields and values to FormData
    formData.append("quantity", submitData.quantity);
    formData.append("list_items_id", submitData.list_items_id);
    formData.append("order_id", id);

    // Dispatch an action to open form loading state if needed
    // dispatch(openFormLoading());

    // Send FormData with API request
    dispatch(createOrderItem(formData));
    setModal({
      add: false,
    });
    // Reset the form if needed
    // resetForm();
  };

  // const onFormSubmit = (submitData) => {
  //   console.log('aaaaaa',submitData);
  //   // dispatch(openFormLoadin());
  //   // const new_data = new FormData();
  //   // new_data.append("quantity", submitData?.quantity);

  //   // new_data.append("list_items_id", submitData?.list_items_id);
  //   // new_data.append("order_id", id);

  //   // dispatch(createOrderItem(new_data));

  //   // resetForm();
  // };
  const resetForm = () => {
    setFormData({
      quantity: "",
      main_category_id: "",
    });
  };
  const hasDeletedItems = single_order?.order_items?.some((item) => item.is_deleted === 0);
  return (
    <React.Fragment>
      <Head title="View Order"></Head>
      <Content>
        <ToastExample show={showToast} />
        <Row>
          {/* customer */}
          <Col md="4">
            <Card className="">
              <CardBody className="card-inner">
                <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                  <Icon name="user-list" className="mr-1"></Icon>
                  Customer
                </CardSubtitle>
                <CardText className="flex flex-col ml-2">
                  <div>
                    <Icon name="user-fill" className="mr-1" style={{ color: "#8094ae" }}></Icon>{" "}
                    {single_order?.user?.name}
                  </div>
                  <div>
                    <Icon name="call" className="mr-1" style={{ color: "#8094ae" }}></Icon> {single_order?.user?.phone}
                  </div>
                  <div className="d-flex">
                    <div>
                      <Icon name="map-pin" className="mr-1" style={{ color: "#8094ae" }}></Icon>
                    </div>
                    <div>{single_order?.address}</div>
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          {/* warehouse */}
          <Col md="3">
            <Card className="">
              <CardBody className="card-inner">
                <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                  <Icon name="building" className="mr-1"></Icon> Warehouse
                </CardSubtitle>
                <CardText className="flex flex-col ml-2">
                  <div>
                    <Icon name="info-fill" className="mr-1" style={{ color: "#8094ae" }}></Icon>{" "}
                    {single_order?.warehouse?.name}
                  </div>
                  <div>
                    <Icon name="call" className="mr-1" style={{ color: "#8094ae" }}></Icon>{" "}
                    {single_order?.warehouse?.phone}
                  </div>
                  <div>
                    <Icon name="map-pin" className="mr-1" style={{ color: "#8094ae" }}></Icon>{" "}
                    {single_order?.warehouse?.address}
                  </div>
                  {single_order?.order_comment != "null" && (
                    <div>
                      <em class="icon ni ni-clipboard"></em> {single_order?.order_comment}
                    </div>
                  )}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          {/* progress bar */}
          <Col md="5">
            <Card className="">
              <CardBody className="card-inner">
                <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                  <div className="flex justify-between mt-1">
                    <div>
                      <Icon name="package" className="mr-1"></Icon> Order Status
                    </div>
                    <div className="flex flex-col" style={{ color: "#8094ae", fontSize: "12px" }}>
                      <Badge
                        color={
                          single_order?.order_status_id == 7
                            ? "success"
                            : single_order?.order_status_id == 8
                            ? "danger"
                            : single_order?.order_status_id == 1
                            ? "secondary"
                            : "warning"
                        }
                      >
                        {single_order?.order_status?.name}
                      </Badge>
                    </div>
                  </div>
                </CardSubtitle>
                <CardText className="ml-2 flex flex-col">
                  <div className="mt-3">
                    {single_order?.order_status_id == 1 ? (
                      <Progress animated color="warning" value="10" />
                    ) : single_order?.order_status_id == 2 ? (
                      <Progress animated color="success" value="20" />
                    ) : single_order?.order_status_id == 3 ? (
                      <Progress animated color="success" value="30" />
                    ) : single_order?.order_status_id == 4 ? (
                      <Progress animated color="success" value="50" />
                    ) : single_order?.order_status_id == 5 ? (
                      <Progress animated color="success" value="70" />
                    ) : single_order?.order_status_id == 6 ? (
                      <Progress animated color="success" value="85" />
                    ) : single_order?.order_status_id == 7 ? (
                      <Progress animated color="success" value="100" />
                    ) : single_order?.order_status_id == 8 ? (
                      <Progress animated color="danger" value="100" />
                    ) : single_order?.order_status_id == 9 ? (
                      <Progress animated color="warning" value="90" />
                    ) : single_order?.order_status_id == 10 ? (
                      <Progress animated color="warning" value="90" />
                    ) : (
                      <Progress value="0" />
                    )}
                    {/* <span>
                                            <Icon name="cart-fill"></Icon>
                                        </span> */}
                  </div>
                  <div className="mt-2">
                    <Icon name="wallet" className="mr-1" style={{ color: "#8094ae" }}></Icon>{" "}
                    {single_order?.payment_mode}
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {/* bill */}
          <Col md="8" className="mt-2">
            <Card className="">
              <CardBody className="card-inner">
                <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                  <div className="flex justify-between mt-1">
                    <div>
                      <Icon name="list" className="mr-1"></Icon> Bill Details
                    </div>
                    <Button color="primary" className="btn-icon" onClick={() => openAddModal()}>
                      <Icon name="plus"></Icon>
                    </Button>

                    <div style={{ color: "#8094ae", fontSize: "12px" }}>
                      <Badge color="info">{single_order?.unique_order_id}</Badge>
                    </div>
                  </div>
                </CardSubtitle>

                <CardText className="flex flex-col ml-2 pt-2">
                  <table className="table table-borderless mt-2  text-right">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th scope="col" className="text-left">
                          Product
                        </th>
                        <th scope="col" className="text-left">
                          Quantity
                        </th>
                        <th scope="col">Price</th>
                        <th scope="col">Total</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {single_order?.order_items?.length > 0
                        ? single_order?.order_items?.map(
                            (item, index, last) =>
                              item?.is_deleted == "0" && (
                                <>
                                  <tr>
                                    <td>
                                      {index + 1}
                                      {"."}
                                    </td>
                                    <td className="text-left">{item?.name}</td>
                                    <td className="text-left">
                                      {item?.order_item_addons?.length > 0 &&
                                      item?.order_item_addons?.filter((addon) => addon.addon_quantity > 0).length > 0
                                        ? item?.order_item_addons?.reduce(
                                            (total, addon) => total + addon.addon_quantity,
                                            0
                                          )
                                        : item?.quantity}
                                    </td>
                                    <td>{parseFloat(item?.price)?.toFixed(2)}</td>
                                    <td>
                                      {item?.order_item_addons?.filter((addon) => addon.addon_quantity > 0).length > 0
                                        ? parseFloat(
                                            item?.order_item_addons?.reduce(
                                              (total, addon) => total + addon.addon_quantity,
                                              0
                                            ) * parseFloat(item?.price)
                                          ).toFixed(2)
                                        : parseFloat(item?.price * item?.quantity).toFixed(2)}
                                    </td>
                                    <td>
                                      <Icon
                                        name="trash"
                                        onClick={() => handleDeleteButtonClick(item?.item_id)}
                                        Delete
                                      />
                                    </td>
                                    {/* <td>{parseFloat(item?.price * item?.quantity)?.toFixed(2)}</td> */}
                                  </tr>
                                  {item?.order_item_addons?.length > 0 ? (
                                    <>
                                      <tr>
                                        <td colspan="1"></td>
                                        <td colspan="4">
                                          <div className="nk-divider divider my-0"></div>
                                        </td>
                                      </tr>
                                      <tr style={{ fontSize: "12px", color: "#526484", fontWeight: "550" }}>
                                        <td></td>
                                        <td className="text-left">Addons</td>
                                        <td className="text-left">Quantity</td>
                                        <td>Price</td>
                                        <td>Total</td>
                                      </tr>
                                      <tr>
                                        <td colspan="1"></td>
                                        <td colspan="4">
                                          <div className="nk-divider divider my-0"></div>
                                        </td>
                                      </tr>
                                      {item?.order_item_addons
                                        ?.filter((addon) => addon.type === "MULTIPLE")
                                        ?.map((addon, index) => (
                                          <>
                                            <tr style={{ fontSize: "12px" }}>
                                              <td></td>
                                              <td className="text-left">
                                                {addon?.addon_category_name} ({addon?.addon_name})
                                              </td>
                                              <td className="text-left">{addon?.addon_quantity}</td>
                                              <td>{addon.addon_price}</td>
                                              <td>
                                                {parseFloat(addon.addon_price * addon?.addon_quantity)?.toFixed(2)}
                                              </td>
                                            </tr>
                                          </>
                                        ))}
                                      {item?.order_item_addons
                                        ?.filter((addon) => addon.type === "SINGLE")
                                        ?.map((addon, index) => (
                                          <>
                                            <tr style={{ fontSize: "12px" }}>
                                              <td></td>
                                              <td className="text-left">
                                                {addon?.addon_category_name} ({addon?.addon_name})
                                              </td>
                                              <td className="text-left">{addon?.addon_quantity}</td>
                                              <td>{addon.addon_price}</td>
                                              <td>
                                                {parseFloat(addon.addon_price * addon?.addon_quantity)?.toFixed(2)}
                                              </td>
                                            </tr>
                                          </>
                                        ))}
                                      {item?.order_item_variants?.length > 0 ? (
                                        <tr>
                                          <td colspan="1"></td>
                                          <td colspan="4">
                                            <div className="nk-divider divider my-0"></div>
                                          </td>
                                        </tr>
                                      ) : null}
                                    </>
                                  ) : null}
                                  {item?.order_item_variants?.length > 0 ? (
                                    <>
                                      <tr>
                                        <td colspan="1"></td>
                                        <td colspan="4">
                                          <div className="nk-divider divider my-0"></div>
                                        </td>
                                      </tr>
                                      <tr style={{ fontSize: "12px", color: "#526484", fontWeight: "550" }}>
                                        <td></td>
                                        <td className="text-left">Variants</td>
                                        <td className="text-left">Quantity</td>
                                        <td>Price</td>
                                        <td>Total</td>
                                      </tr>
                                      <tr>
                                        <td colspan="1"></td>
                                        <td colspan="4">
                                          <div className="nk-divider divider my-0"></div>
                                        </td>
                                      </tr>
                                      {item?.order_item_variants?.map((variant, index) => (
                                        <>
                                          <tr style={{ fontSize: "12px" }}>
                                            <td></td>
                                            <td className="text-left">{variant?.variant_name}</td>
                                            <td className="text-left">{variant?.quantity}</td>
                                            <td>{variant.variant_price}</td>
                                            <td>
                                              {(
                                                parseFloat(variant?.quantity) * parseFloat(variant.variant_price)
                                              ).toFixed(2)}
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                    </>
                                  ) : null}
                                  {single_order?.order_items.length - 1 == index ? null : (
                                    <tr>
                                      <td colspan="5">
                                        <div className="nk-divider divider my-0"></div>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )
                          )
                        : ""}
                    </tbody>
                  </table>
                  {
                    hasDeletedItems ? (
                      // single_order && single_order?.order_items &&  single_order?.order_items.map((item,index)=>(
                      <>
                        <div className="nk-divider divider sm"></div>
                        <div className="flex flex-row content-end justify-end items-end mx-1">
                          <div className="flex flex-col" style={{ fontSize: "13px" }}>
                            <div className="text-right" style={{ paddingRight: "3px" }}>
                              <label className="col-form-label py-0 fs-8 pe-3">Sub Total : </label>
                            </div>
                            <div className="text-right" style={{ paddingRight: "3px" }}>
                              <label className="col-form-label py-0 fs-8 pe-3">Tax & Other Charges : </label>
                            </div>
                            <div className="text-right" style={{ paddingRight: "3px" }}>
                              <label className="col-form-label py-0 fs-8 pe-3">Warehouse Charge : </label>
                            </div>
                            {single_order?.coupon_amount > 0 ? (
                              <div className="text-right" style={{ paddingRight: "3px" }}>
                                <label className="col-form-label py-0 fs-8 pe-3">Coupon Discount : </label>
                              </div>
                            ) : null}
                            <div
                              className="text-right mt-2"
                              style={{ paddingRight: "3px", color: "#121212", fontWeight: "530", fontSize: "14px" }}
                            >
                              <label className="col-form-label py-0 pe-3">Total : </label>
                            </div>
                            <div className="text-right mt-2" style={{ paddingRight: "3px" }}>
                              <label className="col-form-label py-0 fs-8 pe-3">Delivery Charge : </label>
                            </div>
                            {single_order?.walletamount > 0 ? (
                              <div className="text-right" style={{ paddingRight: "3px" }}>
                                <label className="col-form-label py-0 fs-8 pe-3">Wallet Redeemed : </label>
                              </div>
                            ) : null}
                            {single_order?.payable > 0 ? (
                              <div
                                className="text-right mt-2"
                                style={{ paddingRight: "3px", color: "#121212", fontWeight: "530", fontSize: "14px" }}
                              >
                                <label className="col-form-label py-0 fs-8 pe-3">Payable : </label>
                              </div>
                            ) : null}
                          </div>

                          <div className="flex flex-col" style={{ fontSize: "13px", marginLeft: "12px" }}>
                            <div className="fs-8 ps-2 text-right">
                              ₹{parseFloat(single_order?.sub_total)?.toFixed(2)}
                            </div>
                            <div className=" fs-8 w-75px ps-2 text-right">
                              ₹
                              {(
                                parseFloat(single_order?.surge_fee ?? 0) +
                                parseFloat(single_order?.convenience_fee ?? 0) +
                                parseFloat(single_order?.tax ?? 0)
                              )?.toFixed(2)}
                            </div>
                            <div className=" fs-8 w-75px ps-2 text-right">
                              ₹{parseFloat(single_order?.warehouse_charges)?.toFixed(2)}
                            </div>
                            {single_order?.coupon_amount > 0 ? (
                              <div className=" fs-8 w-75px ps-2 text-right">
                                - ₹{parseFloat(single_order?.coupon_amount)?.toFixed(2)}
                              </div>
                            ) : null}

                            <div
                              className="text-right flex-grow-1 w-75px ps-2 mt-2"
                              style={{ color: "#121212", fontWeight: "530", fontSize: "14px" }}
                            >
                              ₹
                              {(parseFloat(single_order?.total) - parseFloat(single_order?.delivery_charge))?.toFixed(
                                2
                              )}
                            </div>

                            {single_order?.delivery_charge > 0 ? (
                              <div className=" fs-8 w-75px ps-2 text-right mt-2">
                                + ₹{parseFloat(single_order?.delivery_charge)?.toFixed(2)}
                              </div>
                            ) : (
                              <div className=" fs-8 w-75px ps-2 text-right mt-2">Free Delivery</div>
                            )}
                            {single_order?.walletamount > 0 ? (
                              <div className=" fs-8 w-75px ps-2 text-right">
                                - ₹{parseFloat(single_order?.walletamount)?.toFixed(2)}
                              </div>
                            ) : null}
                            {single_order?.payable > 0 ? (
                              <div
                                className=" fs-8 w-75px ps-2 text-right mt-2"
                                style={{ color: "#121212", fontWeight: "530", fontSize: "14px" }}
                              >
                                ₹{parseFloat(single_order?.payable)?.toFixed(2)}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="nk-divider divider sm"></div>
                        <div className="flex flex-row content-end justify-end items-end mx-1">
                          <div className="flex flex-col" style={{ fontSize: "13px" }}>
                            <div
                              className="text-right"
                              style={{ paddingRight: "3px", color: "#121212", fontWeight: "600", fontSize: "15px" }}
                            >
                              <label className="col-form-label py-0 pe-3">Net Total : </label>
                            </div>
                          </div>

                          <div className="flex flex-col" style={{ fontSize: "13px", marginLeft: "5px" }}>
                            <div
                              className="text-right flex-grow-1 w-75px ps-2"
                              style={{ color: "#121212", fontWeight: "600", fontSize: "15px" }}
                            >
                              ₹{parseFloat(single_order?.total)?.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="text-center py-5"> No Items to show</div>
                    )

                    //  ) )
                  }

                  {hasDeletedItems && (
                    <div style={{ color: "#8094ae", fontSize: "12px" }}>
                      <Badge color="info" onClick={() => printOrderBill(single_order.id)}>
                        {/* <FaPrint  size={10} className=""/> */} <div>Print Bill</div>
                      </Badge>
                    </div>
                  )}
                </CardText>
              </CardBody>
            </Card>
          </Col>

          <Col md="4">
            {/* delivery guy */}
            {single_order?.order_status_id == 7 || single_order?.order_status_id == 8 ? null : (
              <Row className="mt-2">
                <Col md="12">
                  <Card className="">
                    <CardBody className="card-inner">
                      <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                        <Icon name="account-setting-alt" className="mr-1"></Icon>Assign Delivery Guy
                      </CardSubtitle>
                      <CardText className="flex flex-col ml-1 pt-2">
                        <div className="">
                          <RSelect
                            options={deliveryguys}
                            onChange={(e) => setDeliveryGuy(e.value)}
                            defaultValue={deliveryguys?.find(
                              (guy) => guy.value === single_order?.accept_delivery?.delivery_guy_detail_id
                            )}
                          />
                        </div>
                        <div id="nodelivery" style={{ fontSize: "11px", color: "red", fontStyle: "italic" }}></div>
                        <div className="flex justify-end mt-2">
                          <div
                            className=""
                            style={
                              single_order?.order_status_id == 7 || single_order?.order_status_id == 8
                                ? { display: "none" }
                                : { display: "block" }
                            }
                          >
                            {deliveryUpdate && form_loading ? (
                              <>
                                <Button color="primary">
                                  <Spinner size="sm" color="light" />
                                  Updating
                                </Button>
                              </>
                            ) : (
                              <Button
                                color="primary"
                                onClick={() => {
                                  assignDeliveryGuy();
                                }}
                              >
                                <Icon name="folder-check" className="mr-1" />
                                Update
                              </Button>
                            )}
                          </div>
                        </div>
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {/* timeline */}
            <Row className="mt-2">
              <Col md="12">
                <Card className="">
                  <CardBody className="card-inner">
                    <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                      <Icon name="truck" className="mr-1"></Icon> Order Activity
                    </CardSubtitle>
                    <CardText className="flex flex-col items-center ml-1">
                      <div className="flex justify-between">
                        {single_order?.order_status_id == 1 ? (
                          <Button color="success" outline onClick={() => acceptTheOrder()}>
                            {!deliveryUpdate && form_loading ? (
                              <Spinner size="sm" color="success" />
                            ) : (
                              <Icon name="check-circle-fill" />
                            )}
                            Accept
                          </Button>
                        ) : [3, 4]?.includes(single_order?.order_status_id) ? (
                          <Button color="success" outline onClick={() => changeStatustoPickUp()}>
                            {!deliveryUpdate && form_loading ? (
                              <Spinner size="sm" color="success" />
                            ) : (
                              <Icon name="bell" />
                            )}
                            Ready to Pick
                          </Button>
                        ) : [5, 6]?.includes(single_order?.order_status_id) ? (
                          <Button color="success" outline onClick={() => completeTheOrder()}>
                            {!deliveryUpdate && form_loading ? (
                              <Spinner size="sm" color="success" />
                            ) : (
                              <Icon name="list-check" />
                            )}
                            Complete
                          </Button>
                        ) : (
                          ""
                        )}
                        {/* {console.log(deliveryguys?.find((guy) => guy.value === single_order?.accept_delivery?.delivery_guy_detail_id),'dddd')} */}
                        {deliveryguys?.find(
                          (guy) => guy.value === single_order?.accept_delivery?.delivery_guy_detail_id
                        ) != undefined && single_order?.order_status_id == 2 ? (
                          <Button color="success" outline onClick={() => acceptTheOrder()}>
                            {!deliveryUpdate && form_loading ? (
                              <Spinner size="sm" color="success" />
                            ) : (
                              <Icon name="check-circle-fill" />
                            )}
                            Accept
                          </Button>
                        ) : null}
                        <div
                          className=""
                          style={
                            single_order?.order_status_id == 7 || single_order?.order_status_id == 8
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <Button color="danger" outline onClick={() => openCancelModal()}>
                            <Icon name="cross-circle-fill" />
                            Cancel
                          </Button>
                        </div>
                      </div>
                      <div className="card-inner">
                        <div className="timeline">
                          <ul className="timeline-list">
                            {order_log?.length > 0 &&
                              order_log?.map((log, key) => {
                                return (
                                  <li className="timeline-item" key={log?.id}>
                                    <div
                                      className={
                                        key == order_log.length - 1
                                          ? "timeline-status bg-primary is-outline"
                                          : "timeline-status bg-primary"
                                      }
                                    ></div>
                                    <div className="timeline-date">
                                      {moment(log?.created_at).format("DD-MMM")}
                                      <Icon name="alarm-alt"></Icon>
                                    </div>
                                    <div className="timeline-data">
                                      <h6 className="timeline-title">{log?.description}</h6>
                                      <div className="timeline-des">
                                        <span className="time">{moment(log?.created_at).format("hh:mm A")}</span>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      {/* order status updating modal */}
      <Modal
        isOpen={modal.cancel}
        toggle={() => setModal({ cancel: false })}
        className="modal-dialog-centered"
        size="md"
      >
        <ModalBody className="pt-3 pb-2">
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">
              Cancel Order <Badge color="outline-primary">{single_order?.unique_order_id}</Badge> ?
            </h5>
            <Col md="12">
              <FormGroup>
                <label className="form-label">Cancellation Reason</label>
                <div className="form-control-wrap">
                  <RSelect
                    options={canceloptions}
                    defaultValue={canceloptions?.find((opt) => opt.value === cancelData?.reason)}
                    onChange={(val) => {
                      handleInput(val.value);
                    }}
                  />
                  <div className="mt-2" id="exmple123" style={cancelInput ? { display: "block" } : { display: "none" }}>
                    <Input
                      type="text"
                      name="other_reason"
                      placeholder="Enter reason here..."
                      onChange={(e) => {
                        setCancelData({ ...cancelData, other_reason: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>
            <div className="mt-3">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                <li>
                  <Button
                    type="button"
                    color="danger"
                    className="px-4 btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      cancelTheOrder();
                    }}
                  >
                    {form_loading ? (
                      <>
                        <Spinner size="sm" color="light" />
                        Cancelling Order
                      </>
                    ) : (
                      <React.Fragment>Cancel Order</React.Fragment>
                    )}
                  </Button>
                </li>
                <li>
                  <a
                    href="#cancel"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onFormCancel();
                    }}
                    className="link link-light"
                  >
                    Cancel
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* add and edit modal */}
      <Modal isOpen={modal.add} 
      toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title"> Add Item to Order</h5>
            <div className="mt-4">
              <form className="row gy-4" onSubmit={handleFormSubmit}>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Item Name</label>
                    <div className="form-control-wrap">
                      <Controller
                        name="list_items_id"
                        control={control}
                        render={({ onChange, value, ref }) => (
                          <RSelect
                            options={listItems}
                            onChange={(val) => {
                              onChange(val.value);
                              handleSelectChange(val); // Handle additional dropdown visibility
                              handleInputChange({ target: { name: "list_items_id", value: val.value } });
                            }}
                            defaultValue={listItems?.find((cat) => cat.value === value)}
                          />
                        )}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.list_items_id && (
                        <span className="invalid" style={{ color: "#e85347", fontSize: "11px", fontStyle: "italic" }}>
                          Please choose an Item
                        </span>
                      )}
                    </div>
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Quantity</label>
                    <input
                      className="form-control"
                      type="number"
                      name="quantity"
                      value={formData.quantity}
                      onChange={handleInputChange}
                      placeholder="Enter Quantity"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.quantity && <span className="invalid">{errors.quantity.message}</span>}
                  </FormGroup>
                </Col>
                {showAddonDropdown && (
              <Col md="6">
                <FormGroup>
                  <label className="form-label">Addon Category</label>
                  <Controller
                    name="addon_category_id"
                    value={formData.addon}

                    control={control}
                    render={({ onChange, value }) => (
                      <RSelect
                        options={addonCategories.map(category => ({ value: category.id, label: category.name }))}
                        onChange={(val) => {
                          onChange(val.value);
                          handleAddonCategoryChange(val.value);
                        }}
                        defaultValue={addonCategories.find(category => category.id === selectedAddonCategory)}
                      />
                    )}
                    ref={register()}
                  />
                </FormGroup>
              </Col>
            )}
              {showVarientDropdown && (

              <Col md="6">
                <FormGroup>
                  <label className="form-label">Variants</label>
                  <Controller
                    name="varient_id"
                    value={formData.variant}

                    control={control}
                    render={({ onChange, value }) => (
                      <RSelect
                        options={varient.map(category => ({ value: category.id, label: category.name }))}
                        onChange={(val) => {
                          onChange(val.value);
                          handleVarientChange(val.value);
                        }}
                        defaultValue={varient.find(category => category.id === setSelectedvarient)}
                      />
                    )}
                    ref={register()}
                  />
                </FormGroup>
              </Col>


            )}

                <Col md="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" className="px-4 btn-block" type="submit">
                        {/* {form_loading ? (
                <>
                  <Spinner size="sm" color="light" /> Creating New Item
                </>
              ) : ( */}
                        {/* <React.Fragment> */}
                        Add New Item
                        {/* </React.Fragment> */}
                        {/* )} */}
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* delete modal */}
      <Modal isOpen={modal.delete} toggle={() => setModal({ delete: false })} className="modal-dialog-centered">
        <ModalBody className="pt-3 pb-2">
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">
              Delete Item <Badge color="primary">{/* {deletingItem?.name} */}</Badge> ?
            </h5>
            <div className="mt-2">
              <span>This Item will be deleted. Do you really want to delete this item ?</span>
            </div>
            <div className="mt-3">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                <li>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      deleteItemOrder(dltitem);
                    }}
                    color="danger"
                    className="px-4 btn-block"
                  >
                    {/* {form_loading ? (
                      <>
                        <Spinner size="sm" color="light" />
                        Deleting
                      </>
                    ) : ( */}
                    <React.Fragment>Delete</React.Fragment>
                    {/* )}  */}
                  </Button>
                </li>
                <li>
                  <a
                    href="#cancel"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onFormCancel();
                    }}
                    className="link link-light"
                  >
                    Cancel
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default SingleOrder;
