import React, { useState } from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import Menu from "../menu/Menu";
import Toggle from "./Toggle";
import EcommerceMenu from "../menu/EcommerceMenu";
import AdminMenu from '../menu/AdminMenu';
import Logo from '../../assets/icon-green.svg'

const Sidebar = ({ fixed, theme, className, sidebarToggle, mobileView, ...props }) => {
    const [collapseSidebar, setSidebar] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(false);

    const toggleCollapse = () => {
        setSidebar(!collapseSidebar);
    };

    const handleMouseEnter = () => setMouseEnter(true);
    const handleMouseLeave = () => setMouseEnter(false);

    const classes = classNames({
        "nk-sidebar": true,
        "nk-sidebar-fixed": fixed,
        "is-compact": collapseSidebar,
        "has-hover": collapseSidebar && mouseEnter,
        [`is-light`]: theme === "white",
        [`is-${theme}`]: theme !== "white" && theme !== "light",
        [`${className}`]: className,
    });

    return (
        <div className={classes}>
            <div className="nk-sidebar-element nk-sidebar-head user-select-none">
                <div className="nk-sidebar-brand">
                    {/* <Logo /> */}
                    {/* <h3 className="text-dark fs-1 ">Sylcon</h3> */}
                    <img src={Logo} alt="sylcon-icon" style={{  width: '10rem' }} />
                </div>
                <div className="nk-menu-trigger mr-n2">
                    <Toggle className="nk-nav-toggle nk-quick-nav-icon d-xl-none mr-n2" icon="arrow-left" click={sidebarToggle} />
                    <Toggle
                        className={`nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex ${collapseSidebar ? "compact-active" : ""
                            }`}
                        click={toggleCollapse}
                        icon="menu"
                    />
                </div>
            </div>
            <div className="nk-sidebar-content" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <SimpleBar className="nk-sidebar-menu">
                    {window.location.pathname.split("/")[2] === "ecommerce" ? (
                        <>
                            <Menu sidebarToggle={sidebarToggle} mobileView={mobileView} />
                        </>
                    ) : (
                        <>
                            <AdminMenu sidebarToggle={sidebarToggle} mobileView={mobileView} />
                            {/*  */}
                        </>
                    )}
                </SimpleBar>
            </div>
        </div>
    );
};
export default Sidebar;
