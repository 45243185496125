import {
    GET_ALL_BRANDS,
    GET_ALL_ITEMS,
    GET_ALL_ITEM_VARIANTS,
    GET_ALL_ITEM_GROUPS,
    GET_ITEM_SCHEDULE_DATA,
    GET_SINGLE_ITEM,
    GET_ITEM_ADDON_CATEGORY,
    UPDATE_ITEM_ADDON_CATEGORY,
    GET_ALL_ITEMS_WITH_STOCK,
    GET_SINGLE_ITEM_VARIANTS,
} from "./actionType";

const initialState = {
    brands: [],
    items: [],
    single_item_variants: [],
    item_variants: [],
    stock_items: [],
    item_addon_category: [],
    single_item: '',
    schedule_data: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_BRANDS:
            return { ...state, brands: action.payload };
        case GET_ALL_ITEMS:
            return { ...state, items: action.payload };
        case GET_SINGLE_ITEM_VARIANTS:
            return { ...state, single_item_variants: action.payload };
        case GET_ALL_ITEM_VARIANTS:
            return { ...state, item_variants: action.payload };
        case GET_ALL_ITEMS_WITH_STOCK:
            return { ...state, stock_items: action.payload };
        case GET_ITEM_SCHEDULE_DATA:
            return { ...state, schedule_data: action.payload };
        case GET_SINGLE_ITEM:
            return { ...state, single_item: action.payload };
        case GET_ALL_ITEM_GROUPS:
            return { ...state, item_groups: action.payload };
        case GET_ITEM_ADDON_CATEGORY:
            return { ...state, item_addon_category: action.payload };
        case UPDATE_ITEM_ADDON_CATEGORY:
            return { ...state, item_addon_category: action.payload };
        default:
            return state;
    }
}
