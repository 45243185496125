import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, } from 'react-router-dom';
import { Badge, Card, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import { Icon, UserAvatar } from '../../../../components/Component';
import Content from '../../../../layout/content/Content';
import { getSingleUser } from '../../../../redux/admin/user/action';
import { findUpper } from '../../../../utils/Utils';
import BankDetails from './BankDetails';
import PersonalDetails from './PersonalDetails';
import ToastExample from "../../../../helpers/toast";
import { ColorRing } from 'react-loader-spinner'
import { getAllRoles } from '../../../../redux/common/actions';
import RolesPermission from './RolesPermission';
import UserOrder from './UserOrder';
import ReferAndEarn from './ReferAndEarn';
import Wallet from './Wallet';
import DeliverGuy from './DeliveryGuy';
import { getAllWarehouses } from '../../../../redux/admin/warehouse/action';
import SliverStar from "../../../../assets/svg/silverStar.svg"
// import GoldStar from "../../../../assets/svg/goldStar.svg"
// import GoldStar from "../../../../assets/svg/goldStar.svg"


function EditUser() {
    const dispatch = useDispatch();
    const getSelectors = (state) => ({
        user: state.users.single_user.user,
        user_details: state.users.single_user,
        roles: state.common.roles,
        orders: state.users.orders,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });
    const { user, user_details, roles, orders, form_loading, form_submission_status, } = useSelector(getSelectors);

    const [type, setType] = useState({
        personal_info: true,
        bank_details: false,
        permission: false,
        order: false,
        refer: false,
        delivery: false,
        wallet: false,
    });

    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(getAllRoles());
        dispatch(getAllWarehouses());
        dispatch(getSingleUser(id)).then((response) => {
            setLoading(false);
        })
    }, [])

    // setting active tab in edit user
    const activeTab = (type) => {
        setType({ permission: false, personal_info: false, bank_details: false, order: false, refer: false, wallet: false, delivery: false });
        if (type === "PERSONAL") {
            setType({ personal_info: true, })
        } else if (type === "ROLE") {
            setType({ permission: true, })
        } else if (type === "BANK") {
            setType({ bank_details: true, })
        } else if (type === "ORDER") {
            setType({ order: true, })
        } else if (type === "REFER") {
            setType({ refer: true, })
        } else if (type === "WALLET") {
            setType({ wallet: true, })
        } else if (type === "DELIVERY") {
            setType({ delivery: true, })
        }
    }

    return (
        <React.Fragment>
            {loading ? (
                <Content >
                    <div className='d-flex align-items-end justify-content-center'>
                        <ColorRing
                            visible={true}
                            height="120"
                            width="120"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                    </div>
                </Content>
            ) : (
                <Content>
                    <Card className="card-bordered">
                        <div className="card-aside-wrap">
                            <div className={`card-aside card-aside-left user-aside  content-active}`}>
                                <div className="card-inner-group">
                                    <div className="card-inner">
                                        <div className=" flex justify-between items-center user-card">
                                            <div className="user-info">
                                                <span className="lead-text">{user?.name}</span>
                                                <span className="sub-text">{user?.email}</span>
                                                <Badge pill color="success">{user && user?.roles && user?.roles[0] && user?.roles[0]?.name}</Badge>
                                            </div>
                                            <div className="flex flex-column justify-between items-center user-card px-1" >
                                                <div>
                                                    <img src={SliverStar} style={{width:"4rem"}} alt='sylcon' />
                                                </div>
                                                <div className=''>
                                                  Sliver
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-inner">
                                        <div className="flex justify-around items-center  py-0">
                                            <div className="flex flex-column justify-around items-center text-center">
                                                <div className="">
                                                    {user_details?.order_count??0}
                                                </div>
                                                <div style={{ fontWeight: '600' }}>
                                                    Orders
                                                </div>
                                            </div>
                                            <div className="flex flex-column justify-around items-center text-center">
                                                <div className="">
                                                    ₹{user_details?.order_total?parseFloat(user_details?.order_total).toFixed(2):0.00}
                                                </div>
                                                <div style={{ fontWeight: '600' }}>
                                                    Order Value
                                                </div>
                                            </div>
                                            <div className="flex flex-column justify-around items-center text-center">
                                                <div className="">
                                                    ₹{(user?.wallet_amount) > 0 ? parseFloat(user?.wallet_amount).toFixed(2) : parseFloat(0).toFixed(2)}
                                                </div>
                                                <div style={{ fontWeight: '600' }}>
                                                    Wallet
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-inner p-0">
                                        <ul className="link-list-menu">
                                            <li >
                                                <Link className={type.personal_info ? "active" : ""} to="#"
                                                    onClick={() => activeTab('PERSONAL')}>
                                                    <Icon name="user-fill-c"></Icon>
                                                    <span>Personal Information</span>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link className={type.bank_details ? "active" : ""} to="#"
                                                    onClick={() => activeTab('BANK')}>
                                                    <Icon name="building-fill" />
                                                    <span>Bank Details</span>
                                                </Link>
                                            </li>
                                            {roles && roles.length > 0 &&
                                                <li >
                                                    <Link className={type.permission ? "active" : ""} to="#"
                                                        onClick={() => activeTab('ROLE')}>
                                                        <Icon name="shield-check-fill"></Icon>
                                                        <span>Roles & Permission</span>
                                                    </Link>
                                                </li>
                                            }
                                            {user?.roles && user?.roles[0] && user?.roles[0].name == "Delivery Partner" &&
                                                <li >
                                                    <Link className={type.delivery ? "active" : ""} to="#"
                                                        onClick={() => activeTab('DELIVERY')}>
                                                        <Icon name="account-setting-fill" />
                                                        <span>Delivery Guy</span>
                                                    </Link>
                                                </li>
                                            }
                                            <li >
                                                <Link className={type.order ? "active" : ""} to="#"
                                                    onClick={() => activeTab('ORDER')}>
                                                    <Icon name="truck" />
                                                    <span>Orders</span>
                                                </Link>
                                            </li>
                                            {user?.roles && user?.roles[0] && user?.roles[0].name === 'Customer' &&
                                                <li >
                                                    <Link className={type.refer ? "active" : ""} to="#"
                                                        onClick={() => activeTab('REFER')} >
                                                        <Icon name="coin-alt-fill"></Icon>
                                                        <span>Refer & Earning</span>
                                                    </Link>
                                                </li>
                                            }
                                            <li >
                                                <Link className={type.wallet ? "active" : ""} to="#"
                                                    onClick={() => activeTab('WALLET')} >
                                                    <Icon name="wallet-fill" />
                                                    <span>Wallet</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card-inner card-inner-lg">

                                {type.personal_info && !loading &&
                                    <PersonalDetails />
                                }
                                {type.bank_details && !loading &&
                                    <BankDetails user_id={id}/>
                                }
                                {roles && roles.length > 0 && type.permission && !loading &&
                                    <RolesPermission user_id={id} />
                                }
                                {user?.roles && user?.roles[0]?.name === 'Delivery Partner' && type.delivery && !loading &&
                                    <DeliverGuy user_id={id} />
                                }
                                {type.order && !loading &&
                                    <UserOrder  user_id={id} />
                                }
                                {user?.roles && user?.roles[0].name === 'Customer' && type.refer && !loading &&
                                    <ReferAndEarn user_id={id} />
                                }
                                {type.wallet && !loading &&
                                    <Wallet user_id={id}/>
                                }
                            </div>
                        </div>
                    </Card>
                </Content>
            )
            }
        </React.Fragment>
    )
}

export default EditUser
