import React from "react";
import { useEffect, useState } from "react";
// import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Lottie from "react-lottie";
import { Map, GoogleApiWrapper } from "google-maps-react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId, getLatLng, geocodeByAddress } from "react-google-places-autocomplete";
import load from "../../../../assets/lottie/track-order-loading.json";
import Bounce from "react-reveal/Bounce";
import Flip from "react-reveal/Flip";
import marker from "../../../../assets/images/marker.png";
// import { TbCurrentLocation } from "react-icons/tb";
import { useDispatch } from "react-redux";
// import { Form } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { BiSearch } from "react-icons/bi";
// import { CgSearch } from "react-icons/cg";
import { Link, useLocation } from "react-router-dom";
import { BiCurrentLocation } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import LinesEllipsis from "react-lines-ellipsis";
import { Button, Col, Form, FormGroup } from "reactstrap";
// import {
//   addNewAddress,
//   getAddressToCoordinates,
// } from "../../../redux/store/userReducer";

const defaultOptions = {
  animationData: load,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const LoadingContainer = (props) => (
  <div className="flex items-center justify-center" style={{ paddingTop: "15rem" }}>
    <Lottie options={defaultOptions} height={100} width={100} />
  </div>
);

function CreateOrdersMap() {
  const editAddress = JSON.parse(localStorage.getItem("editAddress")) ?? null;
  const IsEditMyAddress = localStorage.getItem("IsEditMyAddress");
  const firstTime = JSON.parse(localStorage.getItem("firstTime")) ?? false;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [gpsLoading, setGpsLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(true);
  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(true);
  const [dragging, setDragging] = useState(false);
  const [mapCenter, setMapCenter] = useState([]);
  const [initialPosition, setInitialPosition] = useState([]);
  const [centerAroundCurrentLocation, setCenterAroundCurrentLocation] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState([]);
  const [value, setValue] = useState(null);

  const [locationDetails, setLocationDetails] = useState({
    landmark: "",
    house: "",
  });
  const loco = useLocation();

  useEffect(() => {
    if (localStorage.getItem("address")) {
      setMapCenter({
        lat: localStorage.getItem("userLat"),
        lng: localStorage.getItem("userLng"),
      });
    } else {
      setMapCenter({
        lat: localStorage.getItem("userLat"),
        lng: localStorage.getItem("userLng"),
      });
      setInitialPosition({
        lat: localStorage.getItem("userLat"),
        lng: localStorage.getItem("userLng"),
      });
    }
  }, []);

  const handleGeoLocationClick = (results) => {
    setGpsLoading(true);
    const saveGeoLocation = new Promise((resolve) => {
      localStorage.setItem("geoLocation", JSON.stringify(results[0]));
      localStorage.setItem("address", results[0]?.formatted_address);
      const userSetAddress = {
        address: results[0]?.formatted_address,
      };
      localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
      setDefaultAddress(results[0].formatted_address);
      setTimeout(() => {
        localStorage.setItem("userLat", results[0].geometry.location.lat());
        localStorage.setItem("userLng", results[0].geometry.location.lng());
        setMapCenter({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      }, 200);
      resolve("GeoLocation Saved");
    });

    saveGeoLocation.then(() => {
      setGpsLoading(false);
      setCurrentLocation(false);
    });
  };

  const getMyLocation = (callback) => {
    const location = navigator && navigator.geolocation;
    if (window.ReactNativeWebView) {
      const response = {
        type: "location_permission",
        data: "no-data",
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(response));
    }
    setGpsLoading(true);
    if (location) {
      location.getCurrentPosition(
        (position) => {
          reverseLookup(position.coords.latitude, position.coords.longitude, callback);
        },
        (error) => {
          setGpsLoading(true);
          console.log(error);
        }
      );
    }
  };

  const reverseLookup = (latitude, longitude, callback) => {
    var lat, lng;
    if (IsEditMyAddress && firstTime) {
      lat = localStorage.getItem("userLat");
      lng = localStorage.getItem("userLng");
    } else {
      lat = latitude;
      lng = longitude;
    }
  };

  const onMarkerDragEnd = (map) => {
    localStorage.setItem("userLat", map.center.lat());
    localStorage.setItem("userLng", map.center.lng());
    reverseLookup(map.center.lat(), map.center.lng());
  };

  const handleDragging = () => {
    setDragging(true);
    localStorage.removeItem("firstTime");
  };

  const handlChanges = (e, type) => {
    setLocationDetails((previousLocationDetails) => {
      const updatedLocationDetails = { ...previousLocationDetails, [type]: e };
      return updatedLocationDetails;
    });
  };

  const addAddress = (e) => {
    e.preventDefault();
    localStorage.setItem("landmark", locationDetails.landmark);
    // window.location.replace("/createorder");
  };
  return (
    <div>
      {" "}
      <div>
        {loading || gpsLoading ? (
          <div className="flex flex-col justify-center items-center " style={{ paddingTop: "35vh" }}>
            <Lottie options={defaultOptions} height={100} width={100} />
            <span className="mt-3 fw-700 font-16">
              {gpsLoading ? (
                <div>
                  {/* <img src={loc} style={{ width: "100" }} /> */}
                  "gggggg"
                </div>
              ) : (
                "Going to location..."
              )}
            </span>
            {gpsLoading ? <span className="mt-3 fw-700 font-16">Please Select Location</span> : null}
          </div>
        ) : (
          <Map
            className="mappp"
            google={window.google}
            initialCenter={{
              lat: mapCenter.lat,
              lng: mapCenter.lng,
            }}
            center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
            zoom={15}
            onDragend={(t, map) => onMarkerDragEnd(map)} // This is the event handler that gets triggered when the dragging of the map or marker is completed.
            zoomControl={false}
            mapTypeControl={false}
            scaleControl={true}
            streetViewControl={false}
            fullscreenControl={false}
            // onReady={(mapProps, map) => {
            //   // The onReady prop allows you to define a callback function that will be called once the Google Map component is ready.
            //   dragging? reverseLookup(initialPosition?.lat, initialPosition?.lng): (initialPosition?.lat, initialPosition?.lng);
            //   localStorage.setItem("userLat", map.center.lat());
            //   localStorage.setItem("userLng", map.center.lng());
            // }}
            onDragstart={() => {
              //   //the purpose of onDragstart is to capture the start of the dragging operation for the marker on the map. When the user initiates the drag by clicking and holding the marker, the handleDragging function is invoked with the argument true
              handleDragging(true);
            }}
            centerAroundCurrentLocation={centerAroundCurrentLocation}
          >
            <div className="center-marker-pulse">
              <Bounce top duration={1000}>
                <img src={marker} alt="Marker" className="center-marker-full" />
              </Bounce>
            </div>
          </Map>
        )}
        <Form className="row gy-4 " encType="multipart/formData">
          <Col md="6">
            <div className=" ">
              <div className="">
                {/* <div>
                  <Link to={"/createorder"}>
                    <div
                      className="p-2"
                      style={{
                        borderRadius: "14px",
                        border: "1px solid #BBBDC1",
                        backgroundColor: "#fff",
                        boxShadow: "rgb(167 167 167) 0px 0px 20px 2px",
                      }}
                    >
                      <IoIosArrowBack size={22} color="#BBBDC1" />
                    </div>
                  </Link>
                  </div> */}
                <div
                  className="grow me-2 "
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    // boxShadow: "rgb(167 167 167) 0px 0px 20px 2px",
                  }}
                >
                  {googleScriptLoaded && (
                    <GooglePlacesAutocomplete
                      debounce={750}
                      withSessionToken={true}
                      loader={<div style={{ paddingTop: "35vh" }}></div>}
                      renderInput={(props) => (
                        <div className="input-location-icon-field flex">
                          <label className="form-label">Search location </label>
                          <FormGroup>
                            <input
                              {...props}
                              // className="search-input-location"
                              className="form-control"
                              placeholder={"Search Your Location..."}
                            />
                          </FormGroup>
                          {/* <div className="flex-grow flex justify-center items-center me-2">
                            <BiSearch className="search-icon right-0 h-5 w-5" style={{ color: "#055438" }} />
                          </div> */}
                        </div>
                      )}
                      renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                        <div className="location-suggestions-container">
                          {suggestions.length > 0 && (
                            <div
                              className="flex flex-start py-2 px-2 text-base font-normal"
                              style={{ color: "#828282" }}
                            >
                              SEARCH RESULTS
                            </div>
                          )}
                          {suggestions.map((suggestion, index) => (
                            <Flip top delay={index * 50} key={suggestion.id}>
                              <div
                                className="location-suggestion"
                                onClick={(event) => {
                                  onSelectSuggestion(suggestion, event);
                                  geocodeByPlaceId(suggestion.place_id)
                                    .then((results) => {
                                      getLatLng(results[0]).then(({ lat, lng }) =>
                                        setMapCenter({ lat: lat, lng: lng })
                                      );
                                      handleGeoLocationClick(results);
                                    })
                                    .catch((error) => console.error(error));
                                }}
                              >
                                <div className="flex flex-col pb-1 px-2">
                                  <div
                                    className="flex flex-row items-center gap-5  text-lg font-semibold"
                                    style={{ color: "#514F4F" }}
                                  >
                                    <div>
                                      <FaLocationDot className="mt-1" size={18} />
                                    </div>
                                    <div className="">
                                      <span className="location-main-name">
                                        {suggestion.structured_formatting.main_text}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex flex-start text-sm" style={{ color: "#828282" }}>
                                    <span className=" ">{suggestion.structured_formatting.secondary_text}</span>
                                  </div>
                                </div>
                              </div>
                            </Flip>
                          ))}
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Form>

        {/* <div onClick={() => getMyLocation()}>
          <BiCurrentLocation className=" fixed top-0 right-0 h-5 w-5" size={22} color="#000" />
        </div> */}

        {loading || gpsLoading ? (
          ""
        ) : (
          <div className="bottom-0 w-100 fixed">
            <div className="">
              <div>
                <div className="text-start px-3    py-3 grow ">
                  <LinesEllipsis
                    maxLine="1"
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      letterSpacing: "0.5px",
                      lineHeight: "0px",
                    }}
                  />
                </div>
                <div>
                  <Form className="row gy-4 " encType="multipart/formData" onSubmit={(e) => addAddress(e)}>
                    <Col md="12">
                      <div className="">
                        <div className="flex mt-2  flex-col justify-between">
                          <div className="pb-1">
                            <FormGroup>
                              <label className="form-label">Enter Address</label>
                              <input
                                type="text"
                                required
                                placeholder="Enter address"
                                name="landmark"
                                value={locationDetails.landmark}
                                className="form-control"
                                // style={{
                                //   width: "100%",
                                //   outline: "none", // Optional: Remove default outline
                                //   fontSize: "12px",
                                //   height: "45px",
                                //   background: "#f1f4f5",
                                //   borderRadius: "10px",
                                //   padding: "10px", // Optional: Remove default outline
                                // }}
                                onChange={(e) => handlChanges(e.target.value, "landmark")}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md="12">
                      <div className="flex mt-2  flex-col justify-between">
                        <div className="pb-1">
                          <FormGroup>
                            <label className="form-label">Work / Home</label>
                            <input
                              type="text"
                              required
                              placeholder="Work , Home"
                              name="house"
                              value={locationDetails.house}
                              onChange={(e) => handlChanges(e.target.value, "house")}
                              className="form-control"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </Col>

                    {/* <div className="px-3  pt-4 pb-4 w-100 bg-white">
                      <button type="submit" className="send-otp-button-location">
                        Save
                      </button>
                    </div> */}

                    <Col md="3">
                      <div className="">
                        <div className="pt-1">
                          <Button
                            // className="btn-icon py-1 px-2 btn-dim"
                            color="primary"
                            className="btn-block"
                            size="rg"
                            // onClick={(e) => _submitphone(e)}
                            // style={{ pointerEvents: "none" }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Form>

                  {/* <form onSubmit={(e) => addAddress(e)}> */}

                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyB9z3AqEqHc2rRndPVUrpdAJE_WQu94IM4",
  LoadingContainer: LoadingContainer,
})(CreateOrdersMap);
//AIzaSyBSNDy_BbefI292samhULmQrgXGm3sLSKw
