import React from 'react'
import { Badge, Card } from 'reactstrap'
import { UserAvatar } from '../../../../components/Component'
import noDocument from '../../../../assets/no-document.svg'

function RecentWarehouses({ recent_warehouses }) {
    return (
        <Card className="h-100">
            <div className="card-inner">
                <div className="card-title-group mb-2">
                    <div className="card-title">
                        <h6 className="title">Recently Added Warehouse</h6>
                    </div>
                </div>
                <ul className="nk-top-products">
                    {recent_warehouses.length > 0 ? recent_warehouses?.map((warehouse, idx) => (
                        <li className="item" key={idx}>
                            <div className="thumb">
                                <UserAvatar className="sm" theme='primary-dim' text={warehouse?.name?.charAt(0)?.toUpperCase()} ></UserAvatar>
                            </div>
                            <div className="info">
                                <div className="title">{warehouse?.name}</div>
                                <div className="price">{warehouse?.phone}</div>
                            </div>
                            <div className="total">
                                <div className="amount"><Badge color='warning'>{warehouse?.store_category?.name}</Badge></div>
                            </div>
                        </li>
                    )) :
                        <div className='pt-5'>
                            <div className='d-flex flex-column justify-content-center align-items-center pt-2'>
                                <div>
                                    <img src={noDocument} style={{ width: '3rem' }} alt='sylcon' />
                                </div>
                                <div>No Data Found</div>
                            </div>
                        </div>
                    }
                </ul>
            </div>
        </Card>
    )
}

export default RecentWarehouses
