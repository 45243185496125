import React, { useEffect, useState } from 'react'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Row, UncontrolledDropdown } from 'reactstrap'
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable } from '../../../../../components/Component'
import { WEBSITE_API_URL } from '../../../../../config'

import Content from '../../../../../layout/content/Content'
import Head from '../../../../../layout/head/Head'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { nonActiveFormSubmissionStatus } from '../../../../../redux/common/actions'
import { getAllCompletedStorePayouts } from '../../../../../redux/admin/report/action'

const CompletedPayouts = () => {
    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        completedPayouts: state.reports.completed_payouts,
    });

    const { completedPayouts } = useSelector(getSelectors);


    const [modal, setModal] = useState(false);
    // orders in current payout
    const [currentPayout, setCurrentPayout] = useState([]);



    // get all pending payout  onload
    useEffect(() => {
        dispatch(getAllCompletedStorePayouts());
    }, [])

    // payout order modal
    const openOrderModal = (payout) => {
        setCurrentPayout(payout)
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal(true);
        }, 100);
    };

    // export completed payouts
    const onExport = () => {
        const token = localStorage.getItem('accessToken');

        window.location = WEBSITE_API_URL + "/export-store-payouts?token=" + token + "&type=COMPLETED";
    }



    //headers of table
    const PayoutHeading = [

        {
            name: "Store Name",
            selector: (row) => row.name,
            sortable: true,
            hide: 370,
        },
        {
            name: "Total Amount",
            selector: (row) => row?.orders?.reduce((sum, row) => sum + parseFloat(row.sub_total), 0),
            sortable: true,
            hide: 370,
        },
        {
            name: "Tax Amount",
            selector: (row) => row?.orders?.reduce((sum, row) => sum + parseFloat(row.tax), 0),
            sortable: true,
            hide: 370,
        },
        {
            name: "Store Charge",
            selector: (row) => row?.orders?.reduce((sum, row) => sum + parseFloat(row.store_charges), 0),
            sortable: true,
            hide: 370,
        },
        {
            name: "Total Deductions",
            selector: (row) => row?.orders?.reduce((sum, row) => sum + parseFloat(row.commission), 0),
            sortable: true,
            hide: "sm",

        },
        {
            name: "Store Payout Amount",
            selector: (row) => row?.orders?.reduce((sum, row) => sum + parseFloat(row.payout_amount), 0),
            sortable: true,
            hide: 370,
        },
        {
            name: "Status",
            selector: (row) => row.is_payout_released,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <Badge pill color="success">
                        Completed
                    </Badge>

                );
            },
        },
        {
            name: "Actions",
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans row={row} />

                </div>

            ),
            sortable: false,
        },
    ];

    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a" onClick={(e) => { openOrderModal(row) }}>
                                <Icon name="eye-fill" />
                                View Orders
                            </DropdownItem>
                        </li>


                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    return (
        <React.Fragment>
            <Head title="Completed Store Payouts "></Head>

            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Completed Store Payouts
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {completedPayouts.length} Completed Payouts .</p>
                            </BlockDes>
                        </BlockHeadContent>

                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Row className='mb-3 '>

                        <Col md="12" className='d-flex justify-content-end'>

                            <Button outline color="primary" className='mx-3' size='lg' onClick={() => { onExport() }}>
                                <Icon name="download-cloud"></Icon>
                                <span>Export</span>
                            </Button>
                        </Col>


                    </Row>

                </Block>


                {/* datatable */}
                <ReactDataTable
                    data={completedPayouts}
                    columns={PayoutHeading}

                    pagination
                />

            </Content>


            {/* view completed payout orders modal */}
            <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody className="pt-3 pb-2">
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false)
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className='p-2'>
                        <h5 className="title">Orders</h5>
                        <table className="table table-row-dashed table-row-gray-300 gy-7 border text-center">
                            <thead>
                                <tr className="fw-bolder fs-6 text-gray-800">
                                    <th>Order Id</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPayout && currentPayout?.orders && currentPayout?.orders?.map((order) => (
                                    <tr key={order?.id}>
                                        <td>{order?.unique_order_id}</td>
                                        <td>{order?.payout_amount}</td>
                                        <td>{moment(order?.created_at).format('DD-MM-yyy')}</td>
                                        <td>
                                            <a href={"/view-order/" + order?.id} type="button" className=" btn btn-success btn-sm">
                                                <Icon name="eye-fill" />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                    </div>

                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default CompletedPayouts
