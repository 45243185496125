import React, { useEffect, useState } from 'react'
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import SalesData from './salesData';
import moment from 'moment';

function SalesStatisticsLimitzo({ weekly_orders }) {

    const [salesData, setSalesData] = useState({ labels: [], dataUnit: 'People', datasets: [] });
    useEffect(() => {
        var days = [];
        var cancel_data = [];
        var order_data = [];
        for (let i = 1; i <= 7; i++) {
            let day = moment().subtract(i, 'days');
            days.push(day.format('DD MMM'));
            const cancelled_orders = weekly_orders?.filter((order) => (order.order_status_id == 8 && moment(order.created_at).format('D-M-Y') == day.format('D-M-Y')))?.length
            const orders = weekly_orders?.filter((order) => (moment(order.created_at).format('D-M-Y') == day.format('D-M-Y')))?.length
            order_data.push(orders)
            cancel_data.push(cancelled_orders)
        }
        var orderdata = [
            {
                label: "Total orders",
                color: "#9d72ff",
                borderWidth: 2,
                borderColor: "#9d72ff",
                fill: true,
                dash: 0,
                pointRadius: 0,
                lineTension: 0.4,
                backgroundColor: "rgba(157, 114, 255, 0.15)",
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#9d72ff",
                pointBorderWidth: 2,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 2,
                pointHitRadius: 4,
                data: order_data ? order_data?.reverse() : [],
            },
            {
                label: "Canceled orders",
                color: "#eb6459",
                borderWidth: 2,
                borderColor: "#eb6459",
                borderDash: [5],
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#eb6459",
                pointBorderWidth: 2,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 2,
                pointHitRadius: 4,
                backgroundColor: "transparent",
                data: cancel_data ? cancel_data?.reverse() : [],
            },
        ]
        var dates = days?.reverse();
        setSalesData({ ...salesData, datasets: orderdata, labels: dates })
    }, [weekly_orders]);

    return (
        <Card>
            <div className="nk-ecwg nk-ecwg8 h-100">
                <div className="card-inner">
                    <div className="card-title-group mb-3">
                        <div className="card-title">
                            <h6 className="title">Sales Statistics</h6>
                        </div>
                        <div className="card-tools">
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    tag="a"
                                    href="#toggle"
                                    onClick={(ev) => ev.preventDefault()}
                                    className="link link-light link-sm "
                                >
                                    Weekly
                                </DropdownToggle>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                    <ul className="nk-ecwg8-legends">
                        <li>
                            <div className="title">
                                <span className="dot dot-lg sq" style={{ background: "#6576ff" }}></span>
                                <span>Total Order</span>
                            </div>
                        </li>
                        <li>
                            <div className="title">
                                <span className="dot dot-lg sq" style={{ background: "#eb6459" }}></span>
                                <span>Cancelled Order</span>
                            </div>
                        </li>
                    </ul>
                    <div className="nk-ecwg8-ck">
                        <SalesData salesData={salesData} />
                    </div>
                    <div className="chart-label-group pl-5">
                        <div className="chart-label">{salesData?.labels[0]}</div>
                        <div className="chart-label">{salesData?.labels[6]}</div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default SalesStatisticsLimitzo