import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, FormGroup, Row } from 'reactstrap'
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from '../../../../../components/Component'
import ToastExample from '../../../../../helpers/toast'
import Content from '../../../../../layout/content/Content'
import Head from '../../../../../layout/head/Head'
import * as yup from "yup";
import { string } from "yup";
import { openFormLoading } from '../../../../../redux/common/actions'
import { getSingleUser, updateBankDetails } from '../../../../../redux/admin/user/action'


function BankDetails(user_id) {
    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        user: state.users.single_user.user,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });

    const { form_loading, form_submission_status, user } = useSelector(getSelectors);
    const [showToast, setShowToast] = useState(false)
    const [bankDetails, setBankDetails] = useState( user.bank_detail)

    // form validation schema
    const bankSchema = yup.object({
        name: string().required(),
        bank_name: string().required(),
        ifsc: string().required(),
        account_number: string().required(),

    });

    // schema to edit form data
    const defaultValues = useMemo(
        () => ({
            id: bankDetails?.id || "",
            name: bankDetails?.holder_name || "",
            bank_name: bankDetails?.bank_name || "",
            ifsc: bankDetails?.ifsc || "",
            account_number: bankDetails?.account || "",

        }),
        [bankDetails]
    );

    const methods = useForm({
        resolver: yupResolver(bankSchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    const onFormSubmit = (submitData) => {

        dispatch(openFormLoading());
        const formData = new FormData();
        formData.append('id', user?.id);
        formData.append('name', submitData?.name);

        formData.append('bank_name', submitData?.bank_name);
        formData.append('account_number', submitData?.account_number);
        formData.append('ifsc', submitData?.ifsc);

        dispatch(updateBankDetails(formData)).then((response) => {
            if (response.payload.success) {
                setTimeout(() => {
                    setShowToast(true);
                    dispatch(getSingleUser(user.id))
                }, 500)
                setTimeout(() => {
                    setShowToast(false);
                }, 3000)
            }
        })
    }
    return (
        <React.Fragment>
            <Head title="Bank Details"></Head>
            <Block size="lg">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Bank Info</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <ToastExample show={showToast} />
                    </BlockHeadContent>
                </BlockBetween>
            </Block>
            <Block>
                <div className="nk-data data-list">
                    <div className="data-head">
                        <h6 className="overline-title">user Account details</h6>
                    </div>
                    <Form className="gy-3" onSubmit={handleSubmit(onFormSubmit)}>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label" htmlFor="user-name">
                                        Holder Name
                                    </label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            type="text"
                                            id="user-name"
                                            name='name'
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Account Number</label>

                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input type="text" id="account_number"
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            name="account_number"
                                        />
                                        {errors.account_number && <span className="invalid">{errors.account_number.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Bank Name</label>

                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input type="text" id="bank_name" className="form-control" name='bank_name'
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.bank_name && <span className="invalid">{errors.bank_name.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">IFSC</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input type="text" id="ifsc" className="form-control" name='ifsc'
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.ifsc && <span className="invalid">{errors.ifsc.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col lg="7" className="offset-lg-5">
                                <FormGroup className="mt-2">
                                    <Button type='submit' color="primary" size="lg" >
                                        Update
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default BankDetails
