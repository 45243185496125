import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Spinner, UncontrolledDropdown } from 'reactstrap'
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable, RSelect } from '../../../components/Component'
import ToastExample from '../../../helpers/toast'
import Content from '../../../layout/content/Content'
import Head from '../../../layout/head/Head'
import { createMessage, deleteMessage, getAllCities, getAllMessages, toggleMessage, updateMessage } from '../../../redux/admin/cities/actions'
import { nonActiveFormSubmissionStatus, openFormLoading } from '../../../redux/common/actions'
import * as yup from "yup";

const Messages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMessages());
    dispatch(getAllCities());
  }, []);

  const getSelectors = (state) => ({
    cities: state.cities.cities,
    messages: state.cities.messages,
    form_loading: state.common.form_loading,
    form_submission_status: state.common.form_submission_status,
  });
  const { cities, messages, form_loading, form_submission_status, } = useSelector(getSelectors);

  const [deletingMessage, setDeletingMessage] = useState([]);

  const [formData, setFormData] = useState([]);

  const [showToast, setShowToast] = useState(false);

  const [data, setData] = useState([]);
  const [editingMessage, setEditingMessage] = useState();

  const [modal, setModal] = useState({
    edit: false,
    add: false,
    delete: false,
    link: false
  });

  const listCities = cities?.map((city) => ({ value: city.id, label: city.name }));
  const typeOptions = [
    { value: "STORE", label: "Store Application" },
    { value: "CUSTOMER", label: "Customer Application" },
    { value: "DELIVERY", label: "Delivery Agent Application" },
  ]
  //to open add modal
  const openAddModal = () => {
    setEditingMessage([]);
    dispatch(nonActiveFormSubmissionStatus());
    setTimeout(() => {
      setModal({ add: true });
    }, 100);
  };

  //to open edit modal
  const openEditModal = (message) => {
    setModal({ edit: true });
    dispatch(nonActiveFormSubmissionStatus());
    setEditingMessage(message);
  };

  //to open delete modal
  const openDeleteModal = (message) => {
    setModal({ delete: true });
    dispatch(nonActiveFormSubmissionStatus());
    setDeletingMessage(message);
  };

  //to close modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false, delete: false });
    document.getElementsByClassName("invalid").innerHTML = "";
    resetForm();
  };

  const resetForm = () => {
    setFormData({
      message: "",
      type: "",
      city_id: "",
    });
  };

  const defaultValues = useMemo(() => ({
    id: editingMessage?.id || "",
    message: editingMessage?.message || "",
    type: editingMessage?.type || "",
    city_id: editingMessage?.city_id || "",
  }), [editingMessage]);

  const messageSchema = yup.object({
    message: yup.string().required(),
    type: yup.string().required(),
    city_id: yup.string().required(),
  });

  const methods = useForm({
    resolver: yupResolver(messageSchema),
    defaultValues: defaultValues,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control
  } = methods;

  const onFormSubmit = (submitData) => {
    dispatch(openFormLoading());
    console.log(submitData, 'submitdata');
    const new_data = new FormData();
    new_data.append('id', editingMessage?.id);
    new_data.append('message', submitData?.message);
    new_data.append('type', submitData?.type);
    new_data.append('city_id', submitData?.city_id);
    if (modal.edit) {
      dispatch(updateMessage(new_data));
    } else if (modal.add) {
      dispatch(createMessage(new_data));
    } else if (modal.delete) {
      dispatch(deleteMessage(deletingMessage?.id));
    }
    resetForm();
  };

  useEffect(() => {
    if (modal.add == true && form_loading == false && form_submission_status == true) {
      setTimeout(() => {
        dispatch(getAllMessages());
        setData(messages);
        setModal({ add: false });
        setShowToast(true);
      }, 500);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }

    if (modal.edit == true && editingMessage && form_loading == false && form_submission_status == true) {
      dispatch(getAllMessages());
      setData(messages);
      setModal({ edit: false });
      setShowToast(true);
      reset(defaultValues);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    } else {
      reset(defaultValues);
    }

    if (modal.delete == true && deletingMessage && form_loading == false && form_submission_status == true) {
      setTimeout(() => {
        dispatch(getAllMessages());
        setData(messages);
        setModal({ delete: false });
        setShowToast(true);
      }, 500);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }

  }, [form_loading, modal, form_submission_status]);

  // toggle
  const handleStatus = (e, id) => {
    dispatch(toggleMessage(id));
    setTimeout(() => {
      dispatch(getAllMessages());
      setShowToast(true);
    }, 500);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  //headers of datatable
  const tableHeadings = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row?.city ? row?.city?.name : 'No City Found',
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      compact: true,
      style: { paddingRight: "20px" },
      cell: (row) => {
        return (
          <div className="user-card mt-2 mb-2" id={row.id}>
            <Badge color={row?.type === "CUSTOMER" ? "outline-info" : row?.type === "STORE" ? "outline-primary" : "outline-warning"} >
              {row?.type}
            </Badge>
          </div>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      compact: true,
      style: { paddingRight: "20px" },
      cell: (row) => {
        return (
          <div className="user-card mt-2 mb-2" id={row.id}>
            <Button color={row.is_active ? "success" : "danger"} onClick={(e) => handleStatus(e, row.id)}>
              {row.is_active ? <Icon name="stop-circle-fill" /> : <Icon name="play-circle-fill" />}
            </Button>
          </div>
        );
      },
    },
    {
      name: "Actions",
      selector: (row) => row.is_surge,
      compact: true,
      grow: 1,
      style: { paddingRight: "20px" },
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <DropdownTrans row={row} />
        </div>
      ),
      sortable: false,
    },
  ];

  //action dropdown
  const DropdownTrans = ({ row }) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-plain">
            <li>
              <DropdownItem tag="a" onClick={() => openEditModal(row)}>
                <Icon name="pen" />Edit
              </DropdownItem>
            </li>
            <li>
              <DropdownItem tag="a"
                onClick={() => openDeleteModal(row)}>
                <Icon name="trash" />Delete
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <React.Fragment>
      <Head title={'Messages'} />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Message Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {messages?.length} Messages.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <ToastExample show={showToast} />
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <a
                        href="#export"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="btn btn-white btn-outline-light"
                      >
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </a>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon"
                        onClick={() => openAddModal()}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <ReactDataTable
          data={messages}
          columns={tableHeadings}
          expandableRows
          pagination />
      </Content>

      {/* add and edit modal */}
      <Modal
        isOpen={modal.add || modal.edit}
        toggle={() => setModal({ add: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">
              {modal.edit ? "Editing Message - " + editingMessage?.name : "Add  Message"}
            </h5>
            <div className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Message</label>
                    <input
                      className="form-control"
                      type="text"
                      name="message"
                      placeholder="Enter Message"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.message && <span className="invalid">{errors.message.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Type</label>
                    <div className="form-control-wrap">
                      <Controller
                        name="type"
                        control={control}
                        render={({ onChange, value, ref }) => (
                          <RSelect
                            options={typeOptions}
                            onChange={(val) => {
                              onChange(val.value);
                            }}
                            defaultValue={typeOptions.find((t) => t.value === value)}
                          />
                        )}
                      />
                      {errors.type && (
                        <span className="invalid" style={{ color: '#e85347', fontSize: '11px', fontStyle: 'italic' }}>Please choose a Type</span>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">City</label>
                    <div className="form-control-wrap">
                      <Controller
                        name="city_id"
                        control={control}
                        render={({ onChange, value, ref }) => (
                          <RSelect
                            options={listCities}
                            onChange={(val) => {
                              onChange(val.value);
                            }}
                            defaultValue={listCities.find((c) => c.value === value)}
                          />
                        )}
                      />
                      {errors.city_id && (
                        <span className="invalid" style={{ color: '#e85347', fontSize: '11px', fontStyle: 'italic' }}>Please choose a City</span>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button type="submit" color="primary" size="lg" className="btn-block">
                        {form_loading ? (
                          <>
                            <Spinner size="sm" color="light" /> {modal.edit ? "Updating Message" : "Creating New Message"}
                          </>
                        ) : (
                          <React.Fragment>{modal.edit ? "Update Message" : "Add New Message"}</React.Fragment>
                        )}
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* delete modal */}
      <Modal
        isOpen={modal.delete}
        toggle={() => setModal({ delete: false })}
        className="modal-dialog-centered"
      >
        <ModalBody className='pt-3 pb-2'>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Delete Message</h5>
            <div className='mt-2'>
              <span>Do you really want to delete this Message ?</span>
            </div>
            <div className="mt-3">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                <li>
                  <Button onClick={(e) => {
                    e.preventDefault();
                    onFormSubmit();
                  }} color="danger" className="px-4 btn-block">
                    {form_loading ? (
                      <>
                        <Spinner size="sm" color="light" />Deleting
                      </>
                    ) : (
                      <React.Fragment>Delete</React.Fragment>
                    )}
                  </Button>
                </li>
                <li>
                  <a
                    href="#cancel"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onFormCancel();
                    }}
                    className="link link-light"
                  >
                    Cancel
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Messages
