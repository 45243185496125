// import React from 'react'
import { useState } from "react";
// import ProgressiveImage from "react-progressive-image";
import { useSelector, useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
// import { SwipeableDrawer } from "@material-ui/core";
import { HiOutlineMinus } from "react-icons/hi";
import { RiCloseCircleLine } from "react-icons/ri";
// import { addCustomizationCart, clearCart } from '../../redux/store/cartReducer';
// import background from '../../assets/images/f6f5f1.jpg';
import { IoCloseOutline } from "react-icons/io5";
// import { IMAGE_BASE_URL } from "../../config";
import { BiPlus } from "react-icons/bi";
import { Modal, ModalBody } from "reactstrap";
import { ADDCUSTOMIZATION_CART } from "../../../../redux/admin/cart/actionType";
import { addCustomizationCart, addProductToCart, removeProductFromCart } from "../../../../redux/admin/cart/action";

const Customization = ({ product, addProducts, topItems, cartPage, listItems, singlePage }) => {
  const dispatch = useDispatch();

  {
  }
  const [customizeOpen, setCustomizeOpen] = useState(false);
  const cartProducts = useSelector((state) => state.cart);
  console.log("cartProducts143", cartProducts);
  const [quantity, setQuantity] = useState(1);

  const _processAddons = (item) => {
    console.log("item143", item);
    let addons = [];
    addons["selectedaddons"] = [];
    const index = cartProducts?.orderitems?.findIndex((p) => p.id === item.id);
    console.log("index143", index);
    let radio = document.querySelectorAll("input[type=radio]:checked");
    console.log("radio143", radio);
    let checkboxes = document.querySelectorAll("input[type=checkbox]:checked");
    if (index > -1) {
      console.log("inside index");
      cartProducts.orderitems.forEach((cp) => {
        if (cp.id === item.id && cp.selectedaddons?.length > 0) {
          for (let i = 0; i < radio.length; i++) {
            cp.selectedaddons.map((cd) => {
              if (cd.addon_id == radio[i].getAttribute("data-addon-id")) {
                addons["selectedaddons"].push({
                  addon_category_name: radio[i].name,
                  addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
                  category_addon_id: parseInt(radio[i].getAttribute("data-addon-category-id")),
                  addon_name: radio[i].getAttribute("data-addon-name"),
                  quantity: quantity,
                  type: "SINGLE",
                  price: radio[i].value,
                  itemPrice: parseFloat(item.sell_price),
                  singlePrice: parseFloat(item.sell_price) + parseFloat(radio[i].value),
                  totalPrice: parseFloat(item.sell_price) * quantity + parseFloat(radio[i].value) * quantity,
                });
              } else {
                addons["selectedaddons"].push({
                  addon_category_name: radio[i].name,
                  addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
                  category_addon_id: parseInt(radio[i].getAttribute("data-addon-category-id")),
                  addon_name: radio[i].getAttribute("data-addon-name"),
                  quantity: quantity,
                  type: "SINGLE",
                  price: radio[i].value,
                  itemPrice: parseFloat(item.sell_price),
                  singlePrice: parseFloat(item.sell_price) + parseFloat(radio[i].value),
                  totalPrice: parseFloat(item.sell_price) * quantity + parseFloat(radio[i].value) * quantity,
                });
              }
              return cd;
            });
          }

          for (let i = 0; i < checkboxes.length; i++) {
            cp.selectedaddons.map((cd) => {
              if (cd.addon_id == checkboxes[i].getAttribute("data-addon-id")) {
                addons["selectedaddons"].push({
                  addon_category_name: checkboxes[i].name,
                  addon_id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
                  category_addon_id: parseInt(checkboxes[i].getAttribute("data-addon-category-id")),
                  addon_name: checkboxes[i].getAttribute("data-addon-name"),
                  quantity: quantity,
                  type: "MULTIPLE",
                  price: checkboxes[i].value,
                  itemPrice: parseFloat(item.sell_price),
                  singlePrice: parseFloat(item.sell_price) + parseFloat(checkboxes[i].value),
                  totalPrice: parseFloat(item.sell_price) * quantity + parseFloat(checkboxes[i].value) * quantity,
                });
              } else {
                addons["selectedaddons"].push({
                  addon_category_name: checkboxes[i].name,
                  addon_id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
                  category_addon_id: parseInt(checkboxes[i].getAttribute("data-addon-category-id")),
                  addon_name: checkboxes[i].getAttribute("data-addon-name"),
                  quantity: quantity,
                  type: "MULTIPLE",
                  price: checkboxes[i].value,
                  itemPrice: parseFloat(item.sell_price),
                  singlePrice: parseFloat(item.sell_price) + parseFloat(checkboxes[i].value),
                  totalPrice: parseFloat(item.sell_price) * quantity + parseFloat(checkboxes[i].value) * quantity,
                });
              }
              return cd;
            });
          }
        } else {
          for (let i = 0; i < radio.length; i++) {
            addons["selectedaddons"].push({
              addon_category_name: radio[i].name,
              addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
              category_addon_id: parseInt(radio[i].getAttribute("data-addon-category-id")),
              addon_name: radio[i].getAttribute("data-addon-name"),
              quantity: quantity,
              type: "SINGLE",
              price: radio[i].value,
              itemPrice: parseFloat(item.sell_price),
              singlePrice: parseFloat(item.sell_price) + parseFloat(radio[i].value),
              totalPrice: parseFloat(item.sell_price) * quantity + parseFloat(radio[i].value) * quantity,
            });
            item.stock = radio[i].getAttribute("data-addon-stock");
          }

          for (let i = 0; i < checkboxes.length; i++) {
            addons["selectedaddons"].push({
              addon_category_name: checkboxes[i].name,
              addon_id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
              category_addon_id: parseInt(checkboxes[i].getAttribute("data-addon-category-id")),
              addon_name: checkboxes[i].getAttribute("data-addon-name"),
              quantity: quantity,
              type: "MULTIPLE",
              price: checkboxes[i].value,
              itemPrice: parseFloat(item.sell_price),
              singlePrice: parseFloat(item.sell_price) + parseFloat(checkboxes[i].value),
              totalPrice: parseFloat(item.sell_price) * quantity + parseFloat(checkboxes[i].value) * quantity,
            });
            item.stock = checkboxes[i].getAttribute("data-addon-stock");
          }
        }
      });
    } else {
      console.log("indexInElse");
      for (let i = 0; i < radio.length; i++) {
        console.log("inside for1");
        addons["selectedaddons"].push({
          addon_category_name: radio[i].name,
          addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
          category_addon_id: parseInt(radio[i].getAttribute("data-addon-category-id")),
          addon_name: radio[i].getAttribute("data-addon-name"),
          quantity: quantity,
          type: "SINGLE",
          price: radio[i].value,
          singlePrice: parseFloat(item.sell_price) + parseFloat(radio[i].value),
          itemPrice: parseFloat(item.sell_price),
          totalPrice: parseFloat(item.sell_price) * quantity + parseFloat(radio[i].value) * quantity,
        });
        item.stock = radio[i].getAttribute("data-addon-stock");
        console.log("addons.selectedaddons1",addons.selectedaddons);

      }

      for (let i = 0; i < checkboxes.length; i++) {
        addons["selectedaddons"].push({
          addon_category_name: checkboxes[i].name,
          addon_id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
          category_addon_id: parseInt(checkboxes[i].getAttribute("data-addon-category-id")),
          addon_name: checkboxes[i].getAttribute("data-addon-name"),
          quantity: quantity,
          type: "MULTIPLE",
          price: checkboxes[i].value,
          singlePrice: parseFloat(item.sell_price) + parseFloat(checkboxes[i].value),
          itemPrice: parseFloat(item.sell_price),
          totalPrice: parseFloat(item.sell_price) * quantity + parseFloat(checkboxes[i].value) * quantity,
        });
        item.stock = checkboxes[i].getAttribute("data-addon-stock");
      }
    }
    // let newData = Object.assign({}, item, addons);
    let newData = { ...item, addons };
    console.log("newData143", newData);

    if (addons && addons?.selectedaddons && addons?.selectedaddons?.length > 0) {
      console.log("Yup!!");
      dispatch(addCustomizationCart(newData));
    }
    setQuantity(1);
  };


  const addProductQuantity = (product) => {
    // Dispatch the action with the product data
    dispatch(addProductToCart(product));
  };

  const removeProductQuantity = (product) => {
    // Dispatch the action with the product id
    dispatch(removeProductFromCart(product));
  };

  const onMinus = (product) => {
    // dispatch(removeProductFromCart(product));
    if (quantity === 1) {
      setCustomizeOpen(false);
    } else {
      let qty;
      qty = quantity - 1;
      setQuantity(qty);
    }
  };

  const onPlus = () => {
    // dispatch(addProductToCart(product));
    let qty;
    qty = quantity + 1;
    setQuantity(qty);
  };

  return (
    <div>
      <>
        <button    style={{ backgroundColor:'rgb(17, 138, 69)' ,color:"white"}}   class="btn  btn btn-md "
 onClick={() => setCustomizeOpen(true)}>
          Add to Cart 
        </button>
      </>

      <Modal
        isOpen={customizeOpen}
        onClose={() => setCustomizeOpen(false)}
        // onOpen={() => setCustomizeOpen(true)}
        // toggle={() => setModal({ delete: false })}
        className="modal-dialog-centered"
      >
        <ModalBody className="pt-3 pb-2">
          <div className="flex items-center justify-center w-100">
            <HiOutlineMinus size={35} color={"#00000024"} />
          </div>
          <div className="" style={{ paddingBottom: "7rem" }}>
            <div className="flex justify-between  px-4 pb-2 ">
              <div className="flex flex-col text-xl grow px-2" style={{ fontWeight: "900" }}>
                <div className="flex text-xl grow px-2" style={{ fontWeight: "900" }}>
                  {product.name}
                </div>
                <div className="flex items-center px-2" style={{ fontWeight: "600" }}>
                  ₹{product.sell_price}
                </div>
              </div>
              <div
                onClick={() => {
                  setCustomizeOpen(false);
                }}
              >
                <div className="p-1" style={{ borderRadius: "25px", border: "1px solid #ff8686" }}>
                  <IoCloseOutline color="red" size={20} />
                </div>
              </div>
            </div>
            {
              <>
                <div className="p-3">
                  {product &&
                    product?.addoncategories &&
                    product?.addoncategories?.map((addon_category) => (
                      <div key={addon_category.id} className="addon-category-block">
                        <div className="mt-2 w-100 p-1 text-dark" style={{ fontWeight: "600", fontSize: "18px" }}>
                          {addon_category.name}{" "}
                          {addon_category &&
                            addon_category?.addons &&
                            "(" + addon_category?.addons?.length + " Addons)"}
                        </div>
                        {addon_category && addon_category?.addons && addon_category?.addons?.length > 0 && (
                          <div className="mt-2" key={addon_category.id}>
                            {addon_category &&
                              addon_category?.addons &&
                              addon_category?.addons?.map((addon, index) => (
                                <div key={addon.id}>
                                  <label htmlFor={addon.name} className="flex justify-between py-2">
                                    <div className="flex items-center whitespace-nowrap py-1">
                                      <input
                                        type={addon_category.type === "SINGLE" ? "radio" : "checkbox"}
                                        className="check-cust"
                                        name={addon_category.name}
                                        data-addon-id={addon.id}
                                        data-addon-category-id={addon_category.id}
                                        data-addon-stock={addon.stock}
                                        data-addon-name={addon.name}
                                        id={addon.name}
                                        value={addon.price}
                                        defaultChecked={
                                          // addon_category.type === "SINGLE" &&
                                          index === 0 && true
                                        }
                                      />
                                        {addon_category.type === "SINGLE" && (
                                          <label className="ms-2 fw-550 text-slate-400" htmlFor={addon.name} />
                                        )}

                                      <label
                                        className="ms-2 fw-550 text-slate-400 addon-label mx-2"
                                        htmlFor={addon.name}
                                      >
                                        {addon.name}
                                      </label>
                                      <label
                                        className="ms-2 fw-550 text-slate-400 addon-price ms-2 d-flex 
                                                                justify-content-end"
                                        htmlFor={addon.name}
                                      >
                                        ₹ {addon.price}
                                      </label>
                                    </div>
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </>
            }
            <div className="fixed bottom-0 w-100 py-4 px-3 flex bg-white">
              <div className="flex justify-between items-center me-3">
                <div
                  className="flex justify-center items-center rounded-lg text-white p-2.5"
                  style={{ backgroundColor: "#118A45", fontSize: "12px" }}
                  onClick={() => {
                    onMinus();
                  }}
                >
                  <FaMinus />
                </div>
                <span className="px-3">{quantity}</span>
                <div
                  className="flex justify-center items-center rounded-lg text-white p-2.5"
                  style={{ backgroundColor: "#118A45", fontSize: "12px" }}
                  onClick={() => {
                    onPlus();
                  }}
                >
                  <FaPlus />
                </div>
              </div>
              <div
                className="flex justify-center items-center rounded-xl text-white p-2.5 w-100 font-semibold"
                style={{ backgroundColor: "#118A45" }}
                onClick={() => {
                  let cp = { ...product, quantity: quantity };
                  _processAddons(cp);
                  setCustomizeOpen(false);
                }}
              >
                Add to cart
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Customization;
