import { CREATE_USERS_URL, DELETE_USERS_URL, GET_ALL_CUSTOMERS_URL, GET_ALL_DELIVERY_PARTNERS_URL, GET_ALL_STORE_OWNER_URL, GET_ALL_USERS_URL, GET_ALL_USER_ORDERS_URL, GET_ALL_USER_REFERAL_URL, GET_ALL_USER_TRANSACTION_URL, GET_SINGLE_USER_FOR_CREATE_ORDER_URL, GET_SINGLE_USER_URL, UPDATE_DELIVERY_PARTNER_STORE_URL, UPDATE_DELIVERY_PARTNER_URL, UPDATE_USERS_URL, UPDATE_USER_BANK_URL, UPDATE_USER_ROLE_URL, UPDATE_USER_WALLET_URL, UPDATE_VENDOR_STORE_URL, USER_REGISTER_URL } from "../../../config";
import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import { GET_ALL_CUSTOMERS, GET_ALL_DELIVERY_PARTNERS, GET_ALL_STORE_OWNER, GET_ALL_USERS, GET_ALL_USER_ORDERS, GET_ALL_USER_REFERAL, GET_ALL_USER_TRANSACTION, GET_SINGLE_USER, GET_SINGLE_USER_FOR_CREATE_ORDER, UPDATE_DELIVERY_PARTNER, UPDATE_USER_WALLET, USER_BANK_UPDATED, USER_REGISTER, USER_ROLE_UPDATED, USER_UPDATED } from "./actionType";

//all users
export const getAllUsers = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_USERS_URL)
        .then((response) => {
            if (response && response.data.success) {
                const users = response.data.data;
                return dispatch({ type: GET_ALL_USERS, payload: users });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const createUser = (data) => (dispatch) => {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    }
    return axiosClient
        .post(CREATE_USERS_URL, data, config)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};

// get single user
export const getSingleUser = (id) => (dispatch) => {
    return axiosClient
        .post(GET_SINGLE_USER_URL, { id })
        .then((response) => {
            if (response && response.data.success) {
                const users = response.data;
                return dispatch({ type: GET_SINGLE_USER, payload: users });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getUserForCreateOrder = (data) => (dispatch) => {

    return axiosClient
        .post(GET_SINGLE_USER_FOR_CREATE_ORDER_URL,  data )
        .then((response) => {
            if (response && response.data.success) {
                const users = response.data;
                return dispatch({ type: GET_SINGLE_USER_FOR_CREATE_ORDER, payload: users });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const userRegister = (data) => (dispatch) => {

    return axiosClient
        .post(USER_REGISTER_URL,  data )
        .then((response) => {
            if (response && response.data.success) {
                const users = response.data;
                return dispatch({ type: USER_REGISTER, payload: users });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};


export const updateUser = (data) => (dispatch) => {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    }
    return axiosClient
        .post(UPDATE_USERS_URL,
            data,
            config
        )
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
            if (response && response.data.success) {
                const data = response.data;
                return dispatch({ type: USER_UPDATED, payload: data });
            }
        })
};

export const updateBankDetails = (data) => (dispatch) => {
    return axiosClient
        .post(UPDATE_USER_BANK_URL, data,)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
            if (response && response.data.success) {
                const data = response.data;
                return dispatch({ type: USER_BANK_UPDATED, payload: data });
            }
        });
};

export const updateUserRole = (data) => (dispatch) => {
    return axiosClient
        .post(UPDATE_USER_ROLE_URL, data,)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
            if (response && response.data.success) {
                const data = response.data;
                return dispatch({ type: USER_ROLE_UPDATED, payload: data });
            }
        });
};

// get all user orders
export const getAllUserOrder = (id) => (dispatch) => {
    return axiosClient
        .post(GET_ALL_USER_ORDERS_URL, { id })
        .then((response) => {
            if (response && response.data.success) {
                const orders = response.data.orders;
                return dispatch({ type: GET_ALL_USER_ORDERS, payload: orders });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};


//get all user refferals
export const getAllUserReferal = (id) => (dispatch) => {
    return axiosClient
        .post(GET_ALL_USER_REFERAL_URL, { id })
        .then((response) => {
            if (response && response.data.success) {
                const refers = response.data.data;
                return dispatch({ type: GET_ALL_USER_REFERAL, payload: refers });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

// all user wallet transactions
export const getAllUserTransaction = (id) => (dispatch) => {
    return axiosClient
        .post(GET_ALL_USER_TRANSACTION_URL, {id})
        .then((response) => {
            if (response && response.data.success) {

                const transactions = response.data.transactions;
                return dispatch({ type: GET_ALL_USER_TRANSACTION, payload: transactions });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

// add money to wallet
export const updateWalletAmount = (data) => (dispatch) => {
    return axiosClient
        .post(UPDATE_USER_WALLET_URL, data,)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
            if (response && response.data.success) {
                const data = response.data;
                return dispatch({ type: UPDATE_USER_WALLET, payload: data });
            }
        });
};

// delete user
export const deleteUser = (id) => (dispatch) => {
    return axiosClient.post(DELETE_USERS_URL, {
        id,
    }).then((response) => {
        dispatch({
            type: CLOSE_FORM_LOADING,
        });
        dispatch({
            type: FORM_SUBMISSION_STATUS,
            payload: true,
        });
    });
};


// view all store owners
export const getAllStoreOwner = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_STORE_OWNER_URL)
        .then((response) => {
            if (response && response.data.success) {
                const users = response.data.data;
                return dispatch({ type: GET_ALL_STORE_OWNER, payload: users });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

// view all customers
export const getAllCustomers = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_CUSTOMERS_URL)
        .then((response) => {
            if (response && response.data.success) {
                const users = response.data.data;
                return dispatch({ type: GET_ALL_CUSTOMERS, payload: users });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};


// assing store to store owner
export const manageStoreOwner = (id, store_id) => (dispatch) => {
    return axiosClient.post(UPDATE_VENDOR_STORE_URL, {
        id, store_id
    }).then((response) => {
        dispatch({
            type: CLOSE_FORM_LOADING,
        });
        dispatch({
            type: FORM_SUBMISSION_STATUS,
            payload: true,
        });
    });
};


// view all delivery partners
export const getAllDeliveryPartner = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_DELIVERY_PARTNERS_URL)
        .then((response) => {
            if (response && response.data.success) {
                const users = response.data.data;
                return dispatch({ type: GET_ALL_DELIVERY_PARTNERS, payload: users });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};


// assing store to delivery partner
export const manageDeliveryPartner = (id, warehouse_ids) => (dispatch) => {
    return axiosClient.post(UPDATE_DELIVERY_PARTNER_STORE_URL, {
        id, warehouse_ids
    }).then((response) => {
        dispatch({
            type: CLOSE_FORM_LOADING,
        });
        dispatch({
            type: FORM_SUBMISSION_STATUS,
            payload: true,
        });
    });
};

//  update delivery guy details
export const updateDeliveryGuyDetails = (data) => (dispatch) => {

    return axiosClient
        .post(UPDATE_DELIVERY_PARTNER_URL, data,)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
            if (response && response.data.success) {
                const data = response.data;
                return dispatch({ type: UPDATE_DELIVERY_PARTNER, payload: data });
            }
        });
};
