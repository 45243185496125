import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, RSelect } from '../../../../../components/Component';
import Head from '../../../../../layout/head/Head';
import { getAllUserTransaction, getSingleUser, updateWalletAmount } from '../../../../../redux/admin/user/action';
import moment from 'moment';
import { Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Modal, ModalBody, Row } from 'reactstrap';
import { number } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { openFormLoading } from '../../../../../redux/common/actions';
import * as yup from "yup";
import { string } from "yup";
import noDocument from '../../../../../assets/no-document.svg'


function Wallet({user_id}) {

    const dispatch = useDispatch();


    // get data from redux state
    const getSelectors = (state) => ({
        user: state.users.single_user.user,
        transactions: state.users.transactions,

    });

    const { transactions, user } = useSelector(getSelectors);
    const [modal, setModal] = useState(false);
    const [editWallet, setEditWallet] = useState({});
    const [showToast, setShowToast] = useState(false);
    const accessToken=localStorage.getItem('accessToken')


    // function to close the form modal
    const onFormCancel = () => {
        setModal(false);


    };
    // get all user transactions
    console.log(user_id,"user_id")
    useEffect(() => {
       
        dispatch(getAllUserTransaction(user_id))

    }, [])

    // form validation schema
    const walletSchema = yup.object().shape({
        amount: number().required(),
        type: string().required(),

    });

    // schema to edit form data
    const defaultValues = useMemo(
        () => ({
            id: "",
            amount: "",
            type: "",
            message: "",


        }),
        [editWallet]
    );

    const methods = useForm({
        resolver: yupResolver(walletSchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    const onFormSubmit = (submitData) => {

        dispatch(openFormLoading());
        console.log(submitData);
        const formData = new FormData();
        formData.append('id', user?.id);
        formData.append('type', submitData?.type);

        formData.append('amount', submitData?.amount);
        formData.append('message', submitData?.message);

        dispatch(updateWalletAmount(formData)).then((response) => {
            if (response.payload.success) {
                setTimeout(() => {
                    setShowToast(true);
                    setModal(false);
                    dispatch(getAllUserTransaction(user_id))
                    dispatch(getSingleUser(user?.id))
                }, 500)
                setTimeout(() => {
                    setShowToast(false);
                }, 3000)
            }
        })
    }

    const type = [
        { value: "DEPOSIT", label: "DEPOSIT" },
        { value: "WITHDRAW", label: "WITHDRAW" },
    ];
    return (
        <React.Fragment>
            <Head title="Wallet"></Head>
            <BlockHead size="lg">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Wallet</BlockTitle>
                        <BlockDes>
                            <p>All Wallet Transaction Placed Through Sylcon.</p>
                        </BlockDes>
                    </BlockHeadContent>

                </BlockBetween>
            </BlockHead>
            <Block>
                <Row>
                    <Col lg="6">
                        <Card className="card-bordered">
                            <CardHeader className="border-bottom">
                                Wallet Balance
                            </CardHeader>
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">{user?.wallet_amount ? user?.wallet_amount : 0.00} INR</CardTitle>
                                <Button color="primary" onClick={() => setModal(true)}>Adjust Balance</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Block>
            <Block>
                <div className="nk-data data-list">
                    <div className="data-head">
                        <h6 className="overline-title">wallet</h6>
                    </div>
                    <table className={`table table-tranx is-compact`}>
                        <thead>
                            <tr className="tb-tnx-head">
                                <th className="tb-tnx-id">
                                    <span className="">#</span>
                                </th>
                                <th className="tb-tnx-id">
                                    Transaction Type
                                </th>
                                <th className="tb-tnx-id">
                                    Transaction Amount
                                </th>
                                <th className="tb-tnx-id">
                                    Message
                                </th>
                                <th className="tb-tnx-amount is-alt">
                                    <span className="tb-tnx-total">Created At</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {transactions && transactions.length > 0 ? transactions.map((transaction) => {
                                return (
                                    <tr key={transaction.id} className="tb-tnx-item">
                                        <td className="tb-tnx-id">
                                            <span>{transaction?.uuid}</span>
                                        </td>
                                        <td className="tb-tnx-id">
                                            {transaction?.type &&
                                                <Badge pill color={transaction?.type == "deposit" ? "success" : "danger"}>{transaction?.type}</Badge>
                                            }
                                        </td>
                                        <td className="tb-tnx-id">
                                            <span className="title">{transaction?.amount ? transaction?.amount : 0.00}</span>
                                        </td>
                                        <td className="tb-tnx-id">
                                            <span className="title">{transaction?.meta?.description ? transaction?.meta?.description : "--"}</span>
                                        </td>
                                        <td className="tb-tnx-amount is-alt">
                                            <span className="amount">{moment(transaction?.created_at).fromNow()}</span>
                                        </td>
                                    </tr>
                                );
                            }) : (
                                <tr className="tb-tnx-item">
                                    <td align="center" colspan="5">
                                        <div className='w-100 pt-5'>
                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                <div>
                                                    <img src={noDocument} style={{ width: '3rem' }} alt='sylcon' />
                                                </div>
                                                <div>No Data Found</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            </Block>
            {/* add modal */}
            <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Adjust Balance</h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)} >
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Amount</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="amount"
                                            placeholder="Enter the amount"
                                            min="0"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.amount && <span className="invalid">{errors.amount.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Adjustment Type</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="type"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={type}
                                                        onChange={(val) => onChange(val.value)}
                                                        defaultValue={type.find((c) => c.value === value)}
                                                    />
                                                )}
                                                ref={register({ required: "This field is required" })}
                                            />
                                            {errors.type && (
                                                <span className="invalid text-danger text-italic">Please choose Type</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Adjustment Note</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="message"
                                            placeholder="Enter Adjustment Note"
                                            ref={register()}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                Submit
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    onFormCancel();
                                                }}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default Wallet
