import { GET_DASHBOARD_DATA } from "./actionType";

const initialState = {
    recent_orders: [],
    recent_warehouses: [],
    today_users: [],
    total_products: [],
    sales_count: [],
    sales_earning: [],
    users_count: [],
    weekly_orders: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                today_users: action.payload?.todayUsers,
                recent_orders: action.payload?.recentOrders,
                recent_warehouses: action.payload?.recentWarehouses,
                total_products: action.payload?.totalProducts,
                sales_count: action.payload?.salesCount,
                sales_earning: action.payload?.salesEarning,
                users_count: action.payload?.userscount,
                weekly_orders: action.payload?.weeklyOrders,
            };
        default:
            return state;
    }
}
