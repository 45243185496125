import React, { useState } from "react";
import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Button,
    Icon,
    PreviewCard,
} from "../../components/Component";
// import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axiosClient from "../../helpers/axios-client";
import { LOGIN_URL } from "../../config";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { string } from "yup";
import Logo from '../../assets/icon-green.svg'

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [passState, setPassState] = useState(false);
    const [errorVal, setErrorVal] = useState("");


    const schema = yup.object({
        email: string().email().required(),
        password: string().min(8).required(),
    });

    const defaultValues = {
        email: '',
        password: '',
    }
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });

    const { errors, register, handleSubmit, setError } = methods;

    const onFormSubmit = (formData) => {
        setLoading(true);
        setErrorVal();


        axiosClient.post(LOGIN_URL, formData).then((response) => {
            // const response = data.status;
            console.log(response, 'status')
            if (response && response.status == 200) {
                if (response?.data && response?.data.success) {
                    localStorage.setItem("accessToken", response?.data?.token);
                    localStorage.setItem("userName", response?.data?.user_name);
                    localStorage.setItem("image", response?.data?.image);
                    localStorage.setItem("email", response?.data?.email);
                    localStorage.setItem("role", response?.data?.user.roles[0].name);
                    localStorage.setItem("permissions", JSON.stringify(response?.data?.permissions));
                    setTimeout(() => {
                        window.history.pushState(
                            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
                            "auth-login",
                            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
                        );
                        window.location.reload();
                    }, 2000);
                } else {
                    setErrorVal("hh");
                    setLoading(false)
                }
                console.log(response?.data);
            }
        }).catch((err) => {
            const response = err.response;
            if (response?.status == 422) {
                if (response?.data?.errors?.name) {
                    setError("name", {
                        type: "server",
                        message: "Something went wrong with username",
                    });
                }
                if (response?.data?.errors?.email) {
                    setError("email", {
                        type: "server",
                        message: response?.data?.errors?.email[0],
                    });
                }
                if (response?.data?.errors?.password) {
                    console.log(response?.data?.errors);

                    setError("password", {
                        type: "server",
                        message: response?.data?.errors?.password[0],
                    });
                }
                // console.log(errors);
            }
        });
        // if (formData.name === loginName && formData.passcode === pass) {
        //   localStorage.setItem("accessToken", "token");
        //   setTimeout(() => {
        //     window.history.pushState(
        //       `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
        //       "auth-login",
        //       `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
        //     );
        //     window.location.reload();
        //   }, 2000);
        // } else {
        //   setTimeout(() => {
        //     setError("Cannot login with credentials");
        //     setLoading(false);
        //   }, 2000);
        // }
    };


    return (
        <React.Fragment>
            <Head title="Login" />
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body  wide-xs">
                    <div className="brand-logo pb-5 text-center">
                        {/* <h2>Welcome to Sylcon</h2> */}
                        <img src={Logo} alt="sylcon-icon" style={{ height: '100%', width: '90%', objectFit: 'contain' }} />
                    </div>
                    <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                        <BlockHead>
                            <BlockContent>
                                <BlockTitle tag="h4">Login to Dashboard</BlockTitle>
                                <BlockDes>
                                    <p>Enter your registered email and password.</p>
                                </BlockDes>
                            </BlockContent>
                        </BlockHead>
                        {errorVal && (
                            <div className="mb-3">
                                <Alert color="danger" className="alert-icon">
                                    {" "}
                                    <Icon name="alert-circle" /> Unable to login with credentials{" "}
                                </Alert>
                            </div>
                        )}
                        <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                            <FormGroup>
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="default-01">
                                        Registered Email
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        id="default-01"
                                        name="email"
                                        ref={register({ required: "This field is required" })}
                                        defaultValue="info@softnio.com"
                                        placeholder="Enter your email"
                                        className="form-control-lg form-control"
                                    />
                                    {errors.email && <span className="invalid">{errors.email.message}</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="password">
                                        Password
                                    </label>
                                    {/* <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                                        Forgot Code?
                                    </Link> */}
                                </div>
                                <div className="form-control-wrap">
                                    <a href="#password"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setPassState(!passState);
                                        }}
                                        className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}  >
                                        <Icon name="eye" className="passcode-icon icon-show"></Icon>
                                        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                    </a>
                                    <input type={passState ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        // defaultValue="123456"
                                        ref={register({ required: "This field is required" })}
                                        placeholder="Enter your password"
                                        className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                                    />
                                    {errors.password && <span className="invalid">{errors.password.message}</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Button size="lg" className="btn-block" type="submit" color="primary">
                                    {loading ? (
                                        <>
                                            <Spinner size="sm" color="light" /> <span>Logging In...</span>
                                        </>
                                    ) : (
                                        "Sign in"
                                    )}
                                </Button>
                            </FormGroup>
                            {/* <div className="form-note-s2 text-center pt-4">
                                {" "}
                                Create new account?{" "}
                                <Link to={`${process.env.PUBLIC_URL}/sign-up`}>
                                    <strong>Sign Up</strong>
                                </Link>
                            </div> */}
                        </Form>
                    </PreviewCard>
                </Block>
                <AuthFooter />
            </PageContainer>
        </React.Fragment>
    );
};
export default Login;
