export const GET_ALL_WAREHOUSE = 'GET_ALL_WAREHOUSE';
export const GET_WAREHOUSE_ITEMS = 'GET_WAREHOUSE_ITEMS';
export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE';
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE';
export const TOGGLE_WAREHOUSE = "TOGGLE_WAREHOUSE";
export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE";
export const ADD_TO_DISPLAY_ITEMS = "ADD_TO_DISPLAY_ITEMS";
export const CLEAR_CART_IN_DASHBOARD_CREATE_ORDER = "CLEAR_CART_IN_DASHBOARD_CREATE_ORDER";





