import React, { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiMinus, BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
// import { addAddonsCart, addToCart, addVariantCart, clearCart, removeAddonsCart, removeFromCart, removeVariantCart } from '../../../redux/store/cartReducer'
// import { IMAGE_BASE_URL } from '../../../config'
// import ProgressiveImage from 'react-progressive-image'
// import background from '../../../assets/images/f6f5f1.jpg';
import TextTruncate from "react-text-truncate";
import { BsExclamationCircle } from "react-icons/bs";
import {
  addAddonsCart,
  addToCart,
  addVariantCart,
  clearCart,
  removeAddonsCart,
  removeFromCart,
  removeVariantCart,
  updateCart,
} from "../../../../../redux/admin/cart/action";
import Content from "../../../../../layout/content/Content";
import { Block } from "../../../../../components/Component";
import { Button, Card, Col, Row } from "reactstrap";

function CartItems({ noItemCard, inActiveAddons, inActiveAddonCategories, inActiveItemVariants, inActiveItems }) {
  const dispatch = useDispatch();
  const cartProducts = useSelector((state) => state.cart);
  const [updateState, setUpdatestate] = useState(false);

  console.log("cartProductsCartITEMSSSS", cartProducts);

  const orderItems = [];
  // cartProducts.push(cartProduct?.orderitems);
  orderItems.push(cartProducts?.orderitems);

  console.log("orderItemsInCartItems", orderItems);

  const _processVariants = (variant, product) => {
    const updatedVariants = [];
    const productIndex = cartProducts?.orderitems?.findIndex((p) => p?.id === product?.id);
    if (productIndex > -1) {
      cartProducts?.orderitems?.forEach((cartProduct) => {
        if (cartProduct?.selectedvariants?.filter((v) => v?.variant_id === variant?.id)) {
          updatedVariants.push({
            variant_id: variant.variant_id,
            variant_name: variant.variant_name,
            quantity: 1,
            sell_price: variant.sell_price,
            totalPrice: parseFloat(variant.sell_price),
          });
        } else {
          updatedVariants.push({
            variant_id: variant.variant_id,
            variant_name: variant.variant_name,
            quantity: 1,
            sell_price: variant.sell_price,
            totalPrice: parseFloat(variant.sell_price),
          });
        }
      });
    } else {
      updatedVariants.push({
        variant_id: variant.variant_id,
        variant_name: variant.variant_name,
        quantity: 1,
        sell_price: variant.sell_price,
        totalPrice: parseFloat(variant.sell_price),
      });
    }
    const updatedProductData = { ...product, selectedvariants: updatedVariants };
    dispatch(addVariantCart(updatedProductData));
  };

  const _processRemoveVariants = (variant, product) => {
    const removeVariants = [];
    removeVariants.push({
      variant_id: variant.variant_id,
      variant_name: variant.variant_name,
      quantity: variant.quantity,
      sell_price: variant.sell_price,
      totalPrice: parseFloat(variant.sell_price) * variant.quantity,
    });
    const updatedProductData = { ...product, selectedvariants: removeVariants };
    console.log(updatedProductData, "updatedProductData");
    dispatch(removeVariantCart(updatedProductData));
  };

  const totalPrice = (product) => {
    let total = 0;
    const cartItem = cartProducts?.orderitems?.find((p) => p.id === product?.id);

    if (cartItem) {
      const totalPriceArray = cartItem?.selectedvariants?.map((vart) => vart?.totalPrice);
      total = totalPriceArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    return parseFloat(total);
  };

  // ************************start **************

  const forceStateUpdate = () => {
    setTimeout(() => {
      // this.forceUpdate();
      if (updateState) {
        setUpdatestate(false);
      } else {
        setUpdatestate(true);
      }
    }, 100);
  };

  const addProductQuantity = (product) => {
    console.log("insideaddproducrquaantity", product);
    // const { cartProducts, updateCart } = this.props;
    let productAlreadyInCart = false;
    // this.props.calculateStoreChargeTax(cartProducts);

    cartProducts?.orderitems?.forEach((cp) => {
      if (cp.id === product.id) {
        if (cp.quantity < product.stock) {
          cp.quantity += 1;
        }
        productAlreadyInCart = true;
      }
    });

    if (!productAlreadyInCart) {
      cartProducts?.orderitems?.push(product);
    }

    updateCart(cartProducts?.orderitems);
    forceStateUpdate();
  };

  const removeProductQuantity = (product) => {
    // const { cartProducts, updateCart } = this.props;
    // this.props.calculateStoreChargeTax(cartProducts);
    console.log("inside remove p q - inactive");

    const index = cartProducts?.orderitems.findIndex(
      (p) => p.id === product.id && JSON.stringify(p) === JSON.stringify(product)
    );
    if (index >= 0) {
      cartProducts?.orderitems.forEach((cp) => {
        if (cp.id === product.id) {
          if (JSON.stringify(cp) === JSON.stringify(product)) {
            if (cp.quantity === 1) {
              //if quantity is 1 then remove product from cart
              cartProducts?.orderitems.splice(index, 1);
            } else {
              //else decrement the quantity by 1
              cp.quantity -= 1;
            }
          }
        }
      });

      updateCart(cartProducts?.orderitems);
      forceStateUpdate();
    }
  };

  // ************************ end **************

  return (
    <div style={{}} id="_noItem" className="">
      {/* <div className="mt-2.5 pt-4 px-4 pb-2 flex justify-between items-center " style={{ marginTop: "10vh" }}>
        <div
          className="flex items-center text-xs border rounded-lg px-2 py-1 text-red-500"
          onClick={() => {
            dispatch(clearCart());
          }}
        >
          <RiDeleteBinLine className="me-1" color="red" /> Clear Cart
        </div>
      </div> */}
      {/* <div style={{ paddingLeft: "40px", paddingTop: "35px" }}>
        <button
          className="bg-white cursor-pointer"
          style={{ border: "1px solid", display: "-webkit-inline-box", padding: "6px 8px", borderRadius: "5px" }}
        >
          <div
            className=" items-center "
            style={{ display: "flex", alignItems: "center", gap: "8px" }}
            onClick={() => {
              dispatch(clearCart());
            }}
          >
            <RiDeleteBinLine className="" color="red" />
            Clear Cart
          </div>
        </button>
      </div> */}
      <div style={{marginTop:"7vh",marginLeft:'35px'}}>
       
          <div
            class="btn btn-danger btn btn-md btn-danger"
            onClick={() => {
              dispatch(clearCart());
            }}
          >
            <RiDeleteBinLine className="mr-2" color="WHITE" />
            Clear Items
          </div>
      </div>

      <React.Fragment>
        <Content>
          {cartProducts?.orderitems?.map((item, index) =>
            item?.selectedvariants?.length > 0 ? (
              <Block key={item.id}>
                <Row className="g-gs">
                  <Col xxl="12">
                    <Row className="g-gs">
                      <Col xxl="12" md="12" className="my-4">
                        <Card >
                          <div className="nk-ecwg nk-ecwg3 pb-3">
                            <div className="card-inner pb-0">
                              <div className="flex flex-col">
                                <div className="form-label flex justify-between" >
                                  <div className="" style={{ fontSize: "17px" }}>{item.name}</div>
                                  <div className="" style={{ fontSize: "17px" }}>₹{parseFloat(item.sell_price)} </div>
                                </div>
                              </div>

                              <TextTruncate
                                className="text-sm font-semibold text-slate-500 mb-2 mt-2"
                                style={{ fontSize: "16px" }}
                                Lines={1}
                                text={item.description}
                              />

                              {item?.selectedvariants?.map((variant, index) => (
                                <>
                                  {/* {index <= 0 && inActiveItems?.find(cp => cp == item.id) ?
                                          <div className='grow flex items-center' style={{ fontSize: '12px', color: 'red' }}>
                                              <BsExclamationCircle className='me-1' color='red' />  Sorry, Currently not available
                                          </div> : index <= 0 && inActiveItemVariants?.find(cp => cp == variant.variant_id) ?
                                              <div className='grow flex items-center' style={{ fontSize: '12px', color: 'red' }}>
                                                  <BsExclamationCircle className='me-1' color='red' />  Sorry, Currently not available
                                              </div>
                                              : null} */}

                                  <div
                                    className=""
                                    
                                    key={variant.variant_id}
                                    //   style={
                                    //     inActiveItems?.find((cp) => cp == item.id) ||
                                    //     inActiveItemVariants?.find((cp) => cp == variant.variant_id)
                                    //       ? { color: "red" }
                                    //       : {}
                                    //   }
                                  >
                                    {/* <div className=" form-label" style={{ fontSize: "15px" }}>{item.name}</div> */}
                                    {/* <div className="" style={{  fontWeight:"500", marginBottom:"0.5rem" }}>{item.name}</div> */}
                                  <div className="flex justify-between">
                                  <div style={{fontSize:"14px",  fontWeight:"500", marginBottom:"0.5rem" }}>{variant.variant_name}</div>
                                    <div style={{ fontSize:"17px",  fontWeight:"500", marginBottom:"0.5rem" }}>₹{parseFloat(variant.sell_price)}</div>
                                  </div>
                                  
                                    
                                    {inActiveItems?.find((cp) => cp == item.id) ||
                                    inActiveItemVariants?.find((cp) => cp == variant.variant_id) ? (
                                      <div className="flex justify-end" key={variant.id}>
                                        <div
                                        // onClick={() => {
                                        //   let cp = { ...variant, quantity: 1 };
                                        //   _processRemoveVariants(cp, item);
                                        // }}
                                        >
                                          {/* <RiDeleteBinLine className="w-[22px] h-[22px]" color="red" /> */}
                                        </div>
                                        {/* <div className="px-3">Quantity:{variant.quantity}</div> */}
                                        {/* <div
                                          className="flex justify-center items-center rounded-md text-white px-1 py-1"
                                          style={{ backgroundColor: "#c7c7c7", fontSize: "12px" }}
                                          onClick={() => {
                                            noItemCard();
                                          }}
                                        >
                                          <BiPlus size={18} />
                                        </div> */}
                                      </div>
                                    ) : (
                                      <div className="flex justify-end" key={variant.id}>
                                        <div
                                          className={
                                            variant.quantity > 1
                                              ? "flex justify-center items-center rounded-md text-white px-1 py-1 "
                                              : ""
                                          }
                                          // style={
                                          //   variant.quantity > 1 ? { backgroundColor: "#118A45", padding: "5px" } 
                                          //   : {}
                                          // }
                                          onClick={() => {
                                            let cp = { ...variant, quantity: 1 };
                                            _processRemoveVariants(cp, item);
                                          }}
                                        >
                                          {/* {variant.quantity > 1 ? (
                                            <BiMinus className="w-[18px] h-[18px]" />
                                          ) : (
                                              <RiDeleteBinLine className="w-[22px] h-[22px]" color="#808080" />
                                          )} */}
                                        </div>
                                        {/* <div className="">Quantity:{variant.quantity}</div> */}
                                        <div
                                          className="flex justify-center items-center rounded-md text-white px-1 py-1"
                                          // style={{ backgroundColor: "#118A45", fontSize: "12px" }}
                                          // onClick={() => {
                                          //   let cp = { ...variant, quantity: 1 };
                                          //   _processVariants(cp, item);
                                          // }}
                                        >
                                          {/* <BiPlus size={18} /> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              ))}

                              <div className="">
                                <div className="font-semibold mt-2">
                                  <span className="font-semibold text-xs" style={{fontSize:"14px",  fontWeight:"500", marginBottom:"0.5rem" }}>
                                    ₹{parseFloat(item.sell_price)} + ₹{totalPrice(item)}
                                  </span>
                                  <div className="font-normal form-label mt-2" style={{ fontSize: "17px" }}>
                                    Total: &nbsp;
                                    <span className="font-semibold" style={{ color: "#E84141"}}>
                                      ₹{parseFloat(item.sell_price) + totalPrice(item)}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="text-center">
                              <Button
                                color="success"
                                className="btn btn-md btn-success"
                                onClick={(e) => {
                                  _completeOrder(e);
                                }}
                              >
                                Continue
                              </Button>
                            </div> */}
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Block>
            ) : 
           

            item?.selectedaddons?.length > 0 ? (
              item?.selectedaddons?.map((addon, addonIndx) => (
                <Card>
                <div className="nk-ecwg nk-ecwg3 pb-3">
                  <div className="card-inner pb-0">
                <div
                  // className={
                  //   index > 0 ? " py-3 mx-4 border-t-2" : addonIndx > 0 ? " py-3 mx-4 border-t-2" : " py-3 mx-4"
                  // }
                  key={item.id}
                >
                  <div className="">
                    {/* <ProgressiveImage
                                    delay={20}
                                    src={IMAGE_BASE_URL + item?.images[0]?.image}
                                    placeholder={background}
                                >
                                    {(src) => (
                                        <img
                                            src={src}
                                            style={{ width: '22vw', height: '22vw', borderRadius: '14px', objectFit: 'contain' }}
                                            className="" alt="sylcon"
                                        />
                                    )}
                                </ProgressiveImage> */}
                    <div className="grow flex flex-col ps-2">
                                <div className="form-label flex justify-between" >
                                  <div className="" style={{ fontSize: "17px" }}>{addon.addon_category_name}</div>
                                  <div className="" style={{ fontSize: "17px" }}>₹{parseFloat(item.sell_price)}</div>
                                </div>
                              </div>
                      
                      <div className="">
                      <div className="form-label flex justify-between" >
                        <div className="text-sm font-semibold text-slate-500">{addon.addon_name}</div>
                        <div className="" style={{ fontSize: "17px" }}>₹{parseFloat(addon.price)}</div>


                    </div>
                     
                    </div>
                  </div>


                  {/* <div className="">
                                <div className="font-semibold mt-2">
                                  <span className="font-semibold text-xs" style={{fontSize:"14px",  fontWeight:"500", marginBottom:"0.5rem" }}>
                                    ₹{parseFloat(item.sell_price)} + ₹{totalPrice(item)}
                                  </span>
                                  <div className="font-normal form-label mt-2" style={{ fontSize: "17px" }}>
                                    Total: &nbsp;
                                    <span className="font-semibold" style={{ color: "#E84141"}}>
                                      ₹{parseFloat(item.sell_price) + totalPrice(item)}
                                    </span>
                                  </div>
                                </div>  
                              </div> */}
                    <div className="font-semibold mt-4 flex">
                    {/* <div className=" flex justify-end">Quantity:{addon.quantity}</div> */}
                        
                      <span className="font-semibold text-xs" 
                       style={{fontSize:"14px",  fontWeight:"500", marginBottom:"0.5rem" }}>
                        ₹{parseFloat(item.sell_price) * addon.quantity} + ₹{parseFloat(addon.price)
                         * addon.quantity}
                      </span>
                      <div className="font-normal form-label mt-2"
                       style={{ fontSize: "17px" }}>
                      Total: &nbsp;

                        <span className="font-semibold" style={{ color: "#E84141"}}>
                          ₹{parseFloat(item.sell_price) * addon.quantity + parseFloat(addon.price) * addon.quantity}
                        </span>{" "}
                      </div>
                   
                  </div>
                </div>
                </div>
                </div>


                </Card>

              ))
            ) : (
              <Block key={item.id}>
                <Row className="g-gs">
                  <Col xxl="4">
                    <Row className="g-gs">
                      <Col xxl="6" md="5" className="my-4">
                        <Card>
                          <div className="nk-ecwg nk-ecwg3 pb-3">
                            <div className="card-inner pb-0">
                              {/* <div className="card-title-group"> */}
                                <div className="flex justify-between">


                                <div className="form-label " style={{ fontSize: "17px" }}>
                                  {item.name}
                                </div>
                                <div  className="form-label  flex flex-end" style={{ fontSize: "17px" }}> ₹{item.sell_price}</div>
                                {/* </div> */}
                               

                              </div>
                              <div className="data">
                                <div className="">
                                  {/* data-group */}
                                  {/* <TextTruncate
                                    className="text-sm font-semibold text-slate-500"
                                    Lines={2}
                                    text={item.description}
                                  /> */}

                                  <div className="flex justify-between items-center">
                                    <div
                                      className="mt-4"
                                      style={{ fontSize: "17px", color: "#344357", fontWeight: "500" }}
                                    >
                                      Total:{" "}
                                      <span className="font-semibold" style={{color:" rgb(232, 65, 65)"}}>
                                        {" "}
                                        ₹{parseFloat(item.sell_price) * item.quantity}
                                      </span>
                                    </div>
                                    {inActiveItems?.find((cp) => cp == item.id) ? (
                                      <div className="flex gap-3 items-center">
                                        {/* <div
                                            className={""}
                                            onClick={() => {
                                              // let newData = Object.assign({}, item, { quantity: 1, isRemoveAll: true });
                                              // let newData = {item,  quantity: 1, isRemoveAll: true };
                                              // dispatch(removeFromCart(newData));
                                              removeProductQuantity(item);
                                            }}
                                            >
                                            <RiDeleteBinLine className="w-[22px] h-[22px]" color="red" />
                                            </div> */}
                                        {/* <div className="text-sm text-black">Quantity:{item.quantity}</div> */}
                                        {/* <div
                                              className="rounded-lg text-white"
                                              style={{ backgroundColor: "#c7c7c7", padding: "5px" }}
                                              onClick={() => {
                                                noItemCard();
                                              }}
                                              >
                                              <BiPlus className="w-[18px] h-[18px]" />
                                              </div> */}
                                      </div>
                                    ) : (
                                      <div className="flex gap-3 items-center">
                                        <div
                                          className={item.quantity > 1 ? "rounded-lg text-white " : ""}
                                          style={
                                            item.quantity > 1 ? {  padding: "5px" } : {}
                                          }
                                          onClick={() => {
                                            // let newData = Object.assign({}, item, { quantity: 1 });
                                            // let newData = {...item,  quantity: 1, isRemoveAll: true };
                                            // let newData = [{...item, quantity: 1, isRemoveAll: true }];
                                            // dispatch(removeFromCart({newData, orderItems}));
                                            removeProductQuantity(item);
                                          }}
                                        >
                                          {/* {item.quantity > 1 ? (
                                                    <BiMinus className="w-[18px] h-[18px]" />
                                                  ) : (
                                                    <RiDeleteBinLine className="w-[22px] h-[22px]" color="#808080" />
                                                  )} */}
                                        </div>
                                        {/* <div className="text-sm text-black">{item.quantity}</div>
                                              <div
                                                className="rounded-lg text-white"
                                                style={{ backgroundColor: "#118A45", padding: "5px" }}
                                                onClick={() => {
                                                  addProductQuantity(item);
                                              
                                                }}
                                              >
                                                <BiPlus className="w-[18px] h-[18px]" />
                                              </div> */}
                                      </div>
                                    )}
                                  </div>

                                  <div className="info text-right">
                                    {/* <span className="change up text-danger" style={{ fontSize: "20px" }}>
                                        <Icon name="user-list"></Icon>
                                      </span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Block>

              // <div className={index > 0 ? "bg-white py-3 mx-4 border-t-2" : "bg-white py-3 mx-4"} key={item.id}>
              //   <div className="flex justify-between">
              //     <div className="grow ps-2">
              //       <div className="flex justify-between">
              //         <div className="grow flex flex-col">
              //           <div className="card-title">{item.name}</div>
              //           <TextTruncate
              //             className="text-sm font-semibold text-slate-500"
              //             Lines={2}
              //             text={item.description}
              //           />
              //         </div>
              //         <div className="text-xs font-semibold">₹{item.sell_price}</div>
              //       </div>
              //       {/* {inActiveItems?.find((cp) => cp == item.id) ? (
              //     <div className="grow flex items-center" style={{ fontSize: "12px", color: "red" }}>
              //       <BsExclamationCircle className="me-1" color="red" /> Sorry, Currently not available
              //     </div>
              //      ) : null} */}
              //     </div>
              //   </div>

              //   <div className="flex justify-between items-center">
              //     <div className="font-normal mt-2">
              //       Total: <span className="font-semibold"> ₹{parseFloat(item.sell_price) * item.quantity}</span>
              //     </div>
              //     {inActiveItems?.find((cp) => cp == item.id) ? (
              //       <div className="flex gap-3 items-center">
              //         {/* <div
              //       className={""}
              //       onClick={() => {
              //         // let newData = Object.assign({}, item, { quantity: 1, isRemoveAll: true });
              //         // let newData = {item,  quantity: 1, isRemoveAll: true };
              //         // dispatch(removeFromCart(newData));
              //         removeProductQuantity(item);
              //       }}
              //        >
              //       <RiDeleteBinLine className="w-[22px] h-[22px]" color="red" />
              //       </div> */}
              //         <div className="text-sm text-black">Quantity:{item.quantity}</div>
              //         {/* <div
              //       className="rounded-lg text-white"
              //       style={{ backgroundColor: "#c7c7c7", padding: "5px" }}
              //       onClick={() => {
              //         noItemCard();
              //       }}
              //        >
              //       <BiPlus className="w-[18px] h-[18px]" />
              //       </div> */}
              //       </div>
              //     ) : (
              //       <div className="flex gap-3 items-center">
              //         <div
              //           className={item.quantity > 1 ? "rounded-lg text-white " : ""}
              //           style={item.quantity > 1 ? { backgroundColor: "#118A45", padding: "5px" } : {}}
              //           onClick={() => {
              //             // let newData = Object.assign({}, item, { quantity: 1 });
              //             // let newData = {...item,  quantity: 1, isRemoveAll: true };
              //             // let newData = [{...item, quantity: 1, isRemoveAll: true }];
              //             // dispatch(removeFromCart({newData, orderItems}));
              //             removeProductQuantity(item);
              //           }}
              //         >
              //           {/* {item.quantity > 1 ? (
              //         <BiMinus className="w-[18px] h-[18px]" />
              //       ) : (
              //         <RiDeleteBinLine className="w-[22px] h-[22px]" color="#808080" />
              //       )} */}
              //         </div>
              //         {/* <div className="text-sm text-black">{item.quantity}</div>
              //       <div
              //         className="rounded-lg text-white"
              //         style={{ backgroundColor: "#118A45", padding: "5px" }}
              //         onClick={() => {
              //           addProductQuantity(item);

              //         }}
              //       >
              //         <BiPlus className="w-[18px] h-[18px]" />
              //       </div> */}
              //       </div>
              //     )}
              //   </div>

              // </div>
            )
          )}
        </Content>
      </React.Fragment>
    </div>
  );
}

export default CartItems;
