import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Spinner } from 'reactstrap';
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, RSelect } from '../../../../components/Component';
import Content from '../../../../layout/content/Content';
import Head from '../../../../layout/head/Head';

import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { getAllCities, getCityWarehouses } from '../../../../redux/admin/cities/actions';
import * as yup from "yup";
import { string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import subDays from "date-fns/subDays";
import { WEBSITE_API_URL } from '../../../../config';
import moment from "moment";
import { getAllWarehouses } from '../../../../redux/admin/warehouse/action';


const DateWiseOrders = () => {
    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        warehouses: state.cities.city_warehouse,
        cities: state.cities.cities,
        form_loading: state.common.form_loading,
    });

    const { form_loading, cities, warehouses } = useSelector(getSelectors);
    const [startDate, setStartDate] = useState(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState(new Date());
    const [cityId, setCityId] = useState("All");
    const [warehouseId, setWarehouseId] = useState("All");


    // form validation schema
    const exportSchema = yup.object({
        status: string().required(),
        city_id: string().required(),
        warehouse_id: string().required(),
    });

    // schema to edit form data
    const defaultValues = useMemo(() => ({
        status: "All",
        city_id: "All",
        warehouse_id: "All",
    }), []);

    const methods = useForm({
        resolver: yupResolver(exportSchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    // get all cities  onload
    useEffect(() => {
        dispatch(getAllCities())
        dispatch(getCityWarehouses());
    }, [])

    const onFormSubmit = (submitData) => {
        const token = localStorage.getItem('accessToken');
        const city_id = submitData?.city_id;
        const warehouse_id = submitData?.warehouse_id;
        const status = submitData?.status;
        const formatedStart = moment(startDate).format('DD-MM-yyyy');
        const formatedEnd = moment(endDate).format('DD-MM-yyyy');
        window.location = WEBSITE_API_URL + "/export-sales-order-report?token=" + token + "&start_date=" + formatedStart + "&end_date=" + formatedEnd + "&city=" + city_id + "&warehouse=" + warehouse_id + "&status=" + status + "&type=SALES";
    }

    const handleDatePicker = (dates) => {

        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    // const warehouse = { value: "ALL", label: "ALL" };
    const city = cities?.map((city) => (
        { value: city.id, label: city.name }
    ));
    const additionalCitie = { value: 'All', label: 'ALL' };
    const all_cities = [additionalCitie, ...city];

    const warehous = warehouses.filter(cp => (cityId == "All" ? true : cp.city_id == cityId)).map((warehouse) => (
        { value: warehouse.id, label: warehouse.name }
    ));
    const additionalWarehouse = { value: 'All', label: 'ALL' };
    const all_warehouses = [additionalWarehouse, ...warehous];

    const status = [
        { value: "All", label: "ALL" },
        { value: "COMPLETED", label: "COMPLETED" },
        { value: "CANCELLED", label: "CANCELLED" },
    ];

    return (
        <React.Fragment>
            <Head title="Date Wise Orders"></Head>
            {/* content */}
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Date wise Sales
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                        <Col md="6">
                            <FormGroup>
                                <label className="form-label">Choose Date Range</label>
                                <div className="form-control-wrap">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleDatePicker}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control date-picker"
                                        required
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label className="form-label">Choose City </label>
                                <div className="form-control-wrap">
                                    <Controller
                                        name="city_id"
                                        control={control}
                                        render={({ onChange, value, ref }) => (
                                            <RSelect
                                                options={all_cities}
                                                onChange={(val) => {
                                                    onChange(val.value)
                                                    setCityId(val.value)
                                                    setWarehouseId('All')
                                                }}
                                                defaultValue={all_cities.find((c) => c.value === cityId)}
                                            />
                                        )}
                                        ref={register({ required: "This field is required" })}
                                    />
                                    {errors.city_id && (
                                        <span className="invalid text-danger text-italic">Please choose a City </span>
                                    )}
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label className="form-label">Choose Warehouse </label>
                                <div className="form-control-wrap">
                                    <Controller
                                        name="warehouse_id"
                                        control={control}
                                        render={({ onChange, value, ref }) => (
                                            <RSelect
                                                options={all_warehouses}
                                                onChange={(val) => { onChange(val.value); setWarehouseId(val.value) }}
                                                value={all_warehouses.filter(c => c?.value === warehouseId)}
                                                defaultValue={all_warehouses.find((c) => c.value === warehouseId)}
                                            />
                                        )}
                                        ref={register({ required: "This field is required" })}
                                    />
                                    {errors.warehouse_id && (
                                        <span className="invalid text-danger text-italic">Please choose a Warehouse </span>
                                    )}
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label className="form-label">Choose Status </label>
                                <div className="form-control-wrap">
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ onChange, value, ref }) => (
                                            <RSelect
                                                options={status}
                                                onChange={(val) => onChange(val.value)}
                                                defaultValue={status.find((c) => c.value === value)}
                                            />
                                        )}
                                        ref={register({ required: "This field is required" })}
                                    />
                                    {errors.status && (
                                        <span className="invalid text-danger text-italic">Please choose a Status </span>
                                    )}
                                </div>
                            </FormGroup>
                        </Col>


                        <Col md="12">
                            <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                    <Button type="submit" color="primary" size="lg" className="btn-block">
                                        <Icon name="download-cloud " className="px-2"></Icon>
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" /> Export
                                            </>
                                        ) : (
                                            <React.Fragment>Export</React.Fragment>
                                        )}
                                    </Button>
                                </li>

                            </ul>
                        </Col>
                    </Form>
                </Block>
            </Content>
        </React.Fragment>
    )
}

export default DateWiseOrders
