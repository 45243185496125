import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Form, FormGroup, Row } from 'reactstrap'
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, RSelect } from '../../../../../components/Component'
import Head from '../../../../../layout/head/Head'
import * as yup from "yup";
import { string } from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { WEBSITE_BASE_URL } from '../../../../../config';
import { openFormLoading } from '../../../../../redux/common/actions';
import { getSingleUser, updateUser } from '../../../../../redux/admin/user/action';
import ToastExample from '../../../../../helpers/toast';

function PersonalDetails(user_id) {

    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        user: state.users.single_user.user,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });

    const { form_loading, form_submission_status, user } = useSelector(getSelectors);
    const [showToast, setShowToast] = useState(false)
    const [editUser, setEditUser] = useState(user)
    console.log(editUser,"ddd")

    const userSchema = yup.object().shape({
        name: string().required(),
        phone: string()
            .matches(/^[6-9][0-9]{9}$/, 'Invalid phone number')
            .required('Phone number is required'),
        image: yup.mixed()
            .test("fileSize", "File too large", (value) => {

                if (value[0]) {
                    return value[0].size <= 200 * 1024;
                }
                return true;
            })
            .test("fileFormat", "Unsupported format", (value) => {
                if (value[0]) {
                    return ["image/jpeg", "image/png"].includes(value[0].type);
                }
                return true;
            }).notRequired(),
        email: string().required(),
        password: string().when('val', {
            is: (val) => val && val.length > 0,
            then: yup.string().min(5, 'Password must be at least 3 characters').max(14, "Password cannot exceed 14 characters"),
        }),



    });


    // schema to edit form data
    const defaultValues = useMemo(
        () => ({
            id: editUser?.id || "",
            name: editUser?.name || "",
            phone: editUser?.phone || "",
            email: editUser?.email || "",
            image: "",
            password: editUser?.password || "",
            status: editUser?.is_active === 0 ? 'true' : 'false' || "",
        }),
        [editUser]
    );

    const methods = useForm({
        resolver: yupResolver(userSchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    const onFormSubmit = (submitData) => {

        dispatch(openFormLoading());
        console.log(submitData);
        const formData = new FormData();
        formData.append('id', user?.id);
        formData.append('name', submitData?.name);

        formData.append('email', submitData?.email);
        formData.append('phone', submitData?.phone);
        formData.append('status', submitData?.status);
        if (submitData?.password) {
            formData.append('password', submitData?.password);
        }
        if (submitData?.image?.length) {
            formData.append('image', submitData?.image[0]);
        }
        dispatch(updateUser(formData)).then((response) => {
            if (response.payload.success) {
                setTimeout(() => {
                    setShowToast(true);
                    dispatch(getSingleUser(user?.id));
                }, 500)
                setTimeout(() => {
                    setShowToast(false);
                }, 3000)
            }
        })
    }

    // options for selectbox
    const status = [
        { value: 'false', label: "Active" },
        { value: 'true', label: "Banned" }
    ];

    return (
        <React.Fragment>
            <Head title="Personal Info"></Head>
            <BlockHead size="lg">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Personal Information</BlockTitle>
                        <BlockDes>
                            <p>Basic info, like your name and address, that you use on Sylcon.</p>
                        </BlockDes>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <ToastExample show={showToast} />
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>

            <Block>
                <div className="nk-data data-list">
                    <div className="data-head">
                        <h6 className="overline-title">Basics</h6>
                    </div>
                    <Form className="gy-3" onSubmit={handleSubmit(onFormSubmit)}>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label" htmlFor="user-name">
                                        User Name
                                    </label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            type="text"
                                            id="user-name"
                                            name='name'
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label" htmlFor="user-name">
                                        Profile Photo
                                    </label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        {user?.image ?
                                            <img src={WEBSITE_BASE_URL + user?.image} className="w-50 h-50 rounded-pill" />
                                            :
                                            <Badge className="badge-dot" color="info">Upload Your Profile Photo</Badge>
                                        }
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Image</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            className="form-control"
                                            type="file"
                                            name="image"

                                            ref={register({ required: false })}

                                        />
                                        {errors.image && <span className="invalid">{errors.image.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Email</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input type="email" id="email"
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            name="email"
                                        />
                                        {errors.email && <span className="invalid">{errors.email.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Phone</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input type="tel" id="phone" minLength="10 " maxLength="10" className="form-control" name='phone'
                                            ref={register({ required: "This field is required" })} />
                                        {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Status</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <Controller
                                            name="status"
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <RSelect
                                                    options={status}
                                                    onChange={(val) => { onChange(val.value) }}
                                                    defaultValue={status.find((c) => c.value === value)}
                                                />
                                            )}
                                            ref={register()}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Password</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input type="password" id="password" className="form-control"
                                            ref={register({ required: false })}
                                            name="password"
                                            value={editUser?.password}
                                        />
                                        {console.log(editUser, 'editUser')}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>




                        <Row className="g-3">
                            <Col lg="7" className="offset-lg-5">
                                <FormGroup className="mt-2">
                                    <Button type='submit' color="primary" size="lg" >

                                        Update
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>

                </div>

            </Block>

        </React.Fragment>
    )
}

export default PersonalDetails
