import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import {
    GET_ALL_ADDON_URL,
    CREATE_ADDON_URL,
    UPDATE_ADDON_URL,
    TOGGLE_ADDON_URL,
    DELETE_ADDON_URL,
    BULK_UPLOAD_ADDON_URL,

    GET_ALL_ADDON_CATEGORY_URL,
    CREATE_ADDON_CATEGORY_URL,
    UPDATE_ADDON_CATEGORY_URL,
    TOGGLE_ADDON_CATEGORY_URL,
    DELETE_ADDON_CATEGORY_URL,
    BULK_UPLOAD_ADDON_CATEGORY_URL,
} from "../../../config";
import { GET_ALL_ADDON, GET_ALL_ADDON_CATEGORY } from './actionType';


// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//-------------------------------------Addon Categories------------------------------
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export const getAllAddonCategories = () => (dispatch) => {
    return axiosClient.post(GET_ALL_ADDON_CATEGORY_URL).then((response) => {
        if (response && response.data.success) {
            const addon_categories = response.data.data;
            return dispatch({ type: GET_ALL_ADDON_CATEGORY, payload: addon_categories });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const createAddonCategory = (data) => (dispatch) => {
    return axiosClient.post(CREATE_ADDON_CATEGORY_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    }).catch(error => {
        console.log(error);
    });
};

export const updateAddonCategory = (data, id) => (dispatch) => {
    return axiosClient.post(UPDATE_ADDON_CATEGORY_URL, { data, id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    }).catch(error => {
        console.log(error);
    });
};

export const toggleAddonCategory = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_ADDON_CATEGORY_URL, { id });
};

export const deleteAddonCategory = (id) => (dispatch) => {
    return axiosClient.post(DELETE_ADDON_CATEGORY_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const bulkUploadAddonCategories = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } }
    return axiosClient.post(BULK_UPLOAD_ADDON_CATEGORY_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};


// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//-------------------------------------Addons----------------------------------------
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export const getAllAddons = () => (dispatch) => {
    return axiosClient.post(GET_ALL_ADDON_URL).then((response) => {
        if (response && response.data.success) {
            const addons = response.data.data;
            return dispatch({ type: GET_ALL_ADDON, payload: addons });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const toggleAddon = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_ADDON_URL, { id });
};

export const createAddon = (data) => (dispatch) => {
    return axiosClient.post(CREATE_ADDON_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    }).catch(error => {
        console.log(error);
    });
};

export const updateAddon = (data, id) => (dispatch) => {
    return axiosClient.post(UPDATE_ADDON_URL, { data, id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    }).catch(error => {
        console.log(error);
    });
};

export const deleteAddon = (id) => (dispatch) => {
    return axiosClient.post(DELETE_ADDON_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const bulkUploadAddons = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } }
    return axiosClient.post(BULK_UPLOAD_ADDON_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};
