import React, { useEffect, useState } from "react";
// import slideimg from '../../assets/images/Discount Banner.png'
// import noCart from '../../assets/images/noCart.png'

import { useDispatch, useSelector } from "react-redux";
import CartItems from "./cartItems";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Progress,
  Row,
  Spinner,
  Form,
} from "reactstrap";
// import Loading from '../Elements/loading'
// import { calcualteWarehouseMaxDistance, calculateWarehouseChargeTax, checkTokenValid, getCartWarehouse, itemsActiveCheck } from '../../../../redux/store/cart'

import {
  calcualteWarehouseMaxDistance,
  calculateWarehouseChargeTax,
  checkTokenValid,
  clearCart,
  getCartWarehouse,
  itemsActiveCheck,
  placeOrder,
} from "../../../../redux/admin/cart/action";
import { clearCartInDashboardCreateOrder } from "../../../../redux/admin/warehouse/action";
import { Block } from "../../../../components/Component";
import Content from "../../../../layout/content/Content";

function Cart(items) {
  console.log("itemsIncart", items);
  const dispatch = useDispatch();
  const [paymentMode, setPaymentMode] = useState("COD");

  const [show, setShow] = useState(false);
  const [cartNote, setCartNote] = useState(localStorage.getItem("cartNote"));
  const [isExploding, setIsExploding] = useState(false);
  const [itemIsActive, setItemIsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inActiveAddons, setInActiveAddons] = useState([]);
  const [inActiveAddonCategories, setInActiveAddonCategories] = useState([]);
  const [inActiveItemVariants, setInActiveItemVariants] = useState([]);
  const [inActiveItems, setInActiveItems] = useState([]);
  const cartProducts = useSelector((state) => state.cart);
  const userLogin = localStorage.getItem("userLogin");
  const user_id = localStorage.getItem("user_id");
  const auth_token = localStorage.getItem("auth_token");
  console.log("cartProductsblabla", cartProducts);

  // const orderItems = [];
  // // cartProducts.push(cartProduct?.orderitems);
  // orderItems.push(cartProducts?.orderitems);

  // console.log("orderItems22",orderItems);


    useEffect(() => {
        const cartItems = JSON.stringify(cartProducts?.orderitems)
        console.log("***cartItems***", cartProducts?.orderitems);
        // const formData1 = new FormData();
        // formData1.append("token", localStorage.getItem('auth_token'));
        // formData1.append("user_id", localStorage.getItem('user_id'));
        // dispatch(checkTokenValid(formData1)).then((response) => {
        //     if (response && response.payload && response.payload.success) {
        //         // setLoading(false)
        //     } else {
        //         localStorage.removeItem("auth_token");
        //         localStorage.removeItem("userLogin");
        //         localStorage.removeItem("user_id");
        //     }
        // });
    
        const formData4 = new FormData();
        formData4.append("warehouse_id", cartProducts?.warehouse_id);
        formData4.append("products", cartItems);
        dispatch(itemsActiveCheck(formData4)).then((response) => {
            if (response && response.payload && response.payload.success) {
                setInActiveItems(response.payload.isActiveItemIds)
                setInActiveAddons(response.payload.isActiveAddonIds)
                setInActiveAddonCategories(response.payload.isActiveAddonCategoryIds)
                setInActiveItemVariants(response.payload.InActiveVariantIds)
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
        // dispatch(itemsActiveCheck({warehouse_id:cartProducts?.warehouse_id,products:cartItems})).then((response) => {


        const formData = new FormData();
        formData.append("warehouse_id", cartProducts?.warehouse_id);
        dispatch(getCartWarehouse(formData));
        const formData2 = new FormData();
        formData2.append("id", localStorage.getItem('user_id'));
        formData2.append("warehouse_id", cartProducts?.warehouse_id);
        formData2.append("products", cartItems);
        formData2.append("latitude", localStorage.getItem("userLat"));
        formData2.append("longitude", localStorage.getItem("userLng"));
        dispatch(calcualteWarehouseMaxDistance(formData2));

        const formData3 = new FormData();
        formData3.append("products", cartItems);
        dispatch(calculateWarehouseChargeTax(formData3))

    
    }, []);

    
    const _completeOrder = (e) => {
        // setLoading(true)
        e.preventDefault();
        setPaymentMode("COD") 
        const formData = new FormData();
        let cartProduct = JSON.stringify(cartProducts.orderitems);
        // let defaultAddress = localStorage.getItem("userSetAddress");
        formData.append("customer_id", localStorage.getItem('customer_id'));
        formData.append("address", localStorage.getItem('address'));
        formData.append("landmark", localStorage.getItem('landmark'));
        formData.append("customer_name", localStorage.getItem('customer_name'));

  
  
        formData.append("latitude", localStorage.getItem('userLat'));
        formData.append("longitude", localStorage.getItem('userLng'));
  
        formData.append("token", auth_token);
        formData.append("paymentMode", paymentMode);
        formData.append("coupon_code", cartProducts?.appliedCoupon?.coupon_code ?? null);
        formData.append("cartProducts", cartProduct);
        formData.append("deliveryNote", localStorage.getItem('cartNote')??null);
        formData.append("subtotal", cartProducts.totalAmount);
        let discount = 0;
        if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.discount_type == "PERCENTAGE") {
            let percentage_discount = ((parseFloat(cartProducts?.appliedCoupon?.coupon_discount) / 100) * parseFloat(cartProducts?.totalAmount));
            console.log(percentage_discount, 'ss')
            if (parseFloat(cartProducts?.appliedCoupon?.max_discount)) {
                if (parseFloat(percentage_discount) >= parseFloat(cartProducts?.appliedCoupon?.max_discount)) {
                    percentage_discount = parseFloat(cartProducts?.appliedCoupon?.max_discount);
                }
            }
            discount = percentage_discount;
        } else if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.discount_type == "FIXED") {
            discount = parseFloat(cartProducts?.appliedCoupon?.coupon_discount);
        }
        formData.append("coupon_discount", discount);
        formData.append("distance", cartProducts?.warehouse_distance?.distance ?? 0);
        // formData.append("address", defaultAddress);
        formData.append("warehouse_id", cartProducts?.warehouse_id);
        formData.append("is_schedule", sessionStorage.getItem("IsSchedule") == "true" ? 1 : 0);
        formData.append("schedule_type", sessionStorage.getItem("scheduleType"));
        formData.append("schedule_date", sessionStorage.getItem("scheduleDate"));
        // formData.append("partial_wallet", partialWalletChecked);
        formData.append("totalPayableAmount", cartProducts?.totalPayableAmount);
        dispatch(placeOrder(formData)).then((response) => {
            if (response && response.payload && response.payload.success) {
                dispatch(clearCart());
                dispatch(clearCartInDashboardCreateOrder())
                localStorage.removeItem("address");
                localStorage.removeItem("customer_id");
                localStorage.removeItem("customer_name");
                localStorage.removeItem("userLat");
                localStorage.removeItem("customer_phone");
                localStorage.removeItem("landmark");

                localStorage.removeItem("userSetAddress");



                 window.location.replace("/create-orders")
            } else {
                setLoading(false)
                console.log(cartProducts, 'error')
            }   
        })
    }

    const _goToAddress = () => {
        localStorage.setItem("FromCart", true)
        window.location.replace("/address-change/add/null")
    }

    const noItemCard = () => {
        setItemIsActive(true)
        setTimeout(() => {
            setItemIsActive(false)
        }, 3000);
        // const element = document.getElementById('_noItem').scrollTop += 10;
        // if (element) {
        //     element.scrollIntoView({ behavior: 'smooth' });
        // }
    }

    const _deliveryCharge = () => {
        if (cartProducts && cartProducts?.deliveryCharge && cartProducts.deliveryCharge > 0) {
            return '+₹' + parseFloat(cartProducts.deliveryCharge).toFixed(2);
        } else {
            return "Free Delivery"
        }
    }

    const _totalCharge = () => {
        let total = 0;
        total += cartProducts.totalAmount
        if (cartProducts && cartProducts.deliveryCharge && cartProducts.deliveryCharge > 0) {
            total += parseFloat(cartProducts.deliveryCharge);
        }
        if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.discount_type == "PERCENTAGE") {
            let percentage_discount = ((parseFloat(cartProducts?.appliedCoupon?.coupon_discount) / 100) * parseFloat(cartProducts?.totalAmount));
            if (parseFloat(cartProducts?.appliedCoupon?.max_discount)) {
                if (parseFloat(percentage_discount) >= parseFloat(cartProducts?.appliedCoupon?.max_discount)) {
                    percentage_discount = parseFloat(cartProducts?.appliedCoupon?.max_discount);
                }
            }
            total -= percentage_discount;
        } else if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.discount_type == "FIXED") {
            total -= parseFloat(cartProducts?.appliedCoupon?.coupon_discount);
        }
        return parseFloat(total).toFixed(2);
    }

    const _couponDiscount = () => {
        let total = 0;
        if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.discount_type == "PERCENTAGE") {
            let percentage_discount = ((parseFloat(cartProducts?.appliedCoupon?.coupon_discount) / 100) * parseFloat(cartProducts?.totalAmount));
            if (parseFloat(cartProducts?.appliedCoupon?.max_discount)) {
                if (parseFloat(percentage_discount) >= parseFloat(cartProducts?.appliedCoupon?.max_discount)) {
                    percentage_discount = parseFloat(cartProducts?.appliedCoupon?.max_discount);
                }
            }
            total = percentage_discount;
        } else if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.discount_type == "FIXED") {
            total = parseFloat(cartProducts?.appliedCoupon?.coupon_discount);
        }
        return parseFloat(total).toFixed(2);
    }

    // const setNote = (e) => {
    //     if (e.length > 0) {
    //         localStorage.setItem("cartNote", e)
    //         setCartNote(e)
    //     } else {
    //         localStorage.removeItem("auth_token");
    //         localStorage.removeItem("userLogin");
    //         localStorage.removeItem("user_id");
    //     }
    // });




  return (
    <div style={{ minHeight: "100vh", userSelect: "none" }}>
      {/* //backgroundColor: "#fafafa" */}
      {loading ? (
        // <Loading />
        <div>Loading</div>
      ) : (
        <>
          <Row>
            {/* bill */}
            <Col md="8" className="mt-2">
              {/* <Card className=""> */}
              {/* <CardBody className="card-inner"> */}
              <div style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                <div className="flex justify-between mt-1">
                  <div>Order Details</div>
                </div>
              </div>

              <div className="flex flex-col ml-2 pt-2">
                {cartProducts?.orderitems?.length > 0 ? (
                  <div>
                    <CartItems
                      noItemCard={noItemCard}
                      inActiveAddons={inActiveAddons}
                      inActiveAddonCategories={inActiveAddonCategories}
                      inActiveItemVariants={inActiveItemVariants}
                      inActiveItems={inActiveItems}
                    />

                    <React.Fragment>
                      <Content>
                        <Block>
                          <Row className="g-gs">
                            <Col xxl="12">
                              <Row className="g-gs">
                                <Col xxl="12" md="12">
                                  <Card>
                                    <div className="nk-ecwg nk-ecwg3 pb-3">
                                      <div className="card-inner pb-0">
                                        <div className="flex flex-col">
                                          <div className="form-label flex justify-between" style={{ fontSize: "17px" }}>
                                            <div className="">Sub Amount</div>
                                            <div> ₹{cartProducts?.totalAmount?.toFixed(2)}</div>
                                          </div>

                                          <div className="form-label  flex justify-between pb-2">
                                            <div>Delivery Charge</div>
                                            <div> {_deliveryCharge()}</div>
                                          </div>
                                        </div>

                                        {cartProducts?.discount > 0 && (
                                          <div className="form-label  flex justify-between pb-2">
                                            <div>Coupon Discount</div>
                                            <div> -₹ {_couponDiscount()}</div>
                                          </div>
                                        )}

                                        <hr className="border border-dashed" />
                                        <div className="form-label  flex justify-between font-semibold px-3 pb-3">
                                          <div style={{ fontSize: "17px" }} className="text-black">
                                            Total
                                          </div>
                                          <div style={{ color: "#E84141", fontSize: "17px" }}> ₹{_totalCharge()}</div>
                                        </div>

                                        <div className="text-center">
                                          <Button
                                            color="success"
                                            className="btn btn-md btn-success"
                                            onClick={(e) => {
                                              _completeOrder(e);
                                            }}
                                          >
                                            PlaceOrder
                                          </Button>
                                        </div>

                                        {/* <div className="data">
                                              <div className="">
                                                <div className="pb-4">{item.description}</div>
                                              </div>
                                            </div> */}
                                      </div>
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Block>
                      </Content>
                    </React.Fragment>

                    {/* <div style={{}} className="py-7 flex flex-col justify-between mt-5">
                          <div className="px-3 font-medium text-base text-slate-500 ">
                            <div className="flex justify-between pb-2">
                              <div className="">Sub Amount</div>
                              <div> ₹{cartProducts?.totalAmount?.toFixed(2)}</div>
                            </div>
                            <div className="flex justify-between pb-2">
                              <div>Delivery Charge</div>
                              <div> {_deliveryCharge()}</div>
                            </div>
                            {cartProducts?.discount > 0 && (
                              <div className="flex justify-between pb-2">
                                <div>Coupon Discount</div>
                                <div> -₹ {_couponDiscount()}</div>
                              </div>
                            )}
                          </div>
                          <hr className="border border-dashed" />
                          <div className="flex justify-between font-semibold px-3 pb-3">
                            <div style={{ fontSize: "17px" }} className="text-black">
                              Total
                            </div>
                            <div style={{ color: "#E84141" }}> ₹{_totalCharge()}</div>
                          </div>
                          <div className=" px-3 w-100"> */}
                    {/* {inActiveAddons?.length > 0 || inActiveAddonCategories?.length > 0 || inActiveItemVariants?.length > 0 || inActiveItems?.length > 0 ?
                                        <button type="submit" className='get-start-button-full mt-3' onClick={() => { noItemCard() }}>
                                            Continue eee
                                        </button>
                                        : */}
                    {/* <Button
                              color="success"
                              className="btn btn-md btn-success"
                              onClick={(e) => {
                                _completeOrder(e);
                              }}
                            >
                              Continue
                            </Button>

                    
                          </div> */}
                    {/* </div> */}
                  </div>
                ) : (
                  <div style={{minHeight:"50vh"}} className=" flex justify-center items-center mt-5">

                    {/* <div style={{ minHeight: "50vh", color: "#E84141" }} className=" flex justify-center items-center"> */}
                      {/* <img src={noCart} style={{ width: '100%' }} alt='slideimg' /> */}
                      {/* no cart
                    </div>
                    <div className="flex justify-center mt-3"> */}
                      {/* <img src={slideimg} alt='slideimg' /> */}
                      {/* side img
                    </div> */}
                    No Items in cart

                  </div>
                )}
              </div>
              {/* </CardBody> */}
              {/* </Card> */}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default Cart;
