import React, { useState } from "react";
// import { useState } from "react"
// import ProgressiveImage from "react-progressive-image";
// import { useSelector, useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
// import { FaMinus, FaPlus } from "react-icons/fa";
// import { SwipeableDrawer } from "@material-ui/core";
import { HiOutlineMinus } from "react-icons/hi";
// import { RiCloseCircleLine } from "react-icons/ri";
// import { addCustomizationCart, addToCart, clearCart, addVariantCart, removeFromCart, removeVariantCart } from '';
import { BiMinus, BiPlus } from "react-icons/bi";
// import background from '../../assets/images/f6f5f1.jpg';
import { IoCloseOutline } from "react-icons/io5";
import { addCustomizationCart, addVariantCart, removeVariantCart } from "../../../../redux/admin/cart/action";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";

// import { IMAGE_BASE_URL } from "../../config";

function VariantCustomization({ product, addProducts, topItems, cartPage, listItems, singlePage }) {
  const dispatch = useDispatch();
  const [customizeOpen, setCustomizeOpen] = useState(false);
  const cartProducts = useSelector((state) => state.cart);

  const _processVariants = (variant) => {
    const updatedVariants = [];
    const productIndex = cartProducts?.orderitems?.findIndex((p) => p?.id === product?.id);

    if (productIndex > -1) {
      cartProducts?.orderitems?.forEach((cartProduct) => {
        if (cartProduct?.selectedvariants?.filter((v) => v?.variant_id === variant?.id)) {
          updatedVariants.push({
            variant_id: variant.id,
            variant_name: variant.name,
            quantity: 1,
            sell_price: variant.sell_price,
            totalPrice: parseFloat(variant.sell_price),
          });
        } else {
          updatedVariants.push({
            variant_id: variant.id,
            variant_name: variant.name,
            quantity: 1,
            sell_price: variant.sell_price,
            totalPrice: parseFloat(variant.sell_price),
          });
        }
      });
    } else {
      updatedVariants.push({
        variant_id: variant.id,
        variant_name: variant.name,
        quantity: 1,
        sell_price: variant.sell_price,
        totalPrice: parseFloat(variant.sell_price),
      });
    }

    const updatedProductData = { ...product, selectedvariants: updatedVariants };
    console.log("updatedProductData111", updatedProductData);
    dispatch(addVariantCart(updatedProductData));
  };

  const _processRemoveVariants = (variant) => {
    const removeVariants = [];
    removeVariants.push({
      variant_id: variant.id,
      variant_name: variant.name,
      quantity: variant.quantity,
      sell_price: variant.sell_price,
      totalPrice: parseFloat(variant.sell_price) * variant.quantity,
    });
    const updatedProductData = { ...product, selectedvariants: removeVariants };
    dispatch(addCustomizationCart(updatedProductData));
  };

  const totalPrice = (sell_price) => {
    let total = 0;
    const cartItem = cartProducts?.orderitems?.find((p) => p.id === product?.id);

    if (cartItem) {
      const totalPriceArray = cartItem?.selectedvariants.map((vart) => vart?.totalPrice);
      total = totalPriceArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
    if (total > 0) {
      return "₹" + parseFloat(sell_price) + " + ₹" + parseFloat(total);
    } else {
      return "₹" + parseFloat(sell_price);
    }
  };

  const _totalPrice = () => {
    let total = 0;
    const cartItem = cartProducts?.orderitems?.find((p) => p.id === product?.id);
    if (cartItem) {
      const totalPriceArray = cartItem?.selectedvariants.map((vart) => vart?.totalPrice);
      total = totalPriceArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
    return parseFloat(total);
  };

  return (
    <div>
    
      {topItems ? (
        <>
          <div
            className="rounded-l-md text-white p-1"
            style={{ backgroundColor: "#118A45" }}
            onClick={() => setCustomizeOpen(true)}
          >
            <BiPlus size={22} />
          </div>
        </>
      ) : listItems ? (
        <>
          <div
            className="rounded-lg text-white p-1"
            style={{ backgroundColor: "#118A45" }}
            onClick={() => setCustomizeOpen(true)}
          >
            <BiPlus size={22} />
          </div>
        </>
      ) : singlePage ? (
        <>
          <button className="get-start-button w-100 rounded-2xl" onClick={() => setCustomizeOpen(true)}>
            Add to Cart
          </button>
        </>
      ) : cartProducts.orderitems?.find((cp) => cp.id === product?.id) !== undefined ? (
        // <Link to="/bag">
        //     <div className='store-to-cart-btn'>
        //         To Cart
        //     </div>
        // </Link>

        <div className="store-to-cart-btn">To Cart</div>
      ) : (
        <div className="store-item-add-btn" onClick={() => setCustomizeOpen(true)}>
          {" "}
          Add
        </div>
      )}

      <Modal
        isOpen={customizeOpen}
        onClose={() => setCustomizeOpen(false)}
        // onOpen={() => setCustomizeOpen(true)}
        // toggle={() => setModal({ delete: false })}
        className="modal-dialog-centered"
      >
        <ModalBody className="pt-3 pb-2">
          <div className="flex items-center justify-center w-100">
            <HiOutlineMinus size={35} color={"#fff"} />
          
          </div>
          <div className="" style={{ paddingBottom: "6rem" }}>
            <div className="flex justify-between  px-4 pb-2 ">
              {/* <div className="font-22 fw-700" style={{ color: '#070648', }}>
                            <ProgressiveImage
                                delay={20}
                                src={IMAGE_BASE_URL + product.images[0]?.image}
                                placeholder={background}
                            >
                                {(src) => (
                                    <img
                                        src={src}
                                        style={{
                                            height: "28vw",
                                            width: "24vw", 
                                            borderRadius: "8px",
                                            objectFit: "contain",
                                        }}
                                        className="" alt="grosav"
                                    />
                                )}
                            </ProgressiveImage>
                        </div> */}
              <div className="flex flex-col text-xl grow px-2" style={{ fontWeight: "900" }}>
                <div className="flex text-xl grow px-2" style={{ fontWeight: "900" }}>
                  {product.name}
                  {/* Anirudh */}
                </div>
                <div className="flex items-center px-2" style={{ fontWeight: "600" }}>
                  ₹{product.sell_price}
                </div>
              </div>
              <div
                onClick={() => {
                  setCustomizeOpen(false);
                }}
              >
                <div className="p-1" style={{ borderRadius: "25px", border: "1px solid #ff8686" }}>
                  <IoCloseOutline color="red" size={20} />
                </div>
              </div>
            </div>
            <div className="mt-3 p-3" style={{ backgroundColor: "#F6FAF2" }}>
              {product?.item_variants?.map((variant, index) => (
                <div className="flex items-center py-1 text-sm" key={index}>
                  <div className="pe-2">{variant.name}</div>
                  <div className="pe-2">₹{variant.sell_price}</div>
                  {parseFloat(variant.market_price) > parseFloat(variant.sell_price) ? (
                    <>
                      <strike className="text-gray-600">
                        <div className="text-gray-400 pe-2" style={{ fontSize: "12px" }}>
                          {variant.market_price}
                        </div>
                      </strike>
                      <div
                        style={{
                          color: "#E84141",
                          backgroundColor: "#FEF5F5",
                          border: "1px solid #F8E9E5",
                          borderRadius: "8px",
                          padding: "3px 8px",
                          fontSize: "12px",
                        }}
                      >
                        {Math.round(
                          ((parseFloat(variant?.market_price) - parseFloat(variant?.sell_price)) /
                            parseFloat(variant?.market_price)) *
                            100
                        )}
                        % off
                      </div>
                    </>
                  ) : null}
                  {cartProducts.orderitems
                    ?.find((cp) => cp.id === product?.id)
                    ?.selectedvariants?.find((cp) => cp.variant_id === variant?.id) !== undefined ? (
                    <>
                      {cartProducts.orderitems
                        ?.find((cp) => cp.id === product?.id)
                        ?.selectedvariants?.filter((cp) => cp.variant_id === variant?.id)
                        .map((selVariant) => (
                          <div className="flex grow justify-end" key={selVariant.id}>
                            <div
                              className="flex justify-center items-center rounded-md text-white px-1 py-1"
                              style={{ backgroundColor: "#118A45", fontSize: "12px" }}
                              onClick={() => {
                                let cp = { ...variant, quantity: 1 };
                                _processRemoveVariants(cp);
                              }}
                            >
                              <BiMinus size={18} />
                              
                            </div>
                            <div className="px-1">{selVariant.quantity}</div>
                            <div
                              className="flex justify-center items-center rounded-md text-white px-1 py-1"
                              style={{ backgroundColor: "#118A45", fontSize: "12px" }}
                              onClick={() => {
                                let cp = { ...variant, quantity: 1 };
                                _processVariants(cp);
                              }}
                            >
                              <BiPlus size={18} />
                            </div>
                          </div>
                        ))}
                    </>
                  ) : (
                    <div className="flex grow justify-end">
                      <div
                        className="rounded-md text-white "
                        style={{ backgroundColor: "#118A45", fontSize: "12px", padding: "3px 20px" }}
                        onClick={() => {
                          let cp = { ...variant, quantity: 1 };
                          _processVariants(cp);
                        }}
                      >
                        Add
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex items-center text-sm text-gray-400 grow px-3 mt-3">{product.description}</div>
                  {/* <div className='cart-item-remove-round-btn' onClick={() => {
                        let newData = Object.assign({}, product, { isRemoveAll: true, });
                        dispatch(removeFromCart(newData));
                    }}>
                        Remove All
                    </div> */}
            <div className="text-xl px-3 mt-3 font-semibold">
              Total: <span className="ms-1 text-red-500 font-medium">{totalPrice(product.sell_price)}</span>
            </div>
            <div className="px-3 mt-3 font-semibold">
              Best Before: <span className="ms-1 text-gray-500 font-medium">03 days from packaging date</span>
            </div>

            <div className="fixed bottom-0 w-100 py-3 px-3 flex bg-white">
              <div
                className="rounded-xl text-white text-center w-100"
                style={{ backgroundColor: "#118A45", padding: "12px 0px" }}
                onClick={() => {
                  setCustomizeOpen(false);
                }}
              >
                {_totalPrice() > 0 ? "Add Now" : "Close"}
              </div>
            </div>

          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default VariantCustomization;
