import axiosClient from "../../../helpers/axios-client";
import {
  CART_WAREHOUSE_URL,
  CHECK_TOKEN_VALID_URL,
  GET_ALL_ITEMS_URL,
  ITEMS_ACTIVE_CHECK_URL,
  PLACE_ORDER_URL,
  WAREHOUSE_CHARGE_TAX_URL,
  WAREHOUSE_MAX_DISTANCE_URL
} from "../../../config";

import { ADD_TO_CART,ADD_ITEM_TO_SELECTED_ITEMS, CHECK_TOKEN_VALID, ITEMS_ACTIVE_CHECK, CART_WAREHOUSE,
   WAREHOUSE_MAX_DISTANCE, WAREHOUSE_CHARGE_TAX, REMOVE_FROM_CART, ADD_VARIANT_CART, REMOVE_VARIANT_CART,
    ADD_ADDONS_CART, REMOVE_ADDONS_CART, CLEAR_CART, UPDATE_CART, GET_ALL_ITEMS, ADDCUSTOMIZATION_CART, 
    ADD_PRODUCT_TO_CART,
    REMOVE_PRODUCT_FROM_CART} 
    from "./actionType";
import { formatPrice } from "../../../helpers/formatPrice";


export const addToCart = (payload) => (dispatch) => {
  console.log("Payload:......addToCart", payload); // Log the payload
  return dispatch({ type: ADD_TO_CART, payload: payload });

};

export const removeProductFromCart = (product) => ({
  type: REMOVE_PRODUCT_FROM_CART,
  payload: product,
});
export const addProductToCart = (product) => ({
  type: ADD_PRODUCT_TO_CART,
  payload: product,
});

// export const updateCart = (payload) => (dispatch) => {
//   console.log("bb", payload); // Log the payload
//   return dispatch({ type: UPDATE_CART, payload: payload });

// };


export const updateCart = (cartProducts) => (dispatch) => {
  let productQuantity = cartProducts?.reduce((sum, p) => {
      // sum += p.quantity;
      sum++;
      return sum;
  }, 0);

  let totalPrice = cartProducts.reduce((sum, p) => {
      sum += p.selling_price * p.quantity;
      sum = parseFloat(sum);
      formatPrice(sum);
      return sum;
  }, 0);
  let totalDiscount = cartProducts.reduce((sum, p) => {
      if (p.market_price && p.market_price > p.selling_price) {
          sum += (p.market_price - p.selling_price) * p.quantity;
      }
      sum = parseFloat(sum);
      formatPrice(sum);
      return sum;
  }, 0);

  let cartTotal = {
      productQuantity,
      totalPrice,
      totalDiscount,
  };

  dispatch({
      type: UPDATE_CART,
      payload: cartTotal,
  });
};


export const removeFromCart = (payload) => (dispatch) => {
  console.log("Payload:", payload); // Log the payload
  return dispatch({ type: REMOVE_FROM_CART, payload: payload });

};
export const addVariantCart = (payload) => (dispatch) => {
  console.log("Payload:", payload); // Log the payload
  return dispatch({ type: ADD_VARIANT_CART, payload: payload });

};
export const removeVariantCart = (payload) => (dispatch) => {
  console.log("Payload:", payload); // Log the payload
  return dispatch({ type: REMOVE_VARIANT_CART, payload: payload });
};
export const addAddonsCart = (payload) => (dispatch) => {
  console.log("Payload:", payload); // Log the payload
  return dispatch({ type: ADD_ADDONS_CART, payload: payload });

};
export const removeAddonsCart = (payload) => (dispatch) => {
  console.log("Payload:", payload); // Log the payload
  return dispatch({ type: REMOVE_ADDONS_CART, payload: payload });

};
export const clearCart = (payload) => (dispatch) => {
  console.log("Payload:", payload); // Log the payload
  return dispatch({ type: CLEAR_CART, payload: payload });

};
export const addCustomizationCart = (payload) => (dispatch) => {
  console.log("Payload:", payload); // Log the payload
  return dispatch({ type: ADDCUSTOMIZATION_CART, payload: payload });

};

export const addItemToSelectedItems = (payload) => (dispatch) => {
  console.log("Payload: for addItemToSelectedItems", payload); // Log the payload
  return dispatch({ type: ADD_ITEM_TO_SELECTED_ITEMS, payload: payload });

};

export const checkTokenValid = (data) => (dispatch) => {
  return axiosClient
      .post(CHECK_TOKEN_VALID_URL, data)
      .then((response) => {
          if (response && response.data.success) {
              const data = response.data;
              return dispatch({ type: CHECK_TOKEN_VALID, payload: data });
          }
      })
      .catch(function (error) {
          console.log(error);
      });
};


export const itemsActiveCheck = (data) => (dispatch) => {
  return axiosClient
      .post(ITEMS_ACTIVE_CHECK_URL, data)
      .then((response) => {
          if (response && response.data.success) {
              const data = response.data;
              return dispatch({ type: ITEMS_ACTIVE_CHECK, payload: data });
          }
      })
      .catch(function (error) {
          console.log(error);
      });
};

export const getCartWarehouse = (data) => (dispatch) => {
  return axiosClient
      .post(CART_WAREHOUSE_URL, data)
      .then((response) => {
          if (response && response.data.success) {
              const data = response.data;
              return dispatch({ type: CART_WAREHOUSE, payload: data });
          }
      })
      .catch(function (error) {
          console.log(error);
      });
};

export const calcualteWarehouseMaxDistance = (data) => (dispatch) => {
  return axiosClient
      .post(WAREHOUSE_MAX_DISTANCE_URL, data)
      .then((response) => {
          if (response && response.data.success) {
              const data = response.data;
              return dispatch({ type: WAREHOUSE_MAX_DISTANCE, payload: data });
          }
      })
      .catch(function (error) {
          console.log(error);
      });
};

export const calculateWarehouseChargeTax = (data) => (dispatch) => {
  return axiosClient
      .post(WAREHOUSE_CHARGE_TAX_URL, data)
      .then((response) => {
          if (response && response.data.success) {
              const data = response.data;
              return dispatch({ type: WAREHOUSE_CHARGE_TAX, payload: data });
          }
      })
      .catch(function (error) {
          console.log(error);
      });
};
export const placeOrder = (data) => (dispatch) => {
  return axiosClient
      .post(PLACE_ORDER_URL, data)
      .then((response) => {
          if (response && response.data.success) {
              const data = response.data;
              return dispatch({ type: PLACE_ORDER_URL, payload: data });
          }
      })
      .catch(function (error) {
          console.log(error);
      });
};





