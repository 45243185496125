import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap'
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon, RSelect } from '../../../components/Component'
import ToastExample from '../../../helpers/toast'
import Content from '../../../layout/content/Content'
import Head from '../../../layout/head/Head'
import { createPermission, getAllPermission } from '../../../redux/admin/permission/action'
import { nonActiveFormSubmissionStatus, openFormLoading } from '../../../redux/common/actions'
import * as yup from "yup";
import { string } from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import ToggleButton from './ToggleButton'


const Permissions = () => {
    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        permissions: state.permissions.permissions,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });

    const { form_loading, form_submission_status, permissions } = useSelector(getSelectors);

    const [addPermission, setAddPermission] = useState([]); // state to add
    const [deletePermission, setDeletePermission] = useState([]); // state to delete
    const [modal, setModal] = useState(false);
    const [deleteModal, setDelete] = useState(false);
    const [showToast, setShowToast] = useState(false);


    // function to close the form modal
    const onFormCancel = () => {
        setModal(false);
        setDelete(false);
        resetForm();
    };

    // function to reset the form
    const resetForm = () => {
        setAddPermission({
            name: "",
            readable_name: "",
            head_id: "",

        });
    };

    // form validation schema
    const permissionSchema = yup.object({
        name: string().required(),
        readable_name: string().required(),
        head_id: string().required(),

    });

    // schema to edit form data
    const defaultValues = useMemo(
        () => ({
            id: "",
            name: "",
            readable_name: "",
            head_id: "",

        }),
        [addPermission]
    );

    const methods = useForm({
        resolver: yupResolver(permissionSchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    useEffect(() => {
        dispatch(getAllPermission())
    }, [])


    // add
    const openAddModal = () => {
        setAddPermission([]);
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal(true);
        }, 100);
    };
    // delete
    // to open delete modal
    const openDeleteModal = (permission) => {
        dispatch(nonActiveFormSubmissionStatus());
        setDeletePermission(permission);
        setTimeout(() => {
            setDelete(true)
        }, 100);
    }

    // submit data to api
    const onFormSubmit = (submitData) => {
        dispatch(openFormLoading());

        if (modal) {
            dispatch(createPermission(submitData));

        } else if (deleteModal) {
            dispatch(deletePermission(deletePermission.id));
        }

    };

    // useEffect which watches the formsubmission of delete and create.
    useEffect(() => {
        if (modal && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllPermission());

                setModal(false);
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
        if (deleteModal && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllPermission());
                setDelete(false);
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    }, [createPermission, modal, form_loading, form_submission_status]);

    // permission head for select in add form
    const permissionHead = permissions?.permission_heads?.map((head) => (
        { value: head.id, label: head.name }

    ));

    // for looping in permissions head
    const all_permissions = permissions?.data;


    const p_heads = permissions?.permission_heads;




    return (
        <React.Fragment>
            <Head title="Permissions "></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Permissions
                            </BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <ToastExample show={showToast} />
                                <div className="toggle-expand-content" >
                                    <ul className="nk-block-tools g-3">
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon" onClick={() => openAddModal()} >
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Content>
                    <table className="table table-striped bg-white">
                        <thead className=' bg-white py-3'>
                            <tr className='py-5'>
                                <th scope="col">#</th>
                                <th scope="col">Permission Name</th>
                                {permissions?.roles?.map((role) => (
                                    <th scope="col">{role.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className=' bg-white'>
                            {p_heads && p_heads.map((head) => (
                                <React.Fragment>
                                    <tr className='bg-light '>
                                        <td className=' bg-light' colspan="12">
                                            <span className='text-uppercase fw-bold'>{head.name}</span>
                                        </td>
                                    </tr>
                                    <React.Fragment>
                                        {all_permissions && all_permissions?.map((permission) => {
                                            if (permission?.permission_head_id == head.id) {
                                                return (
                                                    <tr className='bg-white py-4'>
                                                        <th scope="row" className='bg-white text-center'>{permission?.id}</th>
                                                        <td className=' bg-white'>
                                                            {permission?.readable_name}
                                                            <br />
                                                            <Badge pill color="danger" className='cursor-pointer' onClick={(e) => openDeleteModal(permission)}>Remove</Badge>
                                                        </td>
                                                        {permissions?.roles?.map((role) => (
                                                            <td className=' bg-white'>
                                                                <ToggleButton permission={permission} role={role} />
                                                            </td>
                                                        ))}
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </React.Fragment>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </Content>
            </Content>

            {/* add modal */}
            <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="lg" >
                <ModalBody>
                    <a href="#cancel"
                        onClick={(ev) => { ev.preventDefault(); onFormCancel(); }}
                        className="close" >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Add Permission</h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Permission Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Enter Permission Name"
                                            ref={register()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Readable Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="readable_name"
                                            placeholder="Enter Readable  Name"
                                            ref={register()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Permission Head </label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="head_id"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={permissionHead}
                                                        onChange={(val) => onChange(val.value)}
                                                        defaultValue={permissionHead.find((c) => c.value === value)}
                                                    />
                                                )}
                                                ref={register()}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" /> Createing New Permission
                                                    </>
                                                ) : (
                                                    <React.Fragment>Add New Permission </React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <a href="#cancel"
                                                onClick={(ev) => { ev.preventDefault(); onFormCancel(); }}
                                                className="link link-light" >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* delete modal */}
            <Modal
                isOpen={deleteModal}
                toggle={() => setDelete(false)}
                className="modal-dialog-centered" >
                <ModalBody className="pt-3 pb-2">
                    <a href="#cancel"
                        onClick={(ev) => { ev.preventDefault(); onFormCancel(); }}
                        className="close" >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            Delete Permission <Badge color="primary" >{deletePermission?.readable_name}</Badge> ?
                        </h5>
                        <div className="mt-2">
                            <span>If you deleted this permission you need to contact Developing team for retrieving this module. So, Do you really want to Delete this ?</span>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button
                                        onClick={(e) => { e.preventDefault(); onFormSubmit(); }}
                                        color="danger"
                                        className="px-4 btn-block" >
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />
                                                Deleting
                                            </>
                                        ) : (
                                            <React.Fragment>Delete</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a href="#cancel"
                                        onClick={(ev) => { ev.preventDefault(); onFormCancel(); }}
                                        className="link link-light" >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default Permissions
