import {
  ADD_TO_CART,
  ADD_ITEM_TO_SELECTED_ITEMS,
  CHECK_TOKEN_VALID,
  CLEAR_CART,
  REMOVE_ADDONS_CART,
  ADD_ADDONS_CART,
  REMOVE_VARIANT_CART,
  ADDCUSTOMIZATION_CART,
  ADD_VARIANT_CART,
  REMOVE_FROM_CART,
  ITEMS_ACTIVE_CHECK,
  CART_WAREHOUSE,
  UPDATE_CART,
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
} from "./actionType";

const initialState = {
  orderitems: [],
  totalAmount: 0,
  total: [],
  totalPayableAmount: 0,
  warehouse_id: null,
  selectedItems: [],
  validtokens: [],
  activeitems: [],
  cartwarehouse: [],
  appliedCoupon: [],
  warehouse_info: [],
  warehouse_tax: [],
  warehouse_distance: [],
  deliveryCharge: 0,
  discount: 0,
  compelete_order: [],

  amountToBeReturned: 0,
  paymentMethod: "Cash",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REMOVE_PRODUCT_FROM_CART:
      const { totalAmount: removeTotalAmount } = state;
      const { id: removeId, isRemoveAll: removeIsRemoveAll } = action.payload;
      const itemToRemove = state.orderitems.find((item) => item.id === removeId);
      let currentTotalRemove = removeTotalAmount;

      if (itemToRemove) {
        if (removeIsRemoveAll) {
          currentTotalRemove -= itemToRemove.totalPrice;
          const removeIndex = state.orderitems.findIndex((p) => p.id === removeId);
          state.orderitems.splice(removeIndex, 1);
          if (state.orderitems.length === 0) {
            currentTotalRemove = 0;
          }
          state.warehouse_id = [];
        } else {
          itemToRemove.quantity -= 1;
          let removeTotalPrice = 0;
          removeTotalPrice = parseFloat((itemToRemove.sell_price * itemToRemove.quantity).toFixed(2));
          itemToRemove.totalPrice = removeTotalPrice;
          currentTotalRemove -= itemToRemove.sell_price;

          if (itemToRemove.quantity === 0) {
            const removeIndex = state.orderitems.findIndex((p) => p.id === removeId);
            state.orderitems.splice(removeIndex, 1);
            if (state.orderitems.length === 0) {
              currentTotalRemove = 0;
            }
          }
        }
        return {
          ...state,
          totalAmount: parseFloat(currentTotalRemove),
          totalPayableAmount: parseFloat(currentTotalRemove.toFixed(2)),
        };
      }
      return { ...state };

    case ADD_PRODUCT_TO_CART:
      const { totalAmount } = state;
      const { id, name, sell_price, description, warehouse, market_price, images, quantity } = action.payload;

      const item = state.orderitems.find((item) => item.id === id);
      let currentTotal = totalAmount;

      if (item) {
        let sum = 0;
        item.quantity += 1;
        sum = parseFloat((item.sell_price * item.quantity).toFixed(2));
        item.totalPrice = sum;
        currentTotal = parseFloat(currentTotal) + parseFloat(sell_price);
      } else {
        let sum = parseFloat((sell_price * quantity).toFixed(2));
        state.orderitems.push({
          id: id,
          name: name,
          market_price: market_price,
          images: images,
          description: description,
          warehouse: warehouse,
          quantity: 1,
          sell_price: sum,
        });
        currentTotal = parseFloat(currentTotal) + parseFloat(sum);
      }
      return {
        ...state,
        warehouse_id: warehouse.id,
        totalAmount: parseFloat(currentTotal),
        totalPayableAmount: parseFloat(currentTotal.toFixed(2)),
      };

    case UPDATE_CART:
      return {
        ...state,
        total: action.payload,
      };

    case ADD_VARIANT_CART: {
      const { orderitems, totalAmount } = state;
      const { id, name, description, sell_price, warehouse, images, selectedvariants } = action.payload;
      let currentTotal = totalAmount;
      const item = orderitems?.find((item) => item.id === id);

      if (item) {
        let newPrice = 0;
        if (item.selectedvariants && item.selectedvariants.length > 0) {
          item.selectedvariants.forEach((variant) => {
            const variant_selected = selectedvariants.find((selVart) => selVart.variant_id === variant.variant_id);
            const variant_not_selected = selectedvariants.filter(
              (selVart) => selVart.variant_id !== variant.variant_id
            );

            if (variant_selected) {
              variant.quantity += variant_selected.quantity;
              variant.totalPrice = variant.quantity * parseFloat(variant_selected.sell_price);
              newPrice = variant_selected.totalPrice;
              const index = item.selectedvariants.findIndex((p) => p.variant_id === variant.variant_id);
              item.selectedvariants[index] = {
                ...item.selectedvariants[index],
                quantity: variant.quantity,
                totalPrice: variant.totalPrice,
              };
              currentTotal = parseFloat(currentTotal) + newPrice;
            }

            if (variant_not_selected) {
              variant_not_selected.forEach((variant_not) => {
                if (variant_not.variant_id !== variant.variant_id) {
                  const index = item.selectedvariants.findIndex((cp) => cp.variant_id === variant_not.variant_id);
                  if (index === -1) {
                    let totalPrice = parseFloat(variant_not.totalPrice);
                    item.selectedvariants.push(variant_not);
                    currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice);
                  }
                }
              });
            }
          });
        } else {
          let totalPrice = 0;
          totalPrice += selectedvariants.reduce((variantPrice, p) => {
            variantPrice += parseFloat(p.totalPrice);
            return variantPrice;
          }, 0);
          orderitems.push({
            id: id,
            name: name,
            description: description,
            selectedvariants: selectedvariants,
            warehouse: warehouse,
            images: images,
            quantity: 1,
            sell_price: sell_price,
          });
          currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice) + parseFloat(sell_price);
        }
      } else {

        console.log("ADD_VARIANT_CART-else");
        let totalPrice = 0;
        totalPrice += selectedvariants.reduce((variantPrice, p) => {
          variantPrice += parseFloat(p.totalPrice);
          return variantPrice;
        }, 0);
        orderitems.push({
          id: id,
          name: name,
          description: description,
          selectedvariants: selectedvariants,
          warehouse: warehouse,
          images: images,
          quantity: 1,
          sell_price: sell_price,
        });

        console.log("orderitems:2", orderitems);
        currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice) + parseFloat(sell_price);
      }

      return {
        ...state,
        warehouse_id: warehouse.id,
        totalAmount: parseFloat(currentTotal),
        totalPayableAmount: parseFloat(currentTotal.toFixed(2)),
      };
    }

    case REMOVE_VARIANT_CART: {
      const { orderitems, totalAmount } = state;
      const { id, selectedvariants } = action.payload;
      let currentTotal = totalAmount;

      const itemIndex = orderitems.findIndex((item) => item.id === id);
      if (itemIndex !== -1) {
        const item = orderitems[itemIndex];

        if (item.selectedvariants && item.selectedvariants.length > 0) {
          item.selectedvariants.forEach((variant) => {
            const variantToRemove = selectedvariants.find(
              (selVart) => selVart.variant_id === variant.variant_id && variant.quantity > 1
            );
            const variantToRemoveAll = selectedvariants.find(
              (selVart) => selVart.variant_id === variant.variant_id && variant.quantity <= 1
            );

            if (variantToRemoveAll) {
              currentTotal -= variant.totalPrice;
              const index = item.selectedvariants.findIndex((p) => p.variant_id === variant.variant_id);
              item.selectedvariants.splice(index, 1);
              if (item.selectedvariants.length === 0) {
                currentTotal -= parseFloat(item.sell_price);
                orderitems.splice(itemIndex, 1);
                if (orderitems.length === 0) {
                  currentTotal = 0;
                }
              }
            } else if (variantToRemove) {
              variant.quantity -= variantToRemove.quantity;
              variant.totalPrice = variant.quantity * parseFloat(variantToRemove.sell_price);
              currentTotal -= variantToRemove.totalPrice;
            }
          });
        }
      }

      return {
        ...state,
        orderitems: [...orderitems],
        totalAmount: parseFloat(currentTotal),
        totalPayableAmount: parseFloat(currentTotal.toFixed(2)),
      };
    }
    
    case ADDCUSTOMIZATION_CART: {
      const { orderitems, totalAmount } = state;
      const { id, name, description, warehouse, market_price, sell_price, images, quantity, selectedaddons, addons } =
        action.payload;
      // console.log("addons1443", addons.selectedaddons);
      const item = orderitems?.find((item) => item.id === id);
      console.log(orderitems, "orderitems1443");

      let currentTotal = totalAmount;
      if (item) {
        let newPrice = 0;
        if (item.selectedaddons && item.selectedaddons.length > 0) {
          console.log(item.selectedaddons, "item.selectedaddons1443");

          item.selectedaddons.map((addon) => {
            const addon_selected = selectedaddons.find((selAdon) => selAdon.addon_id === addon.addon_id);
            const addon_not_selected = selectedaddons.filter((selAdon) => selAdon.addon_id !== addon.addon_id);
            if (addon_selected) {
              addon.quantity += quantity;
              addon.totalPrice = addon.quantity * parseFloat(addon.singlePrice);
              newPrice = quantity * parseFloat(addon.singlePrice);
              const index = item.selectedaddons.findIndex((p) => p.addon_id === addon.addon_id);
              item.selectedaddons[index] = {
                ...item.selectedaddons[index],
                quantity: addon.quantity,
                totalPrice: addon.totalPrice,
              };
              currentTotal = parseFloat(currentTotal) + newPrice;
            }
            if (addon_not_selected) {
              console.log(item.addon_not_selected, "item.addon_not_selected");

              addon_not_selected.map((addon_not) => {
                if (addon_not.addon_id !== addon.addon_id) {
                  console.log(addon_not, "addon_not");
                  const index = item.selectedaddons.findIndex((cp) => cp.addon_id === addon_not.addon_id);
                  if (index == -1) {
                    let totalPrice = parseFloat(addon_not.totalPrice);
                    item.selectedaddons.push(addon_not);
                    currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice);
                  }
                }
              });
            }
          });
        } else {
          console.log(item.addon_not_selected, "noooo");

          let totalPrice = 0;
          totalPrice += selectedaddons?.reduce((AddonPrice, p) => {
            AddonPrice += parseFloat(p.totalPrice);
            return AddonPrice;
          }, 0);
          orderitems.push({
            id: id,
            name: name,
            market_price: market_price,
            sell_price: sell_price,
            description: description,
            selectedaddons: selectedaddons,
            quantity: 1,
            warehouse: warehouse,
            images: images,
          });
          currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice);
        }
      } else {
        let totalPrice = 0;
        totalPrice += addons.selectedaddons?.reduce((AddonPrice, p) => {
          AddonPrice += parseFloat(p.totalPrice);
          return AddonPrice;
        }, 0);
        orderitems?.push({
          id: id,
          name: name,
          sell_price: sell_price,
          market_price: market_price,
          description: description,
          selectedaddons: addons.selectedaddons,
          warehouse: warehouse,
          quantity: 1,
          images: images,
        });
        console.log("orderitems:3", orderitems);
        currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice);
      }
      // state.warehouse_id = warehouse.id;
      // state.totalAmount = parseFloat(currentTotal);
      // state.totalPayableAmount = parseFloat(currentTotal.toFixed(2));

      return {
        ...state,
        warehouse_id : warehouse.id,
        totalAmount : parseFloat(currentTotal),
        totalPayableAmount : parseFloat(currentTotal.toFixed(2)),
      };
    }
    // case ADD_ADDONS_CART: {
    //   const { orderitems, totalAmount } = state;
    //   const { id, warehouse, addAddonId } = action.payload;
    //   let currentTotal = totalAmount;
    //   const item = orderitems.find((item) => item.id === id);

    //   if (item && item.selectedaddons && item.selectedaddons.length > 0) {
    //     item.selectedaddons.forEach((addon) => {
    //       if (addon.addon_id === addAddonId) {
    //         addon.quantity += 1;
    //         addon.totalPrice = addon.quantity * parseFloat(addon.singlePrice);
    //         currentTotal += parseFloat(addon.singlePrice);
    //       }
    //     });
    //   }

    //   return {
    //     ...state,
    //     warehouse_id: warehouse.id,
    //     totalAmount: parseFloat(currentTotal),
    //     totalPayableAmount: parseFloat(currentTotal.toFixed(2)),
    //   };
    // }

    // case REMOVE_ADDONS_CART: {
    //   const { orderitems, totalAmount } = state;
    //   const { id, isRemoveAll, removeAddonId } = action.payload;
    //   let currentTotal = totalAmount;

    //   const item = orderitems.find((item) => item.id === id);
    //   if (item && item.selectedaddons && item.selectedaddons.length > 0) {
    //     item.selectedaddons.forEach((addon) => {
    //       if (addon.addon_id === removeAddonId) {
    //         if (isRemoveAll === "true") {
    //           currentTotal -= addon.totalPrice;
    //           const index = item.selectedaddons.findIndex((p) => p.addon_id === addon.addon_id);
    //           item.selectedaddons.splice(index, 1);
    //           if (item.selectedaddons.length === 0) {
    //             currentTotal -= parseFloat(item.sell_price);
    //             const itemIndex = orderitems.findIndex((p) => p.id === id);
    //             orderitems.splice(itemIndex, 1);
    //             if (orderitems.length === 0) {
    //               currentTotal = 0;
    //             }
    //           }
    //         } else {
    //           addon.quantity -= 1;
    //           addon.totalPrice = addon.quantity * parseFloat(addon.singlePrice);
    //           currentTotal -= parseFloat(addon.singlePrice);
    //           if (addon.quantity === 0) {
    //             const index = item.selectedaddons.findIndex((p) => p.addon_id === addon.addon_id);
    //             item.selectedaddons.splice(index, 1);
    //             if (item.selectedaddons.length === 0) {
    //               const itemIndex = orderitems.findIndex((p) => p.id === id);
    //               orderitems.splice(itemIndex, 1);
    //               if (orderitems.length === 0) {
    //                 console.log("7");
    //               }
    //             }
    //           }
    //         }
    //       }
    //     });
    //   }

    //   return {
    //     ...state,
    //     totalAmount: parseFloat(currentTotal),
    //     totalPayableAmount: parseFloat(currentTotal.toFixed(2)),
    //   };
    // }

    case CLEAR_CART:
      return {
        ...state,
        orderitems: [],
        totalAmount: 0,
        discount: 0,
        amountToBeReturned: 0,
        totalPayableAmount: 0,
        paymentMethod: "Cash",
        warehouse_id: null,
        appliedCoupon: [],
        warehouse_info: [],
        warehouse_tax: [],
        warehouse_distance: [],
        deliveryCharge: 0,
        compelete_order: [],
      };

    case ADD_ITEM_TO_SELECTED_ITEMS: {
      const { payload } = action;
      const selectedItems = state.selectedItems || []; // Ensure selectedItems is initialized
      return {
        ...state,
        selectedItems: selectedItems.concat(payload),
      };
    }
    case CHECK_TOKEN_VALID: {
      return { ...state, validtokens: action.payload };
    }
    case ITEMS_ACTIVE_CHECK: {
      return { ...state, activeitems: action.payload };
    }
    case CART_WAREHOUSE: {
      return { ...state, cartwarehouse: action.payload };
    }

    default:
      return state;
  }
}
