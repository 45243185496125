import React from "react";
import {
  acceptOrder,
  acceptReturnedOrder,
  assignDeliveryGuyToOrder,
  assignReturnedDeliveryGuy,
  cancelOrder,
  cancelOrderReturned,
  getOrderDeliveryPartners,
  getreturnedSingleOrder,
  updateOrderStatus,
  updateReturnedOrderStatus,
} from "../../../../redux/admin/order/action";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Head from "../../../../layout/head/Head";
import Content from '../../../../layout/content/Content';

import ToastExample from "../../../../helpers/toast";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
// import { Icon, RSelect } from '../../../../../components/Component';
import { Icon, RSelect } from "../../../../components/Component";

import { nonActiveFormSubmissionStatus, openFormLoading } from "../../../../redux/common/actions";
import moment from "moment";
import { Icons } from "react-toastify";

function Singleorderreturned() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getreturnedSingleOrder(id));
    dispatch(getOrderDeliveryPartners());
  }, []);
  const getSelectors = (state) => ({
    returned_single_order: state.order.returned_single_order,
    order_log: state.order.order_log,
    order_delivery_partners: state.order.order_delivery_partners,
    form_loading: state.common.form_loading,
    form_submission_status: state.common.form_submission_status,
    customer: state.order.returned_single_order?.order_item?.order?.user,
    item: state.order.returned_single_order?.order_item,
    orders: state.order.returned_single_order?.order_item?.order,
    warehouse: state.order.returned_single_order?.order_item?.order?.warehouse,
  });
  const {
    returned_single_order,
    order_log,
    order_delivery_partners,
    form_loading,
    form_submission_status,
    customer,
    item,
    orders,
    warehouse,
  } = useSelector(getSelectors);

  console.log(order_delivery_partners, "order_delivery_partners");
  console.log(warehouse, "warehouse");

  const acceptTheOrder = () => {
    dispatch(nonActiveFormSubmissionStatus());
    dispatch(openFormLoading());
    const data = new FormData();
    data.append("id", returned_single_order?.id);
    dispatch(acceptReturnedOrder(data));
  };
  // const changeStatustoChefCooking = () => {
  //     dispatch(nonActiveFormSubmissionStatus());
  //     dispatch(openFormLoading())
  //     const data = new FormData();
  //     data.append('id', single_order?.id);
  //     data.append('order_status_id', 3)
  //     data.append('order_status_id', 3)
  //     dispatch(updateOrderStatus(data))
  // }
  const changeStatustoPickUp = () => {
    dispatch(nonActiveFormSubmissionStatus());
    dispatch(openFormLoading());
    const data = new FormData();
    data.append("id", returned_single_order?.id);
    data.append("order_status_id", 18);
    data.append("type", "pickup");
    dispatch(updateReturnedOrderStatus(data));
  };
  const completeTheOrder = () => {
    dispatch(nonActiveFormSubmissionStatus());
    dispatch(openFormLoading());
    const data = new FormData();
    data.append("id", returned_single_order?.id);
    data.append("order_status_id", 15);
    data.append("type", "complete");
    dispatch(updateReturnedOrderStatus(data));
  };
  //modal
  const [modal, setModal] = useState({ cancel: false });
  const openCancelModal = () => {
    dispatch(nonActiveFormSubmissionStatus());
    setModal({ cancel: true });
  };
  const onFormCancel = () => {
    setModal({ cancel: false });
    setCancelData("");
  };

  const cancelTheOrder = () => {
    dispatch(openFormLoading());
    const data = new FormData();
    data.append("id", returned_single_order?.id);
    if (cancelData?.reason === "OTHER") {
      data.append("cancellation_reason", cancelData?.other_reason);
    } else {
      data.append("cancellation_reason", cancelData?.reason);
    }
    if (modal.cancel) {
      dispatch(cancelOrderReturned(data));
    }
  };

  const canceloptions = [
    { value: "Product Used", label: "Product Used" },
    { value: "Product Damaged", label: "Product Damaged" },
    { value: "Product Missing Packaging", label: "Product Missing Packaging" },
    { value: "Product Not in Original Condition", label: "Product Not in Original Condition" },
    { value: "Return Period Expired", label: "Return Period Expired" },
    { value: "Return Policy Violation", label: "Return Policy Violation" },
    { value: "Return Not Authorized", label: "Return Not Authorized" },
    { value: "Other Reasons", label: "Other Reasons" },
  ];
  const [cancelData, setCancelData] = useState({ reason: "", other_reason: "" });
  const [cancelInput, setCancelInput] = useState(false);

  const handleInput = (val) => {
    setCancelData({ ...cancelData, reason: val });
    if (val === "OTHER") {
      setCancelInput(true);
    } else {
      setCancelInput(false);
    }
  };

  //delivery guy
  const [deliveryGuy, setDeliveryGuy] = useState(returned_single_order?.delivery_guy_detail_id);
  const [deliveryUpdate, setDeliveryUpdate] = useState(false);
  const deliveryguys = order_delivery_partners?.map((partner) => ({ value: partner.id, label: partner?.user?.name }));

  const assignDeliveryGuy = () => {
    if (deliveryGuy) {
      setDeliveryUpdate(true);
      dispatch(openFormLoading());
      const data = new FormData();
      data.append("order_id", returned_single_order?.id);
      data.append("delivery_guy_detail_id", deliveryGuy);
      dispatch(assignReturnedDeliveryGuy(data));
    } else {
      document.getElementById("nodelivery").innerHTML = "* Select a Delivery Guy.....";
    }
  };

  const [showToast, setShowToast] = useState(false); //toast state
  useEffect(() => {
    if (modal.cancel == true && form_loading == false && form_submission_status == true) {
      setTimeout(() => {
        dispatch(getreturnedSingleOrder(id));
        setModal({ cancel: false });
        setCancelInput(false);
        // setShowToast(true);
      }, 300);
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    } else if (form_loading == false && form_submission_status == true) {
      setTimeout(() => {
        setDeliveryUpdate(false);
        dispatch(getreturnedSingleOrder(id));
        // setShowToast(true);
      }, 300);
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    }
  }, [form_loading, modal, form_submission_status]);

  return (
    <React.Fragment>
      <Head title="View Order"></Head>
      <Content>
        <ToastExample show={showToast} />
        <Row>
          {/* customer */}
          <Col md="4">
            <Card className="">
              <CardBody className="card-inner">
                <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                  <Icon name="user-list" className="mr-1"></Icon>
                  Customer
                </CardSubtitle>
                <CardText className="flex flex-col ml-2">
                  <div>
                    <Icon name="user-fill" className="mr-1" style={{ color: "#8094ae" }}></Icon> {customer?.name}
                  </div>
                  <div>
                    <Icon name="call" className="mr-1" style={{ color: "#8094ae" }}></Icon> {customer?.phone}
                  </div>
                  <div className="d-flex">
                    <div>
                      <Icon name="map-pin" className="mr-1" style={{ color: "#8094ae" }}></Icon>
                    </div>
                    <div>{returned_single_order?.order_item?.order?.address}</div>
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          {/* warehouse */}
          <Col md="3">
            <Card className="">
              <CardBody className="card-inner">
                <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                  <Icon name="building" className="mr-1"></Icon> Warehouse
                </CardSubtitle>
                <CardText className="flex flex-col ml-2">
                  <div>
                    <Icon name="info-fill" className="mr-1" style={{ color: "#8094ae" }}></Icon> {warehouse?.name}
                  </div>
                  <div>
                    <Icon name="call" className="mr-1" style={{ color: "#8094ae" }}></Icon> {warehouse?.phone}
                  </div>
                  <div>
                    <Icon name="map-pin" className="mr-1" style={{ color: "#8094ae" }}></Icon> {warehouse?.address}
                  </div>
                  {warehouse?.order_comment != "null" && (
                    <div>
                      <em class="icon ni ni-clipboard"></em> {warehouse?.order_comment}
                    </div>
                  )}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          {/* progress bar */}
          <Col md="5">
            <Card className="">
              <CardBody className="card-inner">
                <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                  <div className="flex justify-between mt-1">
                    <div>
                      <Icon name="package" className="mr-1"></Icon> Order Status
                    </div>
                    <div className="flex flex-col" style={{ color: "#8094ae", fontSize: "12px" }}>
                      <Badge
                        color={
                          returned_single_order?.order_status_id == 15
                            ? "success"
                            : returned_single_order?.order_status_id == 14
                            ? "danger"
                            : returned_single_order?.order_status_id == 12
                            ? "secondary"
                            : "warning"
                        }
                      >
                        {returned_single_order?.order_status?.name}
                      </Badge>
                    </div>
                  </div>
                </CardSubtitle>
                <CardText className="ml-2 flex flex-col">
                  <div className="mt-3">
                    {returned_single_order?.order_status_id == 12 ? (
                      <Progress animated color="warning" value="10" />
                    ) : returned_single_order?.order_status_id == 13 ? (
                      <Progress animated color="success" value="20" />
                    ) : returned_single_order?.order_status_id == 16 ? (
                      <Progress animated color="success" value="30" />
                    ) : returned_single_order?.order_status_id == 17 ? (
                      <Progress animated color="success" value="50" />
                    ) : returned_single_order?.order_status_id == 18 ? (
                      <Progress animated color="success" value="70" />
                    ) : returned_single_order?.order_status_id == 19 ? (
                      <Progress animated color="success" value="85" />
                    ) : returned_single_order?.order_status_id == 15 ? (
                      <Progress animated color="success" value="100" />
                    ) : returned_single_order?.order_status_id == 14 ? (
                      <Progress animated color="danger" value="100" />
                    ) : returned_single_order?.order_status_id == 9 ? (
                      <Progress animated color="warning" value="90" />
                    ) : returned_single_order?.order_status_id == 10 ? (
                      <Progress animated color="warning" value="90" />
                    ) : (
                      <Progress value="0" />
                    )}
                    {/* <span>
                                    <Icon name="cart-fill"></Icon>
                                </span> */}
                  </div>
                  <div className="mt-2">
                    <Icon name="wallet" className="mr-1" style={{ color: "#8094ae" }}></Icon>{" "}
                    {returned_single_order?.payment_mode}
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {/* bill */}
          <Col md="8" className="mt-2">
            <Card className="">
              <CardBody className="card-inner">
                <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                  <div className="flex justify-between mt-1">
                    <div>
                      <Icon name="list" className="mr-1"></Icon> Bill Details
                    </div>
                    <div style={{ color: "#8094ae", fontSize: "12px" }}>
                      <Badge color="info">{returned_single_order?.unique_order_id}</Badge>
                    </div>
                  </div>
                </CardSubtitle>

                <CardText className="flex flex-col ml-2 pt-2">
                  <table className="table table-borderless mt-2  text-right">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th scope="col" className="text-left">
                          Product
                        </th>
                        <th scope="col" className="text-left">
                          Quantity
                        </th>
                        <th scope="col">Price</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {returned_single_order?.order_item ? (
                        <>
                          <tr>
                            <td></td>
                            <td className="text-left">{item?.name}</td>
                            <td className="text-left">
                              {item?.order_item_addons?.length > 0 &&
                              item?.order_item_addons?.filter((addon) => addon.addon_quantity > 0).length > 0
                                ? item?.order_item_addons?.reduce((total, addon) => total + addon.addon_quantity, 0)
                                : item?.quantity}
                            </td>
                            <td>{parseFloat(item?.price)?.toFixed(2)}</td>
                            <td>
                              {item?.order_item_addons?.filter((addon) => addon.addon_quantity > 0).length > 0
                                ? parseFloat(
                                    item?.order_item_addons?.reduce((total, addon) => total + addon.addon_quantity, 0) *
                                      parseFloat(item?.price)
                                  ).toFixed(2)
                                : parseFloat(item?.price * item?.quantity).toFixed(2)}
                            </td>
                            {/* <td>{parseFloat(item?.price * item?.quantity)?.toFixed(2)}</td> */}
                          </tr>
                          {item?.order_item_addons?.length > 0 ? (
                            <>
                              <tr>
                                <td colspan="1"></td>
                                <td colspan="4">
                                  <div className="nk-divider divider my-0"></div>
                                </td>
                              </tr>
                              <tr style={{ fontSize: "12px", color: "#526484", fontWeight: "550" }}>
                                <td></td>
                                <td className="text-left">Addons</td>
                                <td className="text-left">Quantity</td>
                                <td>Price</td>
                                <td>Total</td>
                              </tr>
                              <tr>
                                <td colspan="1"></td>
                                <td colspan="4">
                                  <div className="nk-divider divider my-0"></div>
                                </td>
                              </tr>
                              {item?.order_item_addons
                                ?.filter((addon) => addon.type === "MULTIPLE")
                                ?.map((addon, index) => (
                                  <>
                                    <tr style={{ fontSize: "12px" }}>
                                      <td></td>
                                      <td className="text-left">
                                        {addon?.addon_category_name} ({addon?.addon_name})
                                      </td>
                                      <td className="text-left">{addon?.addon_quantity}</td>
                                      <td>{addon.addon_price}</td>
                                      <td>{parseFloat(addon.addon_price * addon?.addon_quantity)?.toFixed(2)}</td>
                                    </tr>
                                  </>
                                ))}
                              {item?.order_item_addons
                                ?.filter((addon) => addon.type === "SINGLE")
                                ?.map((addon, index) => (
                                  <>
                                    {console.log(addon, "addonSINGLE")}
                                    <tr style={{ fontSize: "12px" }}>
                                      <td></td>
                                      <td className="text-left">
                                        {addon?.addon_category_name} ({addon?.addon_name})
                                      </td>
                                      <td className="text-left">{addon?.addon_quantity}</td>
                                      <td>{addon.addon_price}</td>
                                      <td>{parseFloat(addon.addon_price * addon?.addon_quantity)?.toFixed(2)}</td>
                                    </tr>
                                  </>
                                ))}
                              {item?.order_item_variants?.length > 0 ? (
                                <tr>
                                  <td colspan="1"></td>
                                  <td colspan="4">
                                    <div className="nk-divider divider my-0"></div>
                                  </td>
                                </tr>
                              ) : null}
                            </>
                          ) : null}
                          {item?.order_item_variants?.length > 0 ? (
                            <>
                              <tr>
                                <td colspan="1"></td>
                                <td colspan="4">
                                  <div className="nk-divider divider my-0"></div>
                                </td>
                              </tr>
                              <tr style={{ fontSize: "12px", color: "#526484", fontWeight: "550" }}>
                                <td></td>
                                <td className="text-left">Variants</td>
                                <td className="text-left">Quantity</td>
                                <td>Price</td>
                                <td>Total</td>
                              </tr>
                              <tr>
                                <td colspan="1"></td>
                                <td colspan="4">
                                  <div className="nk-divider divider my-0"></div>
                                </td>
                              </tr>
                              {item?.order_item_variants?.map((variant, index) => (
                                <>
                                  <tr style={{ fontSize: "12px" }}>
                                    <td></td>
                                    <td className="text-left">{variant?.variant_name}</td>
                                    <td className="text-left">{variant?.quantity}</td>
                                    <td>{variant.variant_price}</td>
                                    <td>
                                      {(parseFloat(variant?.quantity) * parseFloat(variant.variant_price)).toFixed(2)}
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </>
                          ) : null}
                          {/* {returned_single_order?.order_items.length - 1 == index ? null :
                                                <tr>
                                                    <td colspan='5'>
                                                        <div className="nk-divider divider my-0"></div>
                                                    </td>
                                                </tr>
                                            } */}
                        </>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                 
                  <div className="nk-divider divider sm"></div>
                  <div className="flex flex-row content-end justify-end items-end mx-1">
                          <div className="flex flex-col" style={{ fontSize: "13px" }}>
                            <div
                              className="text-right"
                              style={{ paddingRight: "3px", color: "#121212", fontWeight: "600", fontSize: "15px" }}
                            >
                              <label className="col-form-label py-0 pe-3">Returning amount : </label>
                            </div>
                          </div>

                          <div className="flex flex-col" style={{ fontSize: "13px", marginLeft: "5px" }}>
                            <div
                              className="text-right flex-grow-1 w-75px ps-2"
                              style={{ color: "#121212", fontWeight: "600", fontSize: "15px" }}
                            >
                              ₹{parseFloat(returned_single_order?.returning_amount)?.toFixed(2)}
                            </div>
                          </div>
                        </div>
                            
                </CardText>
              </CardBody>
            </Card>
          </Col>

          <Col md="4">
            {/* delivery guy */}
            {returned_single_order?.order_status_id == 15 || returned_single_order?.order_status_id == 14 ? null : (
              <Row className="mt-2">
                <Col md="12">
                  <Card className="">
                    <CardBody className="card-inner">
                      <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                        <Icon name="account-setting-alt" className="mr-1"></Icon>Assign Delivery Guy
                      </CardSubtitle>
                      <CardText className="flex flex-col ml-1 pt-2">
                        <div className="">
                          <RSelect
                            options={deliveryguys}
                            onChange={(e) => setDeliveryGuy(e.value)}
                            defaultValue={deliveryguys?.find(
                              (guy) => guy.value === returned_single_order?.accept_delivery?.delivery_guy_detail_id
                            )}
                          />
                        </div>
                        <div id="nodelivery" style={{ fontSize: "11px", color: "red", fontStyle: "italic" }}></div>
                        <div className="flex justify-end mt-2">
                          <div
                            className=""
                            style={
                              returned_single_order?.order_status_id == 15 || returned_single_order?.order_status_id == 14
                                ? { display: "none" }
                                : { display: "block" }
                            }
                          >
                            {deliveryUpdate && form_loading ? (
                              <>
                                <Button color="primary">
                                  <Spinner size="sm" color="light" />
                                  Updating
                                </Button>
                              </>
                            ) : (
                              <Button
                                color="primary"
                                onClick={() => {
                                  assignDeliveryGuy();
                                }}
                              >
                                <Icon name="folder-check" className="mr-1" />
                                Update
                              </Button>
                            )}
                          </div>
                        </div>
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {/* timeline */}
            <Row className="mt-2">
              <Col md="12">
                <Card className="">
                  <CardBody className="card-inner">
                    <CardSubtitle style={{ color: "#526484", fontWeight: "500" }} tag="h6" className="mb-2 ff-base">
                      <Icon name="truck" className="mr-1"></Icon> Order Activity
                    </CardSubtitle>
                    <CardText className="flex flex-col items-center ml-1">
                      <div className="flex justify-between">
                        {returned_single_order?.order_status_id == 12 ? (
                          <Button color="success" outline onClick={() => acceptTheOrder()}>
                            {!deliveryUpdate && form_loading ? (
                              <Spinner size="sm" color="success" />
                            ) : (
                              <Icon name="check-circle-fill" />
                            )}
                            Accept
                          </Button>
                        ) : [13, 17]?.includes(returned_single_order?.order_status_id) ? (
                          <Button color="success" outline onClick={() => changeStatustoPickUp()}>
                            {!deliveryUpdate && form_loading ? (
                              <Spinner size="sm" color="success" />
                            ) : (
                              <Icon name="bell" />
                            )}
                            Ready to Pick
                          </Button>
                        ) : [18, 19]?.includes(returned_single_order?.order_status_id) ? (
                          <Button color="success" outline onClick={() => completeTheOrder()}>
                            {!deliveryUpdate && form_loading ? (
                              <Spinner size="sm" color="success" />
                            ) : (
                              <Icon name="list-check" />
                            )}
                            Complete
                          </Button>
                        ) : (
                          ""
                        )}
                        {/* {console.log(deliveryguys?.find((guy) => guy.value === single_order?.accept_delivery?.delivery_guy_detail_id),'dddd')} */}
                        {deliveryguys?.find(
                          (guy) => guy.value === returned_single_order?.accept_delivery?.delivery_guy_detail_id
                        ) != undefined && returned_single_order?.order_status_id == 13 ? (
                          <Button color="success" outline onClick={() => acceptTheOrder()}>
                            {!deliveryUpdate && form_loading ? (
                              <Spinner size="sm" color="success" />
                            ) : (
                              <Icon name="check-circle-fill" />
                            )}
                            Accept
                          </Button>
                        ) : null}
                        <div
                          className=""
                          style={
                            returned_single_order?.order_status_id == 15 || returned_single_order?.order_status_id == 14
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <Button color="danger" outline onClick={() => openCancelModal()}>
                            <Icon name="cross-circle-fill" />
                            Cancel
                          </Button>
                        </div>
                      </div>
                      <div className="card-inner">
                        <div className="timeline">
                          <ul className="timeline-list">
                            {order_log?.length > 0 &&
                              order_log?.map((log, key) => {
                                return (
                                  <li className="timeline-item" key={log?.id}>
                                    <div
                                      className={
                                        key == order_log.length - 1
                                          ? "timeline-status bg-primary is-outline"
                                          : "timeline-status bg-primary"
                                      }
                                    ></div>
                                    <div className="timeline-date">
                                      {moment(log?.created_at).format("DD-MMM")}
                                      <Icon name="alarm-alt"></Icon>
                                    </div>
                                    <div className="timeline-data">
                                      <h6 className="timeline-title">{log?.description}</h6>
                                      <div className="timeline-des">
                                        <span className="time">{moment(log?.created_at).format("hh:mm A")}</span>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      {/* order status updating modal */}
      <Modal
        isOpen={modal.cancel}
        toggle={() => setModal({ cancel: false })}
        className="modal-dialog-centered"
        size="md"
      >
        <ModalBody className="pt-3 pb-2">
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">
              Cancel Order <Badge color="outline-primary">{returned_single_order?.unique_order_id}</Badge> ?
            </h5>
            <Col md="12">
              <FormGroup>
                <label className="form-label">Cancellation Reason</label>
                <div className="form-control-wrap">
                  <RSelect
                    options={canceloptions}
                    defaultValue={canceloptions?.find((opt) => opt.value === cancelData?.reason)}
                    onChange={(val) => {
                      handleInput(val.value);
                    }}
                  />
                  <div className="mt-2" id="exmple123" style={cancelInput ? { display: "block" } : { display: "none" }}>
                    <Input
                      type="text"
                      name="other_reason"
                      placeholder="Enter reason here..."
                      onChange={(e) => {
                        setCancelData({ ...cancelData, other_reason: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>
            <div className="mt-3">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                <li>
                  <Button
                    type="button"
                    color="danger"
                    className="px-4 btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      cancelTheOrder();
                    }}
                  >
                    {form_loading ? (
                      <>
                        <Spinner size="sm" color="light" />
                        Cancelling Order
                      </>
                    ) : (
                      <React.Fragment>Cancel Order</React.Fragment>
                    )}
                  </Button>
                </li>
                <li>
                  <a
                    href="#cancel"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onFormCancel();
                    }}
                    className="link link-light"
                  >
                    Cancel
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default Singleorderreturned;
