import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Row, UncontrolledDropdown } from 'reactstrap'
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable, RSelect } from '../../../../components/Component'
import { WEBSITE_API_URL } from '../../../../config'
import ToastExample from '../../../../helpers/toast'
import Content from '../../../../layout/content/Content'
import Head from '../../../../layout/head/Head'
import moment from 'moment'
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { collectDeliveryCollection, getAllDeliveryCollection, } from '../../../../redux/admin/report/action'
import { nonActiveFormSubmissionStatus } from '../../../../redux/common/actions'
import DatePicker from "react-datepicker";
import subDays from "date-fns/subDays";
import { Controller, useForm } from 'react-hook-form'
import { string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'



const DeliveryCollections = () => {
    const dispatch = useDispatch();
    // get data from redux state
    const getSelectors = (state) => ({
        collections: state.reports.delivery_collections,
    });
    const { collections } = useSelector(getSelectors);
    const [modal, setModal] = useState(false);
    const [currentCollection, setCurrentCollection] = useState([]);
    const [message, setMessage] = useState("" ?? null);
    const [cityId, setCityId] = useState("All");
    const [error, setError] = useState("" ?? null);
    const [amount, setAmount] = useState(0);
    const [startDate, setStartDate] = useState(subDays(new Date(), 30) ?? []);
    const [endDate, setEndDate] = useState(new Date() ?? []);
    const formatedStart = moment(startDate).format('DD-MM-yyyy' ?? null);
    const formatedEnd = moment(endDate).format('DD-MM-yyyy' ?? null);

    useEffect(() => {
        dispatch(getAllDeliveryCollection(cityId,formatedStart, formatedEnd));
    }, [])

    // delivery collection modal
    const openOrderModal = (delivery_guy) => {
        setCurrentCollection(delivery_guy)
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setAmount(calculateCollectionAmount(delivery_guy?.delivery_collections));
            setModal(true);
        }, 100);
    };

    // on date change
    const handleDatePicker = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    // filter data according to date picker

    const filterPayout = () => {
        if (cityId) {
            setTimeout(() => {
                dispatch(getAllDeliveryCollection(cityId, formatedStart, formatedEnd));
            }, 100);
        } else {
            setError("City is Required");
        }
    }

    // export delivery collection report
    const onExport = () => {
        const token = localStorage.getItem('accessToken');
        if (cityId) {
            window.location = WEBSITE_API_URL + "/delivery-collection/export?token=" + token + "&from=" + formatedStart + "&to=" + formatedEnd + "&city=" + cityId;
        } else {
            setError("City is Required");
        }
    }

    // handle message input
    const handleMessage = (message) => {
        setMessage(message);
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        //  data to be submitted to release payout
        dispatch(collectDeliveryCollection(currentCollection?.id, formatedStart, formatedEnd, message)).then((response) => {
            setModal(false);
            dispatch(getAllDeliveryCollection(cityId));
        })
    }

    // function to filter user delivery collection relation according to date and status
    //  return the total collection amount to datatable cash on hand column

    const calculateCollectionAmount = (delivery) => {
        const formatedStart = moment(startDate);
        const formatedEnd = moment(endDate);
        const deliveryCollection = delivery?.filter((value) => {
            return moment(value.created_at) >= formatedStart && moment(value.created_at) <= formatedEnd && value.status == 0;
        })
        console.log(deliveryCollection);
        let amount = 0;
        deliveryCollection.forEach(value => {
            amount += value?.amount;
        });
        return amount;
    }
    // form validation schema
    const exportSchema = yup.object({
        status: string().required(),
        city_id: string().required(),
    });

    // schema to edit form data
    const defaultValues = useMemo(() => ({
        status: "",
        city_id: "",
    }), []);

    const methods = useForm({
        resolver: yupResolver(exportSchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    //headers of table
    const UserHeading = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            hide: 370,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            hide: 370,
        },
        {
            name: "Phone",
            selector: (row) => row.phone,
            sortable: true,
            hide: 370,
        },
        {
            name: "Cash On Hand",
            selector: (row) => calculateCollectionAmount(row?.delivery_collections),
            sortable: true,
            hide: "sm",

        },
        {
            name: "Actions",
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans row={row} />
                </div>
            ),
            sortable: false,
        },
    ];

    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a" onClick={(e) => { openOrderModal(row) }}>
                                <Icon name="download"></Icon>
                                Delivery Collection
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem tag="a" href={'/single-user-delivery-collection-logs/' + row.id}>
                                <Icon name="eye-fill" />
                                View Logs
                            </DropdownItem>
                        </li>
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    const listCity = collections?.cities?.map((city) => (
        { value: city.id, label: city.name }
    ));
    return (
        <React.Fragment>
            <Head title="Delivery Collection "></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Delivery Collection
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {collections?.count} Delivery Collection .</p>
                                <span className=''>
                                    Total COD Pending :
                                    <Badge pill color="info" className='mx-2'>{collections?.codCollectionPendingCount}</Badge>
                                </span>
                                <span className='mx-3'>
                                    Total Amount Pending :
                                    <Badge pill color="info" className='mx-2'> ₹ {collections?.pendingAmount}</Badge>
                                </span>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <Row className='mb-3'>
                        <Col md="4">
                            <span className="form-label">Choose Date Range</span>
                            <DatePicker
                                style={{ zIndex: "999999999" }}
                                selected={startDate}
                                onChange={handleDatePicker}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                className="form-control date-picker"
                                required
                            />
                        </Col>
                        <Col md="4">
                            <span className="form-label">Select a City</span>
                            <Controller
                                name="city_id"
                                control={control}
                                render={({ onChange, value, ref }) => (
                                    <RSelect
                                        options={listCity}
                                        onChange={(val) => {
                                            setCityId(val?.value)
                                            onChange(val?.value)
                                        }}
                                        defaultValue={listCity?.find((c) => c?.value === cityId)}
                                    />
                                )}
                                ref={register({ required: "This field is required" })}
                            />
                            {!cityId && (<span className="invalid text-danger text-italic">{error}</span>)}
                        </Col>
                        <Col md="4" className='d-flex justify-content-end align-items-end mt-2'>
                            <div >
                                <Button outline color="success" className='mr-3' onClick={() => { filterPayout() }}>
                                    <span>Filter</span>
                                </Button>
                                <Button outline color="primary" className='' onClick={() => { onExport() }}>
                                    <Icon name="download-cloud"></Icon>
                                    <span>Export</span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Block>
                {/* datatable */}
                <ReactDataTable
                    data={collections?.users}
                    columns={UserHeading}
                    pagination
                />
            </Content>


            {/* view collection release modal */}
            <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody className="pt-3 pb-2">
                    <a href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false)
                        }}
                        className="close" >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className='p-2'>
                        <h5 className="title">Cash on Hand : ₹ {amount}</h5>
                        <Form className="gy-4 pt-4" onSubmit={(e) => { onFormSubmit(e) }}>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Message</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="message"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                handleMessage(e.target.value);
                                            }}
                                            placeholder="Enter Message"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" className='d-flex justify-content-end align-items-end '>
                                    <Button type="submit" color="success" size="lg" className="btn-dim">
                                        Collect
                                    </Button>
                                </Col>
                            </Row>

                        </Form>

                    </div>

                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default DeliveryCollections
