import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Button, Col, Form, FormGroup, Row } from 'reactstrap'
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, PreviewCard, RSelect } from '../../../../../components/Component'
import ToastExample from '../../../../../helpers/toast'
import Content from '../../../../../layout/content/Content'
import Head from '../../../../../layout/head/Head'
import * as yup from "yup";
import { string } from "yup";
import { openFormLoading } from '../../../../../redux/common/actions'
import { getSingleUser, updateBankDetails, updateDeliveryGuyDetails } from '../../../../../redux/admin/user/action'
import { getAllWarehouses } from '../../../../../redux/admin/warehouse/action'
import { WEBSITE_BASE_URL } from '../../../../../config'


function DeliverGuy(user_id) {
    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        user: state.users.single_user,
        cities: state.cities.cities,
        warehouse: state.warehouse.warehouse,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });

    const { form_loading, form_submission_status, user, cities, warehouse } = useSelector(getSelectors);
    const [showToast, setShowToast] = useState(false)
    const [deliveryDetails, setDeliveryDetails] = useState(user.delivery_guy_detail)
    const [cityId, setCityId] = useState(user?.delivery_guy_detail?.city_id ?? null);
    // form validation schema
    const deliveryGuySchema = yup.object({
        nick_name: string().required(),
        age: yup.number().required(),
        city_id: string().required(),
        warehouse_id: string().required(),
        gender: string().required(),
        description: string().required(),
        most_accept_delivery_limit: string().required(),
        license_number: string().required(),
        aadhar_number: string().required(),
        license: yup.mixed()
            .test("fileSize", "File too large", (value) => {

                if (value[0]) {
                    return value[0].size <= 200 * 1024;
                }
                return true;
            })
            .test("fileFormat", "Unsupported format", (value) => {
                if (value[0]) {
                    return ["image/jpeg", "image/png", "application/pdf"].includes(value[0].type);
                }
                return true;
            }).notRequired(),
        id_proof: yup.mixed()
            .test("fileSize", "File too large", (value) => {

                if (value[0]) {
                    return value[0].size <= 200 * 1024;
                }
                return true;
            })
            .test("fileFormat", "Unsupported format", (value) => {
                if (value[0]) {
                    return ["image/jpeg", "image/png"].includes(value[0].type);
                }
                return true;
            }).notRequired(),
    });

    useEffect(() => {
        dispatch(getSingleUser(user_id))
        const form_data = new FormData();
        form_data.append('token', localStorage.getItem("accessToken"));
        dispatch(getAllWarehouses(form_data))
    }, [])


    // schema to edit form data
    const defaultValues = useMemo(
        () => ({
            nick_name: deliveryDetails?.nick_name || "",
            age: deliveryDetails?.age || "",
            city_id: deliveryDetails?.city_id || "",
            warehouse_id: deliveryDetails?.warehouse_id || "",
            gender: deliveryDetails?.gender || "",
            description: deliveryDetails?.description || "",
            most_accept_delivery_limit: deliveryDetails?.most_accept_delivery_limit || "",
            license_number: deliveryDetails?.license_number || "",
            aadhar_number: deliveryDetails?.aadhar_number || "",
            licence: "",
            id_proof: "",
        }),
        [deliveryDetails]
    );

    const methods = useForm({
        resolver: yupResolver(deliveryGuySchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    const onFormSubmit = (submitData) => {

        dispatch(openFormLoading());
        const form_data = new FormData();
        form_data.append('id', deliveryDetails?.id);
        form_data.append('user_id', user?.id);
        form_data.append('nick_name', submitData?.nick_name);
        form_data.append('age', submitData?.age);
        form_data.append('city_id', submitData?.city_id);
        form_data.append('warehouse_id', submitData?.warehouse_id);
        form_data.append('gender', submitData?.gender);
        form_data.append('description', submitData?.description);
        form_data.append('most_accept_delivery_limit', submitData?.most_accept_delivery_limit);
        form_data.append('license_number', submitData?.license_number);
        form_data.append('aadhar_number', submitData?.aadhar_number);
        if (submitData?.license?.length) {
            form_data.append('license', submitData?.license[0]);
        }
        if (submitData?.id_proof?.length) {
            form_data.append('id_proof', submitData?.id_proof[0]);
        }
        dispatch(updateDeliveryGuyDetails(form_data)).then((response) => {
            if (response.payload.success) {
                setTimeout(() => {
                    setShowToast(true);
                    dispatch(getSingleUser(user.id))
                }, 500)
                setTimeout(() => {
                    setShowToast(false);
                }, 3000)
            }
        })
    }

    const listCity = cities?.map((city) => (
        { value: city.id, label: city.name }
    ));

    const listWarehouse =
        cityId ? warehouse?.filter(cp => cp.city_id == cityId).map((warehouse) => (
            { value: warehouse.id, label: warehouse.name }
        )) : warehouse?.map((warehouse) => (
            { value: warehouse.id, label: warehouse.name }
        ));

    const gender = [
        { value: "MALE", label: "MALE" },
        { value: "FEMALE", label: "FEMALE" },
    ];
    return (
        <React.Fragment>
            <Head title="Delivery Guy"></Head>

            <Block size="lg">
                <BlockBetween>


                    <BlockHeadContent>
                        <BlockTitle tag="h4">Delivery Guy</BlockTitle>

                    </BlockHeadContent>
                    <BlockHeadContent>

                        <ToastExample show={showToast} />
                    </BlockHeadContent>

                </BlockBetween>
            </Block>
            <Block>
                <div className="nk-data data-list">
                    <div className="data-head">
                        <h6 className="overline-title">Delivery Guy Details</h6>
                    </div>
                    <Form className="gy-3" onSubmit={handleSubmit(onFormSubmit)}>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Nick Name</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="nick_name"
                                            placeholder="Enter Nick Name"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.nick_name && <span className="invalid">{errors.nick_name.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Age</label>

                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="age"
                                            placeholder="Enter Age"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.age && <span className="invalid">{errors.age.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">City</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <Controller
                                            name="city_id"
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <RSelect
                                                    options={listCity}
                                                    onChange={(val) => {
                                                        onChange(val.value)
                                                        setCityId(val.value)
                                                    }}
                                                    defaultValue={listCity.find((c) => c.value === value)}
                                                />
                                            )}
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.city_id && (
                                            <span className="invalid text-danger text-italic">Please choose a city</span>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Warehouse</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <Controller
                                            name="warehouse_id"
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <RSelect
                                                    options={listWarehouse}
                                                    onChange={(val) => onChange(val.value)}
                                                    value={listWarehouse.find((c) => c.value === cityId)}
                                                    defaultValue={listWarehouse.find((c) => c.value === cityId)}
                                                />
                                            )}
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.warehouse_id && (
                                            <span className="invalid text-danger text-italic">Please choose a Warehouse</span>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Gender</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <Controller
                                            name="gender"
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <RSelect
                                                    options={gender}
                                                    onChange={(val) => onChange(val.value)}
                                                    defaultValue={gender.find((c) => c.value === value)}
                                                />
                                            )}
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.gender && (
                                            <span className="invalid text-danger text-italic">Please select Your Gender</span>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Description</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="description"
                                            placeholder="Enter a description"
                                            ref={register()}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Delivery Limit</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            className="form-control"
                                            type="number"
                                            min="0"
                                            name="most_accept_delivery_limit"
                                            placeholder="Enter Limit"
                                            ref={register({ required: "This field is required" })}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Licence Number</label>

                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="license_number"
                                            ref={register({ required: "This field is required" })}
                                            placeholder="Enter Licence Number"
                                        />
                                        {errors.license_number && <span className="invalid">{errors.license_number.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Adhaar Number</label>

                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            className="form-control"
                                            type="text"
                                            minLength="12"
                                            maxLength="12"
                                            name="aadhar_number"
                                            ref={register({ required: "This field is required" })}
                                            placeholder="Enter Adhaar Number"
                                        />
                                        {errors.aadhar_number && <span className="invalid">{errors.aadhar_number.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">License Doc/Image</label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            className="form-control"
                                            type="file"
                                            name="license"

                                            ref={register({ required: false })}

                                        />
                                        {errors.license && <span className="invalid">{errors.license.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        {deliveryDetails?.license ?
                                            <img src={WEBSITE_BASE_URL + deliveryDetails?.license} className="rounded" />
                                            : null}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">ID Proof</label>

                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <input
                                            className="form-control"
                                            type="file"
                                            name="id_proof"
                                            ref={register({ required: false })}
                                        />
                                        {errors.id_proof && <span className="invalid">{errors.id_proof.message}</span>}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row className="g-3">
                            <Col lg="7" className="offset-lg-5">
                                <FormGroup className="mt-2">
                                    <Button type='submit' color="primary" size="lg" >

                                        Update
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default DeliverGuy
