import React from 'react'
import { useDispatch } from 'react-redux';
import { UPDATE_SIDE_BAR_PERMISSION_URL } from '../../../../config';
import axiosClient from '../../../../helpers/axios-client';
import { getAllPermission, togglePermission } from '../../../../redux/admin/permission/action';

function ToggleButton(props) {
    const dispatch = useDispatch();
    const { role, permission } = props;
    // to handle status
    const toggle = (e, id, p_id) => {
        if (hasPermission) {
            const remove = "REMOVE"
            dispatch(togglePermission(id, p_id, remove));

        } else {
            const add = "ADD"
            dispatch(togglePermission(id, p_id, add));
        }
        setTimeout(() => {
            dispatch(getAllPermission());
        }, 500);

        axiosClient.post(UPDATE_SIDE_BAR_PERMISSION_URL, localStorage.getItem("accessToken"))
            .then((data) => {
                if (data && data.status == 200) {
                    if (data?.data && data?.data.success) {
                        localStorage.setItem("permissions", JSON.stringify(data?.data?.permissions));
                        window.location.reload();
                    } else {
                    }
                    console.log(data?.data);
                }
            })
            .catch((err) => {
                const response = err.response;
            });
    };

    const hasPermission = role?.permissions?.find((val) => val.id == permission?.id)
    return (
        <React.Fragment>
            <div className="custom-control custom-switch" onClick={(e) => toggle(e, role?.id, permission?.id)}>
                <input type="checkbox" className="custom-control-input" checked={hasPermission ? true : false} id={"customSwitch" + role?.id + "_" + permission?.id} title={role.name} />
                <label className="custom-control-label" htmlFor={"customSwitch" + role?.id + "_" + permission?.id} />
            </div>

        </React.Fragment>
    )
}

export default ToggleButton
