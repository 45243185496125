import React from 'react'
import { Card } from "reactstrap";
import { DataTableHead, DataTableRow, DataTableItem, UserAvatar } from '../../../../components/Component';
import moment from 'moment'
import { WEBSITE_BASE_URL } from '../../../../config';

function RecentLimitzoOrders({ recent_orders }) {

    return (
        <Card className="card-full">
            <div className="card-inner">
                <div className="card-title-group">
                    <div className="card-title">
                        <h6 className="title">Recent Orders</h6>
                    </div>
                </div>
            </div>
            <div className="nk-tb-list mt-n2">
                <DataTableHead>
                    <DataTableRow>
                        <span>Order No.</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                        <span>Customer</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                        <span>Date</span>
                    </DataTableRow>
                    <DataTableRow>
                        <span>Amount</span>
                    </DataTableRow>
                    <DataTableRow>
                        <span className="d-none d-sm-inline">Status</span>
                    </DataTableRow>
                </DataTableHead>
                {recent_orders.length > 0 ? recent_orders.map((order, idx) => (
                    <DataTableItem key={idx}>
                        <DataTableRow>
                            <span className="tb-lead">
                                <a href={"/view-order/" + order?.id}>
                                    #{order?.unique_order_id}
                                </a>
                            </span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                            <div className="user-card">
                                <UserAvatar className="sm" theme='primary-dim' text={order?.user?.name?.charAt(0)?.toUpperCase()} ></UserAvatar>
                                <div className="user-name">
                                    <span className="tb-lead">{order?.user?.name}</span>
                                </div>
                            </div>
                        </DataTableRow>
                        <DataTableRow size="md">
                            <span className="tb-sub"> {moment(order?.created_at).format("DD/MM/YYYY")}</span>
                        </DataTableRow>
                        <DataTableRow>
                            <span className="tb-sub tb-amount">
                                <span>INR</span>{" "} {order?.total}
                            </span>
                        </DataTableRow>
                        <DataTableRow>
                            <span
                                className={`badge badge-dot badge-dot-xs badge-${order?.order_status_id === 7 ? "success" : order?.order_status_id === 8 ? "danger" : "warning"
                                    }`}
                            >
                                {order?.order_status?.name}
                            </span>
                        </DataTableRow>
                    </DataTableItem>
                )) :
                    <DataTableItem>
                        <DataTableRow>
                            --
                        </DataTableRow>
                        <DataTableRow size="sm">
                            --
                        </DataTableRow>
                        <DataTableRow size="md">
                            --
                        </DataTableRow>
                        <DataTableRow>
                            --
                        </DataTableRow>
                        <DataTableRow>
                            --
                        </DataTableRow>
                    </DataTableItem>
                }
            </div>
        </Card>
    )
}

export default RecentLimitzoOrders
