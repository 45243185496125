import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  RSelect,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import { Button, Card, CardTitle, Col, Form, FormGroup, Modal, ModalBody, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllCities } from "../../../redux/admin/cities/actions";
import { getAllUsers, getSingleUser, getUserForCreateOrder, userRegister } from "../../../redux/admin/user/action";
import DisplayPage from "../../components/UtilDisplay";
import CreateOrdersMap from "./create-orders-map";
import { addToDisplayItems, getAllWarehouses, getWarehouseItems } from "../../../redux/admin/warehouse/action";
import { addItemToSelectedItems, addToCart, removeFromCart } from "../../../redux/admin/cart/action";
import { BiMinus, BiPlus } from "react-icons/bi";
import Cart from "./cart";
import { getAllItems } from "../../../redux/admin/item/action";
import DisplayItems from "./DisplayItems";
import { Toaster, toast } from "sonner";
// import { useForm } from "react-hook-form";

// import { Form, FormControl, InputGroup } from "react-bootstrap";

const CreateOrders = () => {
  const dispatch = useDispatch();
  // const { register, handleSubmit, errors } = useForm();

  const cartProducts = useSelector((state) => state.cart);
  // const warehouse = useSelector((state)=>state.warehouse);

  console.log("orderitemssssssssss", cartProducts?.orderitems);

  

  const [selectedItems, setSelectedItems] = useState([]); // Array to store selected items
  const [selectedItemsData, setSelectedItemsData] = useState([]); // Array to store selected items
  const [formData, setFormData] = useState([]); //form data state defined
  const [submitted, setSubmitted] = useState(false);
  const [namesubmitted, setNameSubmitted] = useState(false);
  const [nameFiledOpen, setNameFieldOpen] = useState(false);

  console.log("selectedItemsData", selectedItemsData);

  const [wareHouseId, setWareHouseId] = useState("All"); //isMultiple state defined
  const [addItemListing, setAddItemsListing] = useState(false); //isMultiple state defined

  const [selectedItemValue, setSelectedItemValue] = useState(null);

  const [selectedItemDetails, setSelectedItemDetails] = useState(null);


  const [userdetail, setUserdetails] = useState({
    phone: "",
    name: "",
  });
  const [phone, setPhone] = useState([]);
  const [detail, setDetails] = useState([]);
  const [phonecheck, setPhonecheck] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);

  console.log("userdetail", userdetail);
  console.log("phone", phone);
  const [loc, setloc] = useState(false);

  const [selectedWarehouse, setSelectedWarehouse] = useState(null); // State to store selected warehouse

  const userss = useSelector((state) => state.users.all_users);
  console.log("All users", userss);

  const _submitphone = () => {
    // e.preventDefault();
    // Check if phone is not empty or null

    if (phone) {
      const formData = new FormData();
      formData.append("phone", phone);
      dispatch(getUserForCreateOrder(formData)).then((response) => {
        if (response?.payload?.success) {
          setDetails(response?.payload?.user);
          console.log("response?.payload?.user", response?.payload?.user);
          setPhonecheck(true);

          localStorage.setItem("customer_id", response.payload?.user?.id ?? "");
          localStorage.setItem("auth_token", response.payload?.user?.auth_token ?? "");
          localStorage.setItem("customer_phone", response.payload?.user?.phone ?? "");
          localStorage.setItem("customer_name", response.payload?.user?.name ?? "");
        } else {
          setPhonecheck(true);
        }
      });
    } else {
     
    }
  };

  const handleNameChange = (e) => {
    const data = e.target.value;
    setUserdetails({ ...userdetail, name: data });
    // alert("success!!");
  };



  const _userRegister = (e) => {
    // e.preventDefault();
    const formData = new FormData();
    formData.append("phone", userdetail.phone);
    formData.append("name", userdetail.name);
    dispatch(userRegister(formData)).then((response) => {
      // console.log("response", response);
      if (response?.payload?.success) {
        setDetails(response?.payload?.data);

        localStorage.setItem("customer_id", response?.payload?.data?.user?.id ?
         response?.payload?.data?.user?.id : "");
        localStorage.setItem("customer_phone", response.payload?.data?.user?.phone ? 
         response.payload?.data?.user?.phone : "" );
        localStorage.setItem("customer_name", response.payload?.data?.user?.name ?
         response.payload?.data?.user?.name : "");
        // alert("user registered successfully");
        toast.success("user registered succesfully");
      } else {
        toast("something went wrong");
      }
    });
  };

  const handleWarehouseItems = (val) => {
    setSelectedWarehouse(val);
    setWareHouseId(val.value);
    const formData = new FormData();
    formData.append("id", val.value);
    dispatch(getWarehouseItems(formData)).then((response) => {
      console.log("response12345", response);
      
    });

    console.log("val", val);

    const filteredItems = listWareHouse_items.filter((item) => item.value === val.value);
    // // setListWareHouseItems(filteredItems);
    // console.log("filteredItems", filteredItems);
  };



  useEffect(() => {
    const phone = localStorage.getItem("customer_phone");
    const name = localStorage.getItem("customer_name");
    if (phone && name) {
      setUserdetails({
        ...userdetail,
        phone: phone,
        name: name,
      });
    }
  }, [detail]);

  useEffect(() => {
    const address = localStorage.getItem("address");
    if (address) {
      setloc(true);
    }
  }, []);

  // useEffect(() => {
  //   dispatch(getAllCities());
  // }, [wareHouseId]);

  useEffect(() => {
    dispatch(getAllWarehouses());
    dispatch(getAllItems());
  }, [wareHouseId]);


  const addItems = (e) => {
    e.preventDefault();
    const selectedItem = warehouse_items.find((wh) => wh.id === selectedItemValue.value);
    console.log("selectedItem-id", selectedItem);

    // Check if the item already exists in display items
    const itemExists = display_items.some((item) => item.id === selectedItem.id);
    if (itemExists) {
      console.log("Item already exists in display items. Skipping addition.");
      // alert("item is allready added")
      toast("item is allready added");

      return; // Exit the function without adding the item again
    }

    setSelectedItemsData(selectedItem);
    let newData = { ...selectedItem, quantity: 1 };

    dispatch(addToDisplayItems(newData));
    toast.success("A new item added succesfully");
  };

  const getSelectors = (state) => ({
    live_orders: state.order.live_orders,
    cities: state.cities.cities,
    warehouse: state.warehouse.warehouse,
    warehouse_items: state.warehouse.warehouse_items,
    items: state.item.items,
    display_items: state.warehouse.display_items,
  });

  const { live_orders, cities, warehouse, warehouse_items, items, display_items } = useSelector(getSelectors);
  console.log("display_items  new", display_items);
  const listWareHouse = warehouse?.map((wh) => ({ value: wh.id, label: wh.name }));
  const listWareHouse_items = warehouse_items?.map((whi) => ({ value: whi.id, label: whi.name }));

  console.log("warehouse_items", warehouse_items);
  console.log("listWareHouse_items", listWareHouse_items);

  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log("handlePhoneSubmit");
    if (!userdetail.phone) {
      return; // Exit the function early if phone number is missing
    } else {
      // Your form submission logic goes here
      _submitphone(); // Assuming this function handles phone submission
    }
  };

  

  const handleNameSubmit = (e) => {
    e.preventDefault();
    console.log("handleNameSubmit");
    setNameSubmitted(true);
    const trimmedName = userdetail?.name.trim(); 

    if (trimmedName) {
      _userRegister();
    }
    else{
      toast("Please enter a valid name.");

    }
    
  };

  const [modal, setModal] = useState({
    map: false,
  });

  const openEditModal = () => {
    setModal({ map: true });
  };

  //to close modal
  const onFormCancel = () => {
    setModal({ map: false });
    document.getElementsByClassName("invalid").innerHTML = "";
    // resetForm();
  };

  return (
    <React.Fragment>
      <Head title="Create Orders"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Create Orders
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Form className="row gy-4 " encType="multipart/formData" onSubmit={addItems}>
          {/* onSubmit={(e) => _userRegister(e)}  */}
          {/* onSubmit={handleSubmit(_submitphone)} */}
          <Col md="6">
            <FormGroup>
              <label className="form-label">User phone Number </label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter phone number"
                required
                minLength="10"
                maxLength="10"
                name="phone"
                value={userdetail?.phone ?? phone}
                onChange={(e) => {
                  let data = e.target.value;
                  setPhone(data);
                  setUserdetails({ ...userdetail, phone: data }); 
                  setDetails([]);
                }}
                // ref={register({ required: "This field is required" })}
              />
              {/* {errors.phone && <span className="invalid">{errors.phone.message}</span>} */}
              {!userdetail.phone && submitted && (
                <div style={{ color: "red" }} className="text-sm text-red-600">
                  This field is required
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="6">
            <div className="mt-4 ml-3">
              <div className="pt-1">
                <Button
                  className="btn-icon py-1 px-2 btn-dim"
                  color="primary"
                  size="rg"
                  // onClick={(e) => _submitphone(e)}
                  // style={{ pointerEvents: "none" }}
                  onClick={handlePhoneSubmit}
                >
                  Check Number
                </Button>
              </div>
            </div>
          </Col>

          {phonecheck  && userdetail?.phone && (
            <>
              <Col md="6">
                <FormGroup>
                  <label className="form-label">Name of the User </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter User Name"
                    required
                    name=""
                    value={detail?.name ? detail?.name : userdetail?.name}
                    onChange={handleNameChange}
                    // ref={register({ required: "This field is required" })}
                  />
                  {/* {errors.phone && <span className="invalid">{errors.phone.message}</span>} */}
                  {!detail?.name && !userdetail?.name && namesubmitted && (
                    <div style={{ color: "red" }} className="text-sm text-red-600">
                      This field is required
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <div className="mt-4 ml-3">
                  <div className="pt-1">
                    {!detail?.name && (
                      <Button
                        className="btn-icon py-1 px-2 btn-dim"
                        color="primary"
                        size="rg"
                        onClick={handleNameSubmit}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </>
          )}

          <Col md="6">
            <label className="form-label">Set Delivery Address </label>
            <FormGroup>
              <input
                className="form-control"
                type="text"
                placeholder={
                  localStorage.getItem("address") ? localStorage.getItem("address") 
                  : "Select Customer Location..."
                }
                required
                name=""
                // value={detail?.name ? detail?.name : userdetail?.name}
                // onChange={handleNameChange}
                value={
                  localStorage.getItem("address") ? localStorage.getItem("address") : "Select Customer Location..."
                }
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <div className="mt-4 ml-3">
              <div className="pt-1">
                <Button
                  className="btn-icon py-1 px-2 btn-dim"
                  color="primary"
                  size="rg"
                  onClick={() => openEditModal()}
                >
                  Address
                </Button>
              </div>
            </div>
          </Col>

          <Col md="5">
            <label className="form-label">Select Warehouse </label>
            <FormGroup>
              <div className="flex-grow-1">
                <RSelect
                  options={listWareHouse}
                  onChange={(val) => {
                    // Call the custom handler function to update the state
                    handleWarehouseItems(val);
                    // Update the state directly if needed
                    // setWareHouseId(val.value);
                  }}
                  defaultValue={  listWareHouse && listWareHouse?.find((wh) => wh.value === "All")}
                />
              </div>
            </FormGroup>
          </Col>
          {   selectedWarehouse  && (
            <>
              <Col md="5">
                <label className="form-label">Items</label>
                <FormGroup>
                  <RSelect
                    options={listWareHouse_items}
                    onChange={(val) => setSelectedItemValue(val)}
                    // defaultValue={null}
                  />
                </FormGroup>
              </Col>

              <Col md="2">
                <div className="mt-4 ml-3">
                  <div className="pt-1">
                    <Button
                      className="btn-icon py-1 px-2 btn-dim"
                      color="primary"
                      size="rg"
                      // onClick={() => openEditModal()}
                    >
                      Add Item
                    </Button>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Form>

        <Toaster position="top-right" richColors />


        {display_items?.length > 0 && (
          <BlockHead size="sm">
            <BlockHeadContent>
              <Col md="6">
                {/* <Cart  items={items}/> */}
                <DisplayItems items={display_items} />
              </Col>
            </BlockHeadContent>
          </BlockHead>
        )}

      </Content>

      <Modal isOpen={modal.map} 
      toggle={() => setModal({ map: false })}
       className="modal-dialog-centered" size="lg">
        <ModalBody className="pt-3 pb-2">
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="closeInMap"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <div className="mt-2">
              <CreateOrdersMap />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CreateOrders;
