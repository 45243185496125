import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from '../../../../../components/Component';
import Head from '../../../../../layout/head/Head';
import { getAllUserOrder } from '../../../../../redux/admin/user/action';
import moment from 'moment';
import noDocument from '../../../../../assets/no-document.svg'

function UserOrder(user_id) {

    const dispatch = useDispatch();


    // get data from redux state
    const getSelectors = (state) => ({
        user: state.users.single_user,
        orders: state.users.orders,

    });

    const { orders, user } = useSelector(getSelectors);

    useEffect(() => {

        dispatch(getAllUserOrder(user_id))

    }, [])




    return (
        <React.Fragment>
            <Head title="Orders"></Head>
            <BlockHead size="lg">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Orders</BlockTitle>
                        <BlockDes>
                            <p>All orders placed by user on Sylcon.</p>
                        </BlockDes>
                    </BlockHeadContent>

                </BlockBetween>
            </BlockHead>
            <Block>
                <div className="nk-data data-list">
                    <div className="data-head">
                        <h6 className="overline-title">ORders</h6>
                    </div>
                    <table className={`table table-tranx is-compact`}>
                        <thead>
                            <tr className="tb-tnx-head">
                                <th className="tb-tnx-id">
                                    <span className="">#</span>
                                </th>
                                <th className="tb-tnx-info">
                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                        <span>Order Status</span>
                                    </span>
                                </th>
                                <th className="tb-tnx-amount is-alt">
                                    <span className="tb-tnx-total">Created At</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders && orders?.length > 0 ? orders?.map((order) => {
                                return (
                                    <tr key={order.id} className="tb-tnx-item">
                                        <td className="tb-tnx-id">
                                            <a href="#id" onClick={(ev) => { ev.preventDefault(); }}   >
                                                <span>{order?.unique_order_id}</span>
                                            </a>
                                        </td>
                                        <td className="tb-tnx-info">
                                            <div className="tb-tnx-desc">
                                                <span className="title">{order?.order_status?.name}</span>
                                            </div>
                                        </td>
                                        <td className="tb-tnx-amount is-alt">
                                            <div className="tb-tnx-total">
                                                <span className="amount">{moment(order?.created_at).fromNow()}</span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                                : (
                                    <tr className="tb-tnx-item">
                                        <td align="center" colspan="3">
                                            <div className='w-100 pt-5'>
                                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                                    <div>
                                                        <img src={noDocument} style={{ width: '3rem' }} alt='sylcon' />
                                                    </div>
                                                    <div>Order Not Found</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </Block>
        </React.Fragment>
    )
}

export default UserOrder
