import React from "react";
import Icon from "../../components/icon/Icon";
import axiosClient from "../../helpers/axios-client";
import { UPDATE_SIDE_BAR_PERMISSION_URL } from "../../config";

const Toggle = ({ className, click, icon }) => {

    const updateSidebar = (e) => {
        e.preventDefault();
        axiosClient.post(UPDATE_SIDE_BAR_PERMISSION_URL, localStorage.getItem("accessToken"))
            .then((data) => {
                if (data && data.status == 200) {
                    if (data?.data && data?.data.success) {
                        localStorage.setItem("permissions", JSON.stringify(data?.data?.permissions));
                    } else {
                    }
                    console.log(data?.data);
                }
            })
            .catch((err) => {
                const response = err.response;
            });
    }
    return (
        <a
            href="#toggle"
            className={className ? className : ""}
            onClick={(ev) => {
                ev.preventDefault();
                click(ev);
                updateSidebar(ev);
            }}
        >
            <Icon name={icon} />
        </a>
    );
};
export default Toggle;
