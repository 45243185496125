import {
  GET_ALL_ORDERS,
  GET_LIVE_ORDERS,
  GET_ORDER_DELIVERY_PARTNERS,
  GET_SINGLE_ORDER,
  GET_ORDER_RETURNS,
  GET_RETURNED_SINGLE_ORDER,
} from "./actionType";

const initialState = {
  live_orders: [],
  orders: [],
  single_order: [],
  returned_single_order: [],

  order_log: [],
  order_delivery_partners: [],
  return_orders: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIVE_ORDERS:
      return { ...state, live_orders: action.payload };
    case GET_ALL_ORDERS:
      return { ...state, orders: action.payload };
    case GET_SINGLE_ORDER:
      return { ...state, single_order: action.payload.data, order_log: action.payload.logs };
      case GET_RETURNED_SINGLE_ORDER:
        return { ...state, returned_single_order: action.payload.data, order_log: action.payload.logs };
    case GET_ORDER_DELIVERY_PARTNERS:
      return { ...state, order_delivery_partners: action.payload };

    case GET_ORDER_RETURNS:
      console.log(action.payload, "return_orders");
      return { ...state, return_orders: action.payload };

    default:
      return state;
  }
}
