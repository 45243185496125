import React, { useEffect, useState } from 'react'

import { IoRadioButtonOffOutline } from 'react-icons/io5';
import { RiRadioButtonFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { calcualteWarehouseMaxDistance, calculateWarehouseChargeTax, checkTokenValid,
     clearCart,
     getCartWarehouse, itemsActiveCheck, 
     placeOrder} from '../../../../redux/admin/cart/action';



function Checkout() {


    const dispatch = useDispatch();
    const [paymentMode, setPaymentMode] = useState("COD")
    const [loading, setLoading] = useState(false)
    const cartProducts = useSelector((state) => state.cart);
    const user_id = localStorage.getItem("user_id")
    const auth_token = localStorage.getItem("auth_token")
    const [partialWalletChecked, setPartialWalletChecked] = useState(false);
    const [canPayPartialWithWallet, setCanPayPartialWithWallet] = useState(false);
    const [canPayFullWithWallet, setCanPayFullWithWallet] = useState(false);

    const _deliveryCharge = () => {
        if (cartProducts && cartProducts.deliveryCharge && cartProducts.deliveryCharge > 0) {
            return '+₹' + parseFloat(cartProducts.deliveryCharge).toFixed(2);
        } else {
            return "Free Delivery"
        }
    }

    const _totalCharge = () => {
        let total = 0;
        total += cartProducts.totalAmount
        if (cartProducts && cartProducts.deliveryCharge && cartProducts.deliveryCharge > 0) {
            total += parseFloat(cartProducts.deliveryCharge);
        }
        if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.discount_type == "PERCENTAGE") {
            let percentage_discount = ((parseFloat(cartProducts?.appliedCoupon?.coupon_discount) / 100) * parseFloat(cartProducts?.totalAmount));
            console.log(percentage_discount, 'ss')
            if (parseFloat(cartProducts?.appliedCoupon?.max_discount)) {
                if (parseFloat(percentage_discount) >= parseFloat(cartProducts?.appliedCoupon?.max_discount)) {
                    percentage_discount = parseFloat(cartProducts?.appliedCoupon?.max_discount);
                }
            }
            total -= percentage_discount;
        } else if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.discount_type == "FIXED") {
            total -= parseFloat(cartProducts?.appliedCoupon?.coupon_discount);
        }
        return parseFloat(total).toFixed(2);
    }

   
    // useEffect(() => {
    //     if (user.success) {
    //         const formUser = new FormData();
    //         formUser.append("id", localStorage.getItem('user_id'));
    //         formUser.append("token", localStorage.getItem('auth_token'));
    //         dispatch(getUserProfile(formUser)).then((response) => {
    //             if (response && response.payload && response.payload.success) {
    //                 if (parseFloat(response.payload.data.wallet_balance) > 0) {
    //                     setCanPayPartialWithWallet(true)
    //                     setCanPayFullWithWallet(false);
    //                 }
    //                 if (parseFloat(response.payload.data.wallet_balance) >= parseFloat(_totalCharge())) {
    //                     setCanPayFullWithWallet(true);
    //                     setCanPayPartialWithWallet(false)
    //                 }
    //             }
    //         })

    //     }
    //     const formData = new FormData();
    //     formData.append("warehouse_id", cartProducts.warehouse_id);
    //     dispatch(getCartWarehouse(formData));
    //     const formData2 = new FormData();
    //     const cartItems = JSON.stringify(cartProducts.orderitems)
    //     formData2.append("id", localStorage.getItem('user_id'));
    //     formData2.append("warehouse_id", cartProducts.warehouse_id);
    //     formData2.append("products", cartItems);
    //     formData2.append("latitude", localStorage.getItem("userLat"));
    //     formData2.append("longitude", localStorage.getItem("userLng"));
    //     dispatch(calcualteWarehouseMaxDistance(formData2));
    //     const formData3 = new FormData();
    //     formData3.append("products", cartItems);
    //     dispatch(calculateWarehouseChargeTax(formData3))
    // }, []);

    const _completeOrder = (e) => {
        // setLoading(true)
        e.preventDefault();
        const formData = new FormData();
        let cartProduct = JSON.stringify(cartProducts.orderitems);
        // let defaultAddress = localStorage.getItem("userSetAddress");
        formData.append("customer_id", localStorage.getItem('customer_id'));
        formData.append("address", localStorage.getItem('address'));
        formData.append("landmark", localStorage.getItem('landmark'));
  
  
        formData.append("latitude", localStorage.getItem('userLat'));
        formData.append("longitude", localStorage.getItem('userLng'));
  
        formData.append("token", auth_token);
        formData.append("paymentMode", paymentMode);
        formData.append("coupon_code", cartProducts?.appliedCoupon?.coupon_code ?? null);
        formData.append("cartProducts", cartProduct);
        formData.append("deliveryNote", localStorage.getItem('cartNote')??null);
        formData.append("subtotal", cartProducts.totalAmount);
        let discount = 0;
        if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.discount_type == "PERCENTAGE") {
            let percentage_discount = ((parseFloat(cartProducts?.appliedCoupon?.coupon_discount) / 100) * parseFloat(cartProducts?.totalAmount));
            console.log(percentage_discount, 'ss')
            if (parseFloat(cartProducts?.appliedCoupon?.max_discount)) {
                if (parseFloat(percentage_discount) >= parseFloat(cartProducts?.appliedCoupon?.max_discount)) {
                    percentage_discount = parseFloat(cartProducts?.appliedCoupon?.max_discount);
                }
            }
            discount = percentage_discount;
        } else if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.discount_type == "FIXED") {
            discount = parseFloat(cartProducts?.appliedCoupon?.coupon_discount);
        }
        formData.append("coupon_discount", discount);
        formData.append("distance", cartProducts?.warehouse_distance?.distance ?? 0);
        // formData.append("address", defaultAddress);
        formData.append("warehouse_id", cartProducts?.warehouse_id);
        formData.append("is_schedule", sessionStorage.getItem("IsSchedule") == "true" ? 1 : 0);
        formData.append("schedule_type", sessionStorage.getItem("scheduleType"));
        formData.append("schedule_date", sessionStorage.getItem("scheduleDate"));
        formData.append("partial_wallet", partialWalletChecked);
        formData.append("totalPayableAmount", cartProducts?.totalPayableAmount);
        dispatch(placeOrder(formData)).then((response) => {
            if (response && response.payload && response.payload.success) {
                dispatch(clearCart());
                // localStorage.removeItem("address");
                // localStorage.removeItem("customer_id");
                // localStorage.removeItem("customer_name");
                // window.location.replace("/order-success/" + response.payload.data.id)
            } else {
                setLoading(false)
                console.log(cartProducts, 'error')
            }
        })
    }


    return (
        <div style={{ minHeight: "100vh", backgroundColor: "#fafafa" }}>
            {/* {loading ?
                <Loading />
                : */}
                <>
                    <div className='bg-white'>
                        {/* <BackButtonTop title="Payment Method" /> */}
                        <div className='pt-7 pb-1'>
                            <div className="mb-3" >
                                <div className='flex flex-col justify-between '>
                                    <div className='flex flex-row justify-between items-center px-3'
                                     onClick={() => { setPaymentMode("COD") }}>
                                        <div>
                                            {/* <img src={cashOnDelivery} alt='cashOnDelivery' className=' rounded-2xl p-[13px]' style={{ backgroundColor: "#00AB670D" }} /> */}
                                        </div>
                                        <div className='text-sm font-semibold  pr-28'>Cash On Delivery</div>
                                        <div>
                                            {paymentMode == "COD" ?
                                                <RiRadioButtonFill size={25} color="#118a45" />
                                                :
                                                <IoRadioButtonOffOutline size={25} color="#118a45" />
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                    
                                   
                                   
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='w-100 fixed bottom-0'>
                        <div style={{ borderRadius: "40px 40px 0px 0px", backgroundColor: "#e7f1eb" }} className='py-7 flex flex-col justify-between'>
                            <div className='px-3 font-medium text-base text-slate-500 '>
                                <div className='flex justify-between pb-2'>
                                    <div className=''>Sub Amount</div>
                                    <div> ₹{cartProducts.totalAmount.toFixed(2)}</div>
                                </div>
                                <div className='flex justify-between pb-2'>
                                    <div>Delivery Charge</div>
                                    <div> {_deliveryCharge()}</div>
                                </div>
                                {/* {cartProducts?.discount > 0 &&
                                    <div className='flex justify-between pb-2'>
                                        <div>Coupon Discount</div>
                                        <div> -₹ {_couponDiscount()}
                                        </div>
                                    </div>
                                } */}
                            </div>
                            <hr className='mx-3' style={{ borderTop: '2px dashed #9C9FA5', height: '1px' }} />
                            <div className='flex justify-between font-semibold px-3 pb-3'>
                                <div style={{ fontSize: "17px" }} className='text-black'>Total</div>
                                <div style={{ color: "#E84141" }}> ₹{_totalCharge()}</div>
                            </div>
                            <div className=' px-3 w-100'>
                                <button type="submit" className='get-start-button-full mt-3' 
                                onClick={(e) => { _completeOrder(e) }}>
                                    Make Payment
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            {/* } */}
        </div>
    )
}

export default Checkout