import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import {
    CREATE_BANNER_URL, DELETE_BANNER_URL, GET_ALL_BANNERS_URL, TOGGLE_BANNER_URL, UPDATE_BANNER_URL,
    CREATE_SLIDER_URL, DELETE_SLIDER_URL, GET_ALL_SLIDERS_URL, TOGGLE_SLIDER_URL, UPDATE_SLIDER_URL,
    CREATE_COUPON_URL, DELETE_COUPON_URL, GET_ALL_COUPONS_URL, TOGGLE_COUPON_URL, UPDATE_COUPON_URL,
    CREATE_SCRATCH_CARD_URL, DELETE_SCRATCH_CARD_URL, GET_ALL_SCRATCH_CARDS_URL, TOGGLE_SCRATCH_CARD_URL, UPDATE_SCRATCH_CARD_URL,
    GET_ALL_SCRATCH_CARD_WINNERS_URL
} from "../../../config";
import { GET_ALL_BANNERS, GET_ALL_SLIDERS, GET_ALL_COUPONS, GET_ALL_SCRATCH_CARDS, GET_ALL_SCRATCH_CARD_WINNERS } from './actionType';


export const getAllBanners = () => (dispatch) => {
    return axiosClient.post(GET_ALL_BANNERS_URL).then((response) => {
        if (response && response.data.success) {
            const banners = response.data.data;
            return dispatch({ type: GET_ALL_BANNERS, payload: banners });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const createBanner = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' } }
    return axiosClient.post(CREATE_BANNER_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};

export const updateBanner = (data, id) => (dispatch) => {
    console.log(data, 'actionnnn')
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' } }
    return axiosClient.post(UPDATE_BANNER_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const toggleBanner = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_BANNER_URL, { id });
};

export const deleteBanner = (id) => (dispatch) => {
    return axiosClient.post(DELETE_BANNER_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

//Sliders

export const getAllSliders = () => (dispatch) => {
    return axiosClient.post(GET_ALL_SLIDERS_URL).then((response) => {
        if (response && response.data.success) {
            const sliders = response.data.data;
            return dispatch({ type: GET_ALL_SLIDERS, payload: sliders });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const createSlider = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' } }
    return axiosClient.post(CREATE_SLIDER_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};

export const updateSlider = (data, id) => (dispatch) => {
    console.log(data, 'actionnnn')
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' } }
    return axiosClient.post(UPDATE_SLIDER_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const toggleSlider = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_SLIDER_URL, { id });
};

export const deleteSlider = (id) => (dispatch) => {
    return axiosClient.post(DELETE_SLIDER_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};


//Coupons
export const getAllCoupons = () => (dispatch) => {
    return axiosClient.post(GET_ALL_COUPONS_URL).then((response) => {
        if (response && response.data.success) {
            const coupons = response.data.coupons;
            return dispatch({ type: GET_ALL_COUPONS, payload: coupons });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const createCoupon = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' } }
    return axiosClient.post(CREATE_COUPON_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};

export const updateCoupon = (data, id) => (dispatch) => {
    console.log(data, 'actionnnn')
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' } }
    return axiosClient.post(UPDATE_COUPON_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const toggleCoupon = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_COUPON_URL, { id });
};

export const deleteCoupon = (id) => (dispatch) => {
    return axiosClient.post(DELETE_COUPON_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

// ScratchCard

export const getAllScratchCards = () => (dispatch) => {
    return axiosClient.post(GET_ALL_SCRATCH_CARDS_URL).then((response) => {
        if (response && response.data.success) {
            const scratch_cards = response.data.data;
            return dispatch({ type: GET_ALL_SCRATCH_CARDS, payload: scratch_cards });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const createScratchCard = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' } }
    return axiosClient.post(CREATE_SCRATCH_CARD_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};

export const updateScratchCard = (data, id) => (dispatch) => {
    console.log(data, 'actionnnn')
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } }

    return axiosClient.post(UPDATE_SCRATCH_CARD_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const toggleScratchCard = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_SCRATCH_CARD_URL, { id });
};

export const deleteScratchCard = (id) => (dispatch) => {
    return axiosClient.post(DELETE_SCRATCH_CARD_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};
//Scratch Card Winners

export const getAllScratchCardWinners = () => (dispatch) => {
    return axiosClient.post(GET_ALL_SCRATCH_CARD_WINNERS_URL).then((response) => {
        if (response && response.data.success) {
            const scratch_cards = response.data.data;
            return dispatch({ type: GET_ALL_SCRATCH_CARD_WINNERS, payload: scratch_cards });
        }
    }).catch(function (error) {
        console.log(error);
    });
};
