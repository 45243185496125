import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllReturnedOrders } from "../../../redux/admin/order/action";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  ReactDataTable,
} from "../../../components/Component";
import ContentAlt from "../../../layout/content/ContentAlt";
import Content from '../../../layout/content/Content';

import { useSelector } from "react-redux"; 

function Returnedorders() {
  const [returnedOrders, setReturnedOrders] = useState([]);
  const dispatch = useDispatch(); //defining dispatch
  useEffect(() => {
    // Fetch data from API
    dispatch(getAllReturnedOrders());
  }, []);
  const getSelectors = (state) => ({
    Returnedorders: state.order.return_orders,
  });
  const { Returnedorders } = useSelector(getSelectors);

  console.log(Returnedorders, "returnedOrders");
  if (Returnedorders && Returnedorders.length > 0) {
    Returnedorders.forEach((order, index) => {
      console.log(`Returned Order ${index + 1}: `, order);
      console.log(`Order Item of Returned Order ${index + 1}: `, order.order_item);
      // Access other properties as needed
    });
  } else {
    console.log("Returnedorders is still loading or empty");
  }

  const tableHeadings = [
    {
      name: "Order id",
      selector: (row) => row?.order_item?.order_id,
      sortable: true,
      cell: (row) => {
        return (
          <div className="user-card flex items-center justify-between mt-2 mb-2 w-100" id={row.id}>
            <div> {row?.order_item?.order_id} </div>
          </div>
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row?.order_item?.name,
      compact: true,
      style: { paddingRight: "20px" },
      cell: (row) => {
        return (
          <div className="user-card flex items-center justify-between mt-2 mb-2 w-100" id={row.id}>
            <div> {row?.order_item?.name} </div>
          </div>
        );
      },
    },
    {
      name: "Quantity",
      selector: (row) => row.order_item.quantity,
      compact: true,
      style: { paddingRight: "20px" },
      cell: (row) => {
        return (
          <div className="user-card flex items-center justify-between mt-2 mb-2 w-100" id={row.id}>
            <div> {row?.order_item?.quantity} </div>
          </div>
        );
      },
    },

    {
      name: "Refund Status",
      selector: (row) => row?.payment_status,
      compact: true,
      style: { paddingRight: "20px" },
      cell: (row) => {
        return (
          <div className="user-card flex items-center justify-between mt-2 mb-2 w-100" id={row.id}>
            <div> {row?.payment_status} </div>
          </div>
        );
      },
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      compact: true,
      style: { paddingRight: "20px" },
      cell: (row) => {
        return (
          <div className="user-card flex items-center justify-between mt-2 mb-2 w-100" id={row.id}>
            <div> {row?.reason} </div>
            <div className="d-flex flex-row-reverse">
              <a href={`view-return-order/${row.id}`} class="btn btn-info">
                View
              </a>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Head title="Returned Orders"></Head>
      {/* content */}
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                ReturnedOrders Orders
              </BlockTitle>
              <BlockDes className="text-soft"></BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools g-3">
                    <li>
                      {/* <Button color="dark" className=""
                                                    >
                                                <span>Bulk CSV Upload</span> <Icon name="icon ni ni-upload"></Icon>
                                            </Button> */}
                    </li>
                    <li className="nk-block-tools-opt">
                      {/* <Button color="primary" className="btn-icon"
                                                
                                            >
                                                <Icon name="plus"></Icon>
                                            </Button> */}
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <ReactDataTable data={Returnedorders} columns={tableHeadings} selectableRows expandableRows pagination />
      </Content>
    </div>
  );
}

export default Returnedorders;
