import { CREATE_PERMISSION_URL, DELETE_PERMISSION_URL, GET_ALL_PERMISSION_URL, TOGGLE_PERMISSION_URL, UPDATE_SIDE_BAR_PERMISSION_URL } from "../../../config";
import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import { GET_ALL_PERMISSION_HEAD, UPDATE_SIDE_BAR_PERMISSION } from "./actionType";


//all users
export const getAllPermission = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_PERMISSION_URL)
        .then((response) => {
            if (response && response.data.success) {
                const users = response.data;
                return dispatch({ type: GET_ALL_PERMISSION_HEAD, payload: users });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};


export const createPermission = (data) => (dispatch) => {
    return axiosClient
        .post(CREATE_PERMISSION_URL, {
            data,
        })
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });

            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};


export const togglePermission = (role_id, permission_id, type) => (dispatch) => {
    return axiosClient.post(TOGGLE_PERMISSION_URL, {
        role_id, permission_id, type
    });
};



export const deletePermission = (id) => (dispatch) => {
    return axiosClient.post(DELETE_PERMISSION_URL, {
        id,
    }).then((response) => {
        dispatch({
            type: CLOSE_FORM_LOADING,
        });
        dispatch({
            type: FORM_SUBMISSION_STATUS,
            payload: true,
        });
    });
};
