import React, { useEffect, useState } from "react";
import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Button,
    Icon,
    PreviewCard,
} from "../../components/Component";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { Spinner, FormGroup } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axiosClient from "../../helpers/axios-client";
import { REGISTER_URL } from "../../config";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { string } from "yup";

const Register = ({ history }) => {
    const [passState, setPassState] = useState(false);
    const [loading, setLoading] = useState(false);

    const schema = yup.object({
        name: string().required(),
        email: string().email().required(),
        password: string().min(8).required(),
        password_confirmation: string().min(8).required(),
    });

    const defaultValues = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
    }
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });

    const { errors, register, handleSubmit, setError } = methods

    const handleFormSubmit = (formValues) => {
        setLoading(true);
        axiosClient.post(REGISTER_URL, formValues).then((response) => {
            console.log(response, 'response');
            if (response?.data && response?.data.success) {
                localStorage.setItem("accessToken",response?.data?.token);
                localStorage.setItem("userName",response?.data?.user_name);
                localStorage.setItem("image",response?.data?.image);
                localStorage.setItem("email",response?.data?.email);
                localStorage.setItem("role",response?.data?.user.roles[0].name);
                localStorage.setItem("permissions", JSON.stringify(response?.data?.permissions));
                setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
            } else {
                setLoading(false)
            }
        })
    };
    return (
        <React.Fragment>
            <Head title="Register" />
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body  wide-xs">
                    <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                        <BlockHead>
                            <BlockContent>
                                <BlockTitle tag="h4">Register</BlockTitle>
                                <BlockDes>
                                    <p>Create New Sylcon Account</p>
                                </BlockDes>
                            </BlockContent>
                        </BlockHead>
                        <form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
                            <FormGroup>
                                <label className="form-label" htmlFor="name">
                                    Name
                                </label>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Enter your name"
                                        ref={register({ required: true })}
                                        className="form-control-lg form-control"
                                    />
                                    {errors.name && <p className="invalid">This field is required</p>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="default-01">
                                        Email
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <input
                                        type="email"
                                        bssize="lg"
                                        id="default-01"
                                        name="email"
                                        ref={register({ required: true })}
                                        className="form-control-lg form-control"
                                        placeholder="Enter your email"
                                    />
                                    {errors.email && <p className="invalid">{errors.email.message}</p>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="password">
                                        Password
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#password"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setPassState(!passState);
                                        }}
                                        className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                                    >
                                        <Icon name="eye" className="passcode-icon icon-show"></Icon>

                                        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                    </a>
                                    <input
                                        type={passState ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        ref={register({ required: "This field is required" })}
                                        placeholder="Enter your password"
                                        className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                                    />
                                    {errors.password && <span className="invalid">{errors.password.message}</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="password">
                                        Confirm Password
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#password"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setPassState(!passState);
                                        }}
                                        className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                                    >
                                        <Icon name="eye" className="passcode-icon icon-show"></Icon>

                                        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                    </a>
                                    <input
                                        type={passState ? "text" : "password"}
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        ref={register({ required: "This field is required" })}
                                        placeholder="Confirm your password"
                                        className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                                    />
                                    {errors.password_confirmation && (
                                        <span className="invalid">{errors.password_confirmation.message}</span>
                                    )}
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Button type="submit" color="primary" size="lg" className="btn-block">
                                    {loading ? <Spinner size="sm" color="light" /> : "Register"}
                                </Button>
                            </FormGroup>
                        </form>
                        <div className="form-note-s2 text-center pt-4">
                            {" "}
                            Already have an account?{" "}
                            <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                                <strong>Sign in </strong>
                            </Link>
                        </div>
                    </PreviewCard>
                </Block>
                <AuthFooter />
            </PageContainer>
        </React.Fragment>
    );
};
export default Register;
