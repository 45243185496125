import { GET_ALL_BANNERS, GET_ALL_SLIDERS, GET_ALL_COUPONS, GET_ALL_SCRATCH_CARDS, GET_ALL_SCRATCH_CARD_WINNERS } from "./actionType";

const initialState = {
    banners: [],
    sliders: [],
    coupons: [],
    scratch_cards: [],
    scratch_card_winners: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_BANNERS:
            return { ...state, banners: action.payload };
        case GET_ALL_SLIDERS:
            return { ...state, sliders: action.payload };
        case GET_ALL_COUPONS:
            return { ...state, coupons: action.payload };
        case GET_ALL_SCRATCH_CARDS:
            return { ...state, scratch_cards: action.payload };
        case GET_ALL_SCRATCH_CARD_WINNERS:
            return { ...state, scratch_card_winners: action.payload };
        default:
            return state;
    }
}
