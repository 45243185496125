import {
    ACCEPT_ORDER_URL,
    ASSIGN_DELIVERY_GUY_TO_ORDER_URL,
    CANCEL_ORDER_URL,
    DELETE_ORDER_URL,
    CREATE_ORDER_ITEM_URL,
    GET_ALL_ORDERS_URL,
    GET_ALL_RETURNORDERS_URL,
    GET_LIVE_ORDERS_URL,
    GET_ORDER_DELIVERY_PARTNERS_URL,
    GET_RETURNED_SINGLE_ORDER_URL,
    GET_SINGLE_ORDER_URL,
    UPDATE_ORDER_STATUS_URL,
    ASSIGN_RETURNED_DELIVERY_GUY_TO_ORDER_URL,
    ACCEPT_RETURNED_ORDER_URL,
    UPDATE_RETURNED_ORDER_STATUS_URL,
    CANCEL_RETURNED_ORDER_URL
} from "../../../config";
import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
// import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import { GET_ALL_ORDERS, GET_LIVE_ORDERS, GET_ORDER_DELIVERY_PARTNERS, GET_SINGLE_ORDER,GET_ORDER_RETURNS, GET_RETURNED_SINGLE_ORDER } from "./actionType";

export const getLiveOrders = (data) => (dispatch) => {
    return axiosClient.post(GET_LIVE_ORDERS_URL, data).then((response) => {
        if (response && response.data.success) {
            const orders = response.data;
            return dispatch({ type: GET_LIVE_ORDERS, payload: orders });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const getAllOrders = (data) => (dispatch) => {
    return axiosClient.post(GET_ALL_ORDERS_URL, data).then((response) => {
        if (response && response.data.success) {
            const orders = response.data.data;
            return dispatch({ type: GET_ALL_ORDERS, payload: orders });
        }
    }).catch(function (error) {
        console.log(error);
    });
};
export const getAllReturnedOrders = (data) => (dispatch) => {
    return axiosClient.post(GET_ALL_RETURNORDERS_URL, data).then((response) => {
        if (response && response.data.success) {
            const orders = response.data.OrderReturn;
            console.log(orders,"orders")
            return dispatch({ type: GET_ORDER_RETURNS, payload: orders });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const getSingleOrder = (id) => (dispatch) => {
    return axiosClient.post(GET_SINGLE_ORDER_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        if (response && response.data.success) {
            const orders = response.data;
            return dispatch({ type: GET_SINGLE_ORDER, payload: orders });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const getreturnedSingleOrder = (id) => (dispatch) => {
    return axiosClient.post(GET_RETURNED_SINGLE_ORDER_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        if (response && response.data.success) {
            const orders = response.data;
            return dispatch({ type: GET_RETURNED_SINGLE_ORDER, payload: orders });
        }
    }).catch(function (error) {
        console.log(error);
    });
};


export const getOrderDeliveryPartners = (id) => (dispatch) => {
    return axiosClient.post(GET_ORDER_DELIVERY_PARTNERS_URL, { id }).then((response) => {
        if (response && response.data.success) {
            const orderpartners = response.data.data;
            return dispatch({ type: GET_ORDER_DELIVERY_PARTNERS, payload: orderpartners });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const assignDeliveryGuyToOrder = (data) => (dispatch) => {
    return axiosClient.post(ASSIGN_DELIVERY_GUY_TO_ORDER_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};
export const assignReturnedDeliveryGuy = (data) => (dispatch) => {
    return axiosClient.post(ASSIGN_RETURNED_DELIVERY_GUY_TO_ORDER_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const updateOrderStatus = (data) => (dispatch) => {
    return axiosClient.post(UPDATE_ORDER_STATUS_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};
export const updateReturnedOrderStatus = (data) => (dispatch) => {
    return axiosClient.post(UPDATE_RETURNED_ORDER_STATUS_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const acceptOrder = (data) => (dispatch) => {
    return axiosClient.post(ACCEPT_ORDER_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};
export const acceptReturnedOrder = (data) => (dispatch) => {
    return axiosClient.post(ACCEPT_RETURNED_ORDER_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const cancelOrder = (data) => (dispatch) => {
    return axiosClient.post(CANCEL_ORDER_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
    
    
};
export const deleteOrderItem = (data) => (dispatch) => {
    return axiosClient.post(DELETE_ORDER_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
    
    
    
};
export const  createOrderItem = (data) => (dispatch) => {
    return axiosClient.post(CREATE_ORDER_ITEM_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
    
    
    
};
export const cancelOrderReturned = (data) => (dispatch) => {
    return axiosClient.post(CANCEL_RETURNED_ORDER_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
    
    
};

