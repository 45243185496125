import { ADD_TO_DISPLAY_ITEMS, CLEAR_CART_IN_DASHBOARD_CREATE_ORDER, 
  GET_ALL_WAREHOUSE, GET_WAREHOUSE_ITEMS } from "./actionType";

const initialState = {
  warehouse: [],
  warehouse_items: [],
  display_items: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_WAREHOUSE:
      return { ...state, warehouse: action.payload };
    case GET_WAREHOUSE_ITEMS:
      return { ...state, warehouse_items: action.payload };

      
    case ADD_TO_DISPLAY_ITEMS:
      const displayItemsArray = Array.isArray(state.display_items) ? state.display_items : [];
      console.log(displayItemsArray, "displayItemsArray");
      return {
        ...state,
        display_items: [...displayItemsArray, action.payload],
      };


    case CLEAR_CART_IN_DASHBOARD_CREATE_ORDER:
      console.log("CLEAR_CART_IN_DASHBOARD_CREATE_ORDER");
      return {
        ...state,
        display_items: [],
      };
    default:
      return state;
  }
}
