import { values } from 'lodash';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { Block, BlockBetween, BlockHeadContent, BlockTitle, RSelect } from '../../../../../components/Component';
import ToastExample from '../../../../../helpers/toast';
import Head from '../../../../../layout/head/Head';
import { getSingleUser, updateUser, updateUserRole } from '../../../../../redux/admin/user/action';
import { openFormLoading } from '../../../../../redux/common/actions';

function RolesPermission(user_id) {

    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        user: state.users.single_user,
        roles: state.common.roles,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });
    const { roles, form_loading, form_submission_status, user } = useSelector(getSelectors);
    const [showToast, setShowToast] = useState(false)
    const methods = useForm();

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    const onFormSubmit = (submitData) => {
        dispatch(openFormLoading());
        const formData = new FormData();
        formData.append('id', user?.id);
        formData.append('role', submitData?.role);
        dispatch(updateUserRole(formData)).then((response) => {
            if (response.payload.success) {
                setTimeout(() => {
                    setShowToast(true);
                    dispatch(getSingleUser(user?.id))

                }, 500)
                setTimeout(() => {
                    setShowToast(false);
                }, 3000)
            }
        })
    }


    // data formated for selectbox
    const allRoles = roles?.map((role) => (
        { value: role.id, label: role.name }

    ));

    return (
        <React.Fragment>
            <Head title="Bank Details"></Head>
            <Block size="lg">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Roles & Permission</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <ToastExample show={showToast} />
                    </BlockHeadContent>
                </BlockBetween>
            </Block>
            <Block>
                <div className="nk-data data-list">
                    <div className="data-head">
                        <h6 className="overline-title">user role</h6>
                    </div>
                    <Form className="gy-3" onSubmit={handleSubmit(onFormSubmit)}>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label" htmlFor="user-name">
                                        Current Role
                                    </label>
                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <Badge pill color="secondary">
                                            {user && user?.roles && user?.roles[0] ? user?.roles[0]?.name : "User Has No Role"}
                                        </Badge>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3 align-center">
                            <Col lg="5">
                                <FormGroup>
                                    <label className="form-label">Change Role</label>

                                </FormGroup>
                            </Col>
                            <Col lg="7">
                                <FormGroup>
                                    <div className="form-control-wrap">
                                        <Controller
                                            name="role"
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <RSelect
                                                    options={allRoles}
                                                    onChange={(val) => onChange(val.value)}
                                                    defaultValue={allRoles?.find((c) => c.value === value)}
                                                />
                                            )}
                                            ref={register()}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col lg="7" className="offset-lg-5">
                                <FormGroup className="mt-2">
                                    <Button type='submit' color="primary" size="lg" >
                                        Update
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default RolesPermission
