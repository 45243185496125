import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Spinner, UncontrolledDropdown } from 'reactstrap';
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable, RSelect } from '../../../../components/Component';
import { WEBSITE_BASE_URL } from '../../../../config';
import ToastExample from '../../../../helpers/toast';
import Content from '../../../../layout/content/Content';
import Head from '../../../../layout/head/Head';
import { nonActiveFormSubmissionStatus, openFormLoading } from '../../../../redux/common/actions';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { createItemVariant, deleteItemVariant, deleteVariantImage, getAllItemVariants, getAllItems, toggleItemVariant, updateItemVariant, bulkUploadItemVariant } from '../../../../redux/admin/item/action';

import noimage from '../../../../assets/images/noimage.png'

const ItemVariants = () => {
    const dispatch = useDispatch(); //defining dispatch
    useEffect(() => {
        dispatch(getAllItemVariants());
        dispatch(getAllItems());
    }, []);
    const getSelectors = (state) => ({
        item_variants: state.item.item_variants,
        items: state.item.items,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });
    const { item_variants, items, form_loading, form_submission_status, } = useSelector(getSelectors);

    const [editItemVariant, setEditItemVariant] = useState([]);   //edit Item state

    const [deletingItemVariant, setDeletingItemVariant] = useState([]);   //delete Item state

    const [formData, setFormData] = useState([]);   //form data state defined

    const [showToast, setShowToast] = useState(false);   //toast state

    const [data, setData] = useState([]);  //data state defined

    const [discount, setDiscount] = useState(false);  //discout state defined


    const [popular, setPopular] = useState([]);  // state defined
    const [veg, setVeg] = useState([]);  // state defined
    const [recommended, setRecommended] = useState([]);  // state defined
    const [image, setImage] = useState([]);
    const [file, setFile] = useState([]);
    const [imageCount, setImageCount] = useState(false);  //discout state defined
    const [bulkFile, setBulkFile] = useState([]);  // state defined


    function uploadSingleFile(e) {
        setImage([...image, { image: e.target.files[0] }]);
        setFile([...file, { image: URL.createObjectURL(e.target.files[0]) }]);
    }

    function deleteFile(e) {
        const s = file.filter((item, index) => index !== e);
        setFile(s);
        const si = image.filter((item, index) => index !== e);
        setImage(si);
        console.log(s, si, 'file')
    }

    function deleteImage(variant_id, image_id) {
        const new_data = new FormData();
        new_data.append('variant_id', variant_id);
        new_data.append('image_id', image_id);
        dispatch(deleteVariantImage(new_data));
    }

    //modal state
    const [modal, setModal] = useState({
        add: false,
        edit: false,
        schedule: false,
        delete: false,
        bulk: false,
    });

    //to open add modal
    const openAddModal = () => {
        setEditItemVariant(' ');
        setDiscount(false);
        setFile([])
        setImage([])
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal({ add: true });
        }, 100);
    };

    //to open edit modal
    const openEditModal = (item) => {
        setModal({ edit: true });
        dispatch(nonActiveFormSubmissionStatus());
        setImage([])
        setFile([])
        setDiscount(true)
        setEditItemVariant(item);
    };

    //to open delete modal
    const openScheduleModal = (item) => {
        setModal({ schedule: true });
        dispatch(nonActiveFormSubmissionStatus());
        // setDeletingItemVariant(item);
    };


    //to open delete modal
    const openDeleteModal = (item) => {
        setModal({ delete: true });
        dispatch(nonActiveFormSubmissionStatus());
        setDeletingItemVariant(item);
    };


    //to open delete modal
    const openBulkUploadModal = (item) => {
        setModal({ bulk: true });
        dispatch(nonActiveFormSubmissionStatus());
        // setDeletingItem(item);
    };


    const dispableImage = () => {
        let disable = true;
        if (modal.edit && editItemVariant.images.length > 0) {
            disable = (editItemVariant.images.length + file.length) <= 4 ? false : true
        } else {
            disable = file.length <= 4 ? false : true
        }
        return disable
    };

    const listItems = items?.map((category) => (
        { value: category.id, label: category.name }
    ));

    const listUnits = [
        { value: "Litre", label: "Litre" },
        { value: "Pack", label: "Pack" },
        { value: "Kilo Gram", label: "Kilo Gram" },
        { value: "Gram", label: "Gram" },
    ];
    //form validation with yup
    const itemSchema = yup.object({
        name: yup.string().required(),
        description: yup.string().required(),
        specification: yup.string().required(),
        item_id: yup.string().required(),
        unit_type: yup.string().required(),
        unit: yup.string().required(),
        sell_price: yup.string().required(),
        stock: yup.string().required(),
    });

    //form values
    const defaultValues = useMemo(
        () => ({
            id: editItemVariant?.id || "",
            name: editItemVariant?.name || "",
            description: editItemVariant?.description || "",
            specification: editItemVariant?.specification || "",
            item_id: editItemVariant?.item_id || "",
            unit_type: editItemVariant?.unit_type || "",
            unit: editItemVariant?.unit || "",
            sell_price: editItemVariant?.sell_price || "",
            market_price: editItemVariant?.market_price || "",
            stock: editItemVariant?.stock || "",
        }),
        [editItemVariant]
    );

    const methods = useForm({
        resolver: yupResolver(itemSchema),
        defaultValues: defaultValues,
    });

    //useform methods
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control
    } = methods;

    //submit function
    const onFormSubmit = (submitData) => {
        dispatch(openFormLoading());
        const new_data = new FormData();
        new_data.append('name', submitData?.name);
        if (image.length > 0) {
            image.forEach((image_file) => {
                new_data.append('images[]', image_file.image);
            })
        }
        new_data.append('description', submitData?.description);
        new_data.append('specification', submitData?.specification);
        new_data.append('item_id', submitData?.item_id);
        new_data.append('unit_type', submitData?.unit_type);
        new_data.append('unit', submitData?.unit);
        new_data.append('sell_price', submitData?.sell_price);
        {
            discount &&
                new_data.append('market_price', submitData?.market_price);
        }
        new_data.append('stock', submitData?.stock);
        if (modal.add) {
            dispatch(createItemVariant(new_data));
        } else if (modal.edit) {
            new_data.append('id', editItemVariant?.id)
            dispatch(updateItemVariant(new_data));
        } else if (modal.delete) {
            dispatch(deleteItemVariant(deletingItemVariant?.id));
        }
        resetForm();
    };

    const onBulkSubmit = (e) => {
        e.preventDefault();
        console.log(bulkFile)
        if (bulkFile) {
            dispatch(openFormLoading());
            const new_data = new FormData();
            new_data.append('item_variant_csv', bulkFile);
            dispatch(bulkUploadItemVariant(new_data));
            setModal({ bulk: false })
            dispatch(getAllItemVariants());
        }
    };

    const resetForm = () => {
        setFormData({
            id: "",
            name: "",
            description: "",
            specification: "",
            item_id: "",
            unit_type: "",
            unit: "",
            sell_price: "",
            market_price: "0",
            stock: "",
        });
    };

    //to close modal
    const onFormCancel = () => {
        setModal({ add: false, edit: false, schedule: false, delete: false });
        document.getElementsByClassName("invalid").innerHTML = "";
        resetForm();
        setEditItemVariant(" ")
        setDiscount(false);
    };

    // toggle
    const handleStatus = (e, id) => {
        dispatch(toggleItemVariant(id));
        setTimeout(() => {
            dispatch(getAllItemVariants());
            setShowToast(true);
        }, 500);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    useEffect(() => {
        if (modal.add == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllItemVariants());
                setData(item_variants);
                setModal({ add: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }

        if (modal.edit == true && editItemVariant && form_loading == false && form_submission_status == true) {
            dispatch(getAllItemVariants());
            setData(item_variants);
            setModal({ edit: false });
            setShowToast(true);
            reset(defaultValues);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        } else {
            reset(defaultValues);
        }

        if (modal.delete == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllItemVariants());
                setData(item_variants);
                setModal({ delete: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    }, [createItemVariant, form_loading, modal, form_submission_status]);

    //headers of datatable
    const tableHeadings = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },

        {
            name: "Item Name",
            selector: (row) => row?.item ? row?.item?.name : 'No Item Found',
            sortable: true
        },

        {
            name: "Unit type",
            selector: (row) => row?.unit_type,
            sortable: true
        },

        {
            name: "Image",
            selector: (row) => row?.images?.length > 0 ? row?.images[0].id : 'No Item Found',
            compact: true,
            style: { paddingRight: "20px" },
            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        {row?.images?.length > 0 ?
                            <img src={WEBSITE_BASE_URL + row?.images[0].image} alt="item" style={{ width: '6rem', borderRadius: "5px" }} />
                            :
                            <div className='d-flex flex-column align-items-center ml-3'>
                                <img src={noimage} alt="item" style={{ height: '5vh', width: '5vh' }} />
                                <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
                            </div>
                        }
                    </div>
                );
            },
        },

        {
            name: "Status",
            selector: (row) => row.is_active,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        <Button color={row.is_active ? "success" : "danger"}
                            onClick={(e) => handleStatus(e, row.id)}
                        >
                            {row.is_active ? <Icon name="stop-circle-fill" /> : <Icon name="play-circle-fill" />}
                        </Button>
                    </div>
                );
            },
        },

        {
            name: "Actions",
            selector: (row) => row.is_surge,
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans
                        row={row}
                    />
                </div>
            ),
            sortable: false,
        },
    ];
    const [sm, updateSm] = useState(false);


    //action dropdown
    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a"
                                onClick={() => openEditModal(row)}
                            >
                                <Icon name="pen" />Edit
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem tag="a"

                                onClick={() => openDeleteModal(row)}
                            >
                                <Icon name="trash" />Delete
                            </DropdownItem>
                        </li>
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    return (
        <React.Fragment>
            <Head title="ItemVariant"></Head>
            {/* content */}
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Item Variants Lists
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {item_variants?.length} Item Variants.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <ToastExample
                                    show={showToast}
                                />
                                <div className="toggle-expand-content">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <Button color="dark" className=""
                                                onClick={() => openBulkUploadModal()}    >
                                                <span>Bulk CSV Upload</span> <Icon name="icon ni ni-upload"></Icon>
                                            </Button>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon"
                                                onClick={() => openAddModal()}
                                            >
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <ReactDataTable
                    data={item_variants}
                    columns={tableHeadings}
                    expandableRows
                    pagination
                />
            </Content>


            <Modal
                isOpen={modal.bulk}
                toggle={() => setModal({ bulk: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <button className="close" onClick={(ev) => { ev.preventDefault(); onFormCancel(); }}  >
                        <Icon name="cross-sm"></Icon>
                    </button>
                    <div className="p-2">
                        <h5 className="title">CSV BULK UPLOAD ITEM VARIANTS</h5>
                        <div className='mt-2'>
                            <Form className="row gy-4 pt-4" onSubmit={(e) => onBulkSubmit(e)} encType="multipart/formData">
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">CSV FILE</label>
                                        <input
                                            class="form-control"
                                            type="file"
                                            accept=".csv"
                                            name="item_variant_csv"
                                            required
                                            onChange={(e) => {
                                                setBulkFile(e.target.files[0])
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <a href={WEBSITE_BASE_URL + "/assets/docs/item-variant-sample.csv"} className="btn btn-lg btn-dark w-100 justify-center" target="_blank" download>
                                        Download Sample CSV
                                    </a>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 pt-4">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" />Uploading File
                                                    </>
                                                ) : (
                                                    <React.Fragment> Upload File </React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <button onClick={(ev) => { ev.preventDefault(); onFormCancel(); }} className="link link-light"  >
                                                Cancel
                                            </button>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>

                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* add and edit modal */}
            <Modal
                isOpen={modal.add || modal.edit}
                toggle={() => setModal({ add: false })}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">{modal.edit ? "Editing Item Variant - " + editItemVariant?.name : "Add Item Variant"}</h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/formData">
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Item Variant Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Enter Item Variant Name"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Image {imageCount ? "( 5 images )" : null}</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            disabled={dispableImage()}
                                            onChange={(event) => {
                                                uploadSingleFile(event);
                                                event.target.value = null;
                                            }}
                                        />
                                        {errors.image && <span className="invalid">{errors.image.message}</span>}
                                    </FormGroup>
                                </Col>

                                {modal.edit && editItemVariant?.images.length > 0 &&
                                    editItemVariant?.images.map((item, index) => {
                                        return (
                                            <Col md="4">
                                                <FormGroup>
                                                    <div className='mt-2 user-select-none' key={index} style={{ boxShadow: "0px 0px 20px 8px #00000015", borderRadius: '5px' }}>
                                                        <img src={WEBSITE_BASE_URL + item.image} alt="" style={{ width: '100%', height: '10rem', borderRadius: '5px 5px 0 0' }} />
                                                        <button className='w-100' type="button" style={{ borderRadius: ' 0 0 5px 5px', backgroundColor: "#c9c9c9", border: 'none' }} onClick={() => deleteImage(editItemVariant.id, item.id)}>
                                                            <span style={{ fontSize: "15px", color: '#fff', fontWeight: '600' }}>delete</span>
                                                        </button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}
                                {file.length > 0 &&
                                    file.map((item, index) => {
                                        return (
                                            <Col md="4">
                                                <FormGroup>
                                                    <div className='mt-2 user-select-none' key={item} style={{ boxShadow: "0px 0px 20px 8px #00000015", borderRadius: '5px' }}>
                                                        <img src={item.image} alt="" style={{ width: '100%', height: '10rem', borderRadius: '5px 5px 0 0' }} />
                                                        <button className='w-100' type="button" style={{ borderRadius: ' 0 0 5px 5px', backgroundColor: "#c9c9c9", border: 'none' }} onClick={() => deleteFile(index)}>
                                                            <span style={{ fontSize: "15px", color: '#fff', fontWeight: '600' }}>delete</span>
                                                        </button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}


                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Description</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="description"
                                            placeholder="Enter Description"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.description && <span className="invalid">{errors.description.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Specification</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="specification"
                                            placeholder="Enter Specification"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.specification && <span className="invalid">{errors.specification.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Item</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="item_id"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={listItems}
                                                        onChange={(val) => onChange(val.value)}
                                                        placeholder="Select Item"
                                                        defaultValue={listItems?.find((cat) => cat.value === value)}
                                                    />
                                                )}
                                            />
                                            {errors.item_id && (
                                                <span className="invalid" style={{ color: '#e85347', fontSize: '11px', fontStyle: 'italic' }}>Please choose a Item</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Unit Type</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="unit_type"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={listUnits}
                                                        onChange={(val) => onChange(val.value)}
                                                        placeholder="Select Unit Type"
                                                        defaultValue={listUnits?.find((cat) => cat.value === value)}
                                                    />
                                                )}
                                            />
                                            {errors.unit_type && (
                                                <span className="invalid" style={{ color: '#e85347', fontSize: '11px', fontStyle: 'italic' }}>Please choose a Unit</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Unit</label>
                                        <div className="form-control-wrap">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="unit"
                                                min={0}
                                                placeholder="Enter Unit"
                                                ref={register()}
                                            />
                                            {errors.unit && <span className="invalid">{errors.unit.message}</span>}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md='6'>
                                    <FormGroup>
                                        <label className="form-label">Stock</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="stock"
                                            min={0}
                                            placeholder="Enter Stock"
                                            ref={register()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">{discount ? 'Selling Price' : 'Price'}</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="sell_price"
                                            min={0}
                                            placeholder={discount ? "Enter Selling Price" : "Enter Price"}
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.price && <span className="invalid">{errors.price.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md={discount ? '4' : '0'}>
                                    <div style={discount ? { display: 'block' } : { display: 'none' }}>
                                        <FormGroup>
                                            <label className="form-label">Market Price</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="market_price"
                                                min={0}
                                                placeholder="Enter Market Price"
                                                ref={register}
                                            />
                                        </FormGroup>
                                    </div>
                                </Col>
                                <div className='mt-4 ml-3' onClick={() => setDiscount(!discount)}>
                                    <div className='pt-1' >
                                        <Button className="btn-icon py-1 px-2 btn-dim" color="primary" size="rg" style={{ pointerEvents: 'none' }}>
                                            {discount ? <Icon name="minus-sm" /> : <><Icon name="plus-sm" />Add Discount</>}
                                        </Button>
                                    </div>
                                </div>

                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" /> {modal.edit ? "Updating Item Variant" : "Creating New Item Variant"}
                                                    </>
                                                ) : (
                                                    <React.Fragment>{modal.edit ? "Update Item Variant" : "Add New Item Variant"}</React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    onFormCancel();
                                                }}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* delete modal */}
            <Modal
                isOpen={modal.delete}
                toggle={() => setModal({ delete: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Delete Item Variant{' '}
                            <Badge color="primary">
                                {deletingItemVariant?.name}
                            </Badge>
                            {' '}
                            ?
                        </h5>
                        <div className='mt-2'>
                            <span>This Item Variant will be deleted. Do you really want to delete this Item Variant ?</span>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        onFormSubmit();
                                    }} color="danger" className="px-4 btn-block">
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />Deleting
                                            </>
                                        ) : (
                                            <React.Fragment>Delete</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* schedule modal */}
            <Modal
                isOpen={modal.schedule}
                size='lg'
                toggle={() => setModal({ schedule: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Schedule Item Variant{' '}
                            <Badge color="primary">
                                {/* {deletingItemVariant?.name} */}
                            </Badge>
                        </h5>
                        <div className='mt-2'>
                            <div className=''>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <Button
                                        className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                        onClick={() => updateSm(!sm)}
                                    >
                                        <Icon name="more-v" />
                                    </Button>
                                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                                                        <Icon className="d-none d-sm-inline" name="calender-date" />
                                                        <span>
                                                            <span className="d-none d-md-inline"></span>Monday
                                                        </span>
                                                        <Icon className="dd-indc" name="chevron-right" />
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <ul className="link-list-opt no-bdr">
                                                            <li>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                    }}
                                                                    href="#!"
                                                                >
                                                                    <span>Last 30 days</span>
                                                                </DropdownItem>
                                                            </li>
                                                            <li>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                    }}

                                                                >
                                                                    <span>Last 6 months</span>
                                                                </DropdownItem>
                                                            </li>
                                                            <li>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                    }}

                                                                >
                                                                    <span>Last 3 weeks</span>
                                                                </DropdownItem>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <Button color="primary">
                                                    <Icon name="reports" />
                                                    <span>Reports</span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <ul className="link-list-plain">
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Monday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Tuesday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Wednesday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Thursday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Friday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Saturday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Sunday
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                            </div>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        onFormSubmit();
                                    }} color="primary" className="px-4 btn-block">
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />Updating
                                            </>
                                        ) : (
                                            <React.Fragment>Update</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default ItemVariants
