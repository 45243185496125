import React, { useEffect, useState } from 'react'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Row, UncontrolledDropdown } from 'reactstrap'
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable } from '../../../../components/Component'
import { WEBSITE_API_URL, WEBSITE_BASE_URL } from '../../../../config'
import ToastExample from '../../../../helpers/toast'
import Content from '../../../../layout/content/Content'
import Head from '../../../../layout/head/Head'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getStorePayouts, releasePayout } from '../../../../redux/admin/report/action'
import { nonActiveFormSubmissionStatus } from '../../../../redux/common/actions'
import DatePicker from "react-datepicker";
import subDays from "date-fns/subDays";

const StorePayouts = () => {
    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        payouts: state.reports.pending_payouts,
    });

    const { payouts } = useSelector(getSelectors);


    const [modal, setModal] = useState(false);
    // orders in current payout
    const [currentPayout, setCurrentPayout] = useState([]);

    // message
    const [message, setMessage] = useState("");

    // save date for datepicker
    const [startDate, setStartDate] = useState(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState(new Date());


    // get all pending payout  onload
    useEffect(() => {
        dispatch(getStorePayouts());
    }, [])

    // payout order modal
    const openOrderModal = (payout) => {
        setCurrentPayout(payout)
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal(true);
        }, 100);
    };

    // on date change
    const handleDatePicker = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    // filter data according to date picker

    const filterPayout = () => {
        const formatedStart = moment(startDate).format('DD-MM-yyyy');
        const formatedEnd = moment(endDate).format('DD-MM-yyyy');

        setTimeout(() => {
            dispatch(getStorePayouts(formatedStart, formatedEnd));
        }, 100);
    }

    const onExport = () => {
        const token = localStorage.getItem('accessToken');
        const formatedStart = moment(startDate).format('DD-MM-yyyy');
        const formatedEnd = moment(endDate).format('DD-MM-yyyy');

        window.location = WEBSITE_API_URL + "/export-store-payouts?token=" + token + "&start_date=" + formatedStart + "&end_date=" + formatedEnd + "&type=PENDING";
    }

    // handle message input
    const handleMessage = (message) => {
        setMessage(message);
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        const formatedStart = moment(startDate).format('DD-MM-yyyy');
        const formatedEnd = moment(endDate).format('DD-MM-yyyy');

        //  data to be submitted to release payout
        const orderIds = currentPayout?.orders?.map((order) => (order?.id))
        const payoutAmount = currentPayout?.orders?.reduce((sum, order) => sum + parseFloat(order.payout_amount), 0)
        const storeId = currentPayout?.id;



        dispatch(releasePayout(JSON.stringify(orderIds), payoutAmount, storeId, message)).then((response) => {
            setModal(false);
            dispatch(getStorePayouts(formatedStart, formatedEnd));
        })
    }


    //headers of table
    const PayoutHeading = [

        {
            name: "Store Name",
            selector: (row) => row.name,
            sortable: true,
            hide: 370,
        },
        {
            name: "Total Amount",
            selector: (row) => row?.orders?.reduce((sum, row) => sum + parseFloat(row.sub_total), 0),
            sortable: true,
            hide: 370,
        },
        {
            name: "Tax Amount",
            selector: (row) => row?.orders?.reduce((sum, row) => sum + parseFloat(row.tax), 0),
            sortable: true,
            hide: 370,
        },
        {
            name: "Store Charge",
            selector: (row) => row?.orders?.reduce((sum, row) => sum + parseFloat(row.store_charges), 0),
            sortable: true,
            hide: 370,
        },
        {
            name: "Total Deductions",
            selector: (row) => row?.orders?.reduce((sum, row) => sum + parseFloat(row.commission), 0),
            sortable: true,
            hide: "sm",

        },
        {
            name: "Store Payout Amount",
            selector: (row) => row?.orders?.reduce((sum, row) => sum + parseFloat(row.payout_amount), 0),
            sortable: true,
            hide: 370,
        },
        {
            name: "Status",
            selector: (row) => row.is_payout_released,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <Badge pill color="danger">
                        Pending
                    </Badge>

                );
            },
        },
        {
            name: "Actions",
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans row={row} />

                </div>

            ),
            sortable: false,
        },
    ];

    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a" onClick={(e) => { openOrderModal(row) }}>
                                <Icon name="eye-fill" />
                                View Orders
                            </DropdownItem>
                        </li>


                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    return (
        <React.Fragment>
            <Head title="Store Payouts "></Head>

            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Store Payouts
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {payouts.length} Payouts .</p>
                            </BlockDes>
                        </BlockHeadContent>

                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Row className='mb-3'>
                        <Col md="4">
                            <span className="form-label">Choose Date Range</span>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDatePicker}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                className="form-control date-picker"
                                required
                            />

                        </Col>
                        <Col md="3" className='d-flex align-items-end'>
                            <Button outline color="success" className='mx-3' onClick={() => { filterPayout() }}>

                                <span>Filter</span>
                            </Button>
                            <Button outline color="primary" className='mx-3' onClick={() => { onExport() }}>
                                <Icon name="download-cloud"></Icon>
                                <span>Export</span>
                            </Button>
                        </Col>
                        <Col md="5" className='d-flex justify-content-center align-items-center'>
                            <a href='/completed-store-payouts'>
                                <Button outline color="success" className='mx-3' >

                                    <span>Completed Payouts</span>
                                </Button>
                            </a>

                        </Col>


                    </Row>

                </Block>


                {/* datatable */}
                <ReactDataTable
                    data={payouts}
                    columns={PayoutHeading}

                    pagination
                />

            </Content>


            {/* view payout orders modal */}
            <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody className="pt-3 pb-2">
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false)
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className='p-2'>
                        <h5 className="title">Orders</h5>
                        <table className="table table-row-dashed table-row-gray-300 gy-7 border text-center">
                            <thead>
                                <tr className="fw-bolder fs-6 text-gray-800">
                                    <th>Order Id</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPayout && currentPayout?.orders && currentPayout?.orders?.map((order) => (
                                    <tr key={order?.id}>
                                        <td>{order?.unique_order_id}</td>
                                        <td>{order?.payout_amount}</td>
                                        <td>{moment(order?.created_at).format('DD-MM-yyy')}</td>
                                        <td>
                                            <a href={"/view-order/" + order?.id} type="button" className=" btn btn-success btn-sm">
                                                <Icon name="eye-fill" />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Form className=" gy-4" onSubmit={(e) => { onFormSubmit(e) }}>

                            <Row>
                                <Col md="8">
                                    <FormGroup>
                                        <label className="form-label">Message</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="message"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                handleMessage(e.target.value);
                                            }}
                                            placeholder="Enter Message"

                                        />

                                    </FormGroup>
                                </Col>
                                <Col md="4" className='d-flex align-items-end'>

                                    <Button type="submit" color="success" size="lg" className="btn-dim">

                                        Release Payout

                                    </Button>
                                </Col>
                            </Row>

                        </Form>

                    </div>

                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default StorePayouts
