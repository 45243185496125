// import React from "react";
// import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
// import Icon from "../icon/Icon";

// const PaginationComponent = ({ itemPerPage, totalItems, paginate, currentPage }) => {
//   const pageNumbers = [];

//   for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
//     pageNumbers.push(i);
//   }

//   const nextPage = () => {
//     paginate(currentPage + 1);
//   };

//   const prevPage = () => {
//     paginate(currentPage - 1);
//   };

//   return (
//     <Pagination aria-label="Page navigation example">
//       <PaginationItem disabled={currentPage - 1 === 0 ? true : false}>
//         <PaginationLink
//           className="page-link-prev"
//           onClick={(ev) => {
//             ev.preventDefault();
//             prevPage();
//           }}
//           href="#prev"
//         >
//           <Icon name="chevrons-left" />
//           <span>Prev</span>
//         </PaginationLink>
//       </PaginationItem>
//       {pageNumbers.map((item) => {
//         return (
//           <PaginationItem className={currentPage === item ? "active" : ""} key={item}>
//             <PaginationLink
//               tag="a"
//               href="#pageitem"
//               onClick={(ev) => {
//                 ev.preventDefault();
//                 paginate(item);
//               }}
//             >
//               {item}
//             </PaginationLink>
//           </PaginationItem>
//         );
//       })}

//       <PaginationItem disabled={pageNumbers[pageNumbers.length - 1] === currentPage}>
//         <PaginationLink
//           className="page-link-next"
//           onClick={(ev) => {
//             ev.preventDefault();
//             nextPage();
//           }}
//           href="#next"
//         >
//           <span>Next</span>
//           <Icon name="chevrons-right" />
//         </PaginationLink>
//       </PaginationItem>
//     </Pagination>
//   );
// };
// export default PaginationComponent;



import React from "react";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
import Icon from "../icon/Icon";

const PaginationComponent = ({ itemPerPage, totalItems, paginate, currentPage }) => {
  const maxDisplayedPages = 10; // Define the maximum number of displayed page numbers
  const totalPages = Math.ceil(totalItems / itemPerPage);

  let startPage = Math.max(1, currentPage - Math.floor(maxDisplayedPages / 2));
  let endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  if (endPage - startPage < maxDisplayedPages - 1) {
    startPage = Math.max(1, endPage - maxDisplayedPages + 1);
  }

  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  const nextPage = () => {
    paginate(currentPage + 1);
  };

  const prevPage = () => {
    paginate(currentPage - 1);
  };

  return (
    <Pagination aria-label="Page navigation example">
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink
          className="page-link-prev"
          onClick={(ev) => {
            ev.preventDefault();
            prevPage();
          }}
          href="#prev"
        >
          <Icon name="chevrons-left" />
          <span>Prev</span>
        </PaginationLink>
      </PaginationItem>
      {pageNumbers.map((item) => (
        <PaginationItem className={currentPage === item ? "active" : ""} key={item}>
          <PaginationLink
            tag="a"
            href="#pageitem"
            onClick={(ev) => {
              ev.preventDefault();
              paginate(item);
            }}
          >
            {item}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={currentPage === totalPages}>
        <PaginationLink
          className="page-link-next"
          onClick={(ev) => {
            ev.preventDefault();
            nextPage();
          }}
          href="#next"
        >
          <span>Next</span>
          <Icon name="chevrons-right" />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationComponent;
