import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="nk-footer">
            <div className="container-fluid">
                <div className=" text-center text-black">
                    Copyright © 2023 Sylcon Powered By  <span style={{ color: '#000', fontWeight: '700' }} > Howin</span><span style={{ color: '#ea2e2e', fontWeight: '700' }} >Cloud </span> All rights reserved.
                </div>

            </div>
        </div>
    );
};
export default Footer;
