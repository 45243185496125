// export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
// export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE'

export const ADD_ITEM_TO_SELECTED_ITEMS = 'ADD_ITEM_TO_SELECTED_ITEMS';
export const CHECK_TOKEN_VALID = 'CHECK_TOKEN_VALID';
export const ITEMS_ACTIVE_CHECK = 'ITEMS_ACTIVE_CHECK';
export const WAREHOUSE_MAX_DISTANCE = 'WAREHOUSE_MAX_DISTANCE';
export const WAREHOUSE_CHARGE_TAX = 'WAREHOUSE_CHARGE_TAX';
export const PLACE_ORDER = 'PLACE_ORDER';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const ADD_VARIANT_CART = 'ADD_VARIANT_CART';
export const REMOVE_VARIANT_CART = 'REMOVE_VARIANT_CART';
export const ADD_ADDONS_CART = 'ADD_ADDONS_CART';
export const REMOVE_ADDONS_CART = 'REMOVE_ADDONS_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const CART_WAREHOUSE = 'CART_WAREHOUSE';
export const UPDATE_CART = 'UPDATE_CART';
export const ADDCUSTOMIZATION_CART = 'ADDCUSTOMIZATION_CART';
// actionTypes.js

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
// actionTypes.js

export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';



// export const GET_ALL_ITEMS = 'GET_ALL_ITEMS';
























