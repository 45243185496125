import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Col, Icon, Row } from "../../../components/Component";
import { getDashboardData } from '../../../redux/admin/dashboard/action'
import RecentLimitzoOrders from './recentOrders';
import { Button, Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import SalesStatisticsLimitzo from './salesStatistics';
import TodayUsers from './todayUsers';
import RecentWarehouses from './recentWarehouses';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

const AdminDashboard = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        const formatedStart = moment(startDate).format('DD-MM-yyyy');
        const formatedEnd = moment(endDate).format('DD-MM-yyyy');
        dispatch(getDashboardData(formatedStart, formatedEnd));
        console.log(formatedStart)
    }, []);

    const getSelectors = (state) => ({
        recent_orders: state.dashboard.recent_orders,
        today_users: state.dashboard.today_users,
        recent_warehouses: state.dashboard.recent_warehouses,
        total_products: state.dashboard.total_products,
        sales_count: state.dashboard.sales_count,
        sales_earning: state.dashboard.sales_earning,
        users_count: state.dashboard.users_count,
        weekly_orders: state.dashboard.weekly_orders,
    });

    const { recent_orders, today_users, recent_warehouses, total_products, sales_earning, sales_count, users_count, weekly_orders } = useSelector(getSelectors);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleDatePicker = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const filterDashBoardData = () => {
        const formatedStart = moment(startDate).format('DD-MM-yyyy');
        const formatedEnd = moment(endDate).format('DD-MM-yyyy');
        dispatch(getDashboardData(formatedStart, formatedEnd));
    }

    return (
        <React.Fragment>
            <Head title="Dashboard"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page tag="h3">
                                Dashboard
                            </BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <div className="d-flex align-items-center justify-content-center">
                                    <ReactDatePicker
                                        selected={startDate}
                                        onChange={handleDatePicker}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        dateFormat="dd-MM-yy"
                                        className="form-control date-picker"
                                    />
                                    <div style={{ paddingLeft: '8px' }}>
                                        <Button className='btn-dim' onClick={() => { filterDashBoardData() }} ><Icon name='filter' /></Button>
                                    </div>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Row className="g-gs">
                        {/* count */}
                        <Col xxl="4">
                            <Row className="g-gs">
                                {/* users */}
                                <Col xxl="6" md="3" >
                                    <Card>
                                        <div className="nk-ecwg nk-ecwg3 pb-3">
                                            <div className="card-inner pb-0">
                                                <div className="card-title-group">
                                                    <div className="card-title">
                                                        <h6 className="title">Users</h6>
                                                    </div>
                                                </div>
                                                <div className="data">
                                                    <div className="data-group">
                                                        <div className="amount">{users_count}</div>
                                                        <div className="info text-right">
                                                            <span className="change up text-danger" style={{ fontSize: '20px' }}>
                                                                <Icon name="user-list"></Icon>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                 {/* warehouses */}
                                 <Col xxl="6" md="3" >
                                    <Card>
                                        <div className="nk-ecwg nk-ecwg3 pb-3">
                                            <div className="card-inner pb-0">
                                                <div className="card-title-group">
                                                    <div className="card-title">
                                                        <h6 className="title">Products</h6>
                                                    </div>
                                                </div>
                                                <div className="data">
                                                    <div className="data-group">
                                                        <div className="amount">{total_products?total_products:0}</div>
                                                        <div className="info text-right">
                                                            <span className="change up text-danger" style={{ fontSize: '20px' }}>
                                                                <Icon name="building"></Icon>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                {/* sales */}
                                <Col xxl="12" md="3" >
                                    <Card>
                                        <div className="nk-ecwg nk-ecwg3 pb-3">
                                            <div className="card-inner pb-0">
                                                <div className="card-title-group">
                                                    <div className="card-title">
                                                        <h6 className="title">Sales</h6>
                                                    </div>
                                                </div>
                                                <div className="data">
                                                    <div className="data-group">
                                                        <div className="amount">₹{" "}{sales_earning ? sales_earning : "0.00"}</div>
                                                        <div className="info text-right">
                                                            <span className="change up text-danger" style={{ fontSize: '20px' }}>
                                                                <Icon name="wallet"></Icon>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                {/* orders */}
                                <Col xxl="12" md="3" >
                                    <Card>
                                        <div className="nk-ecwg nk-ecwg3 pb-3">
                                            <div className="card-inner pb-0">
                                                <div className="card-title-group">
                                                    <div className="card-title">
                                                        <h6 className="title">Orders</h6>
                                                    </div>
                                                </div>
                                                <div className="data">
                                                    <div className="data-group">
                                                        <div className="amount">{sales_count}</div>
                                                        <div className="info text-right">
                                                            <span className="change up text-danger" style={{ fontSize: '20px' }}>
                                                                <Icon name="cart"></Icon>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl="8" >
                            <SalesStatisticsLimitzo weekly_orders={weekly_orders} />
                        </Col>
                        <Col xxl="6" md="6">
                            <TodayUsers today_users={today_users} />
                        </Col>
                        <Col xxl="6" md="6">
                            <RecentWarehouses recent_warehouses={recent_warehouses} />
                        </Col>
                        <Col xxl="12">
                            <RecentLimitzoOrders recent_orders={recent_orders} />
                        </Col>
                    </Row>
                </Block>
            </Content>
        </React.Fragment>
    )
}

export default AdminDashboard
